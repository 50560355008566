import $http from "../axios";

const actions = {
  signup(_, signUpData) {
    return new Promise((resolve, reject) => {
      $http
        .post("/signup", signUpData)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  confirmEmail(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("/confirm-email", {
          emailConfirmationToken: data.emailConfirmationToken
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  resendEmailValidationLink(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("/resend-email-validation-link", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default {
  actions
};
