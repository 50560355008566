<template>
  <form @submit.prevent="resetPassword">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <h1
      class="is-size-3 has-text-weight-semibold has-text-centered"
      :class="{ 'has-text-white': !isDesktop }"
    >
      Resetar Senha
    </h1>
    <br />
    <p :class="{ 'has-text-white': !isDesktop }">
      Digite sua nova senha
    </p>
    <br />

    <b-field
      :label="password && isDesktop ? 'Senha' : ''"
      label-position="on-border"
    >
      <b-input
        size="is-medium"
        v-model="password"
        class="is-expanded"
        type="password"
        placeholder="Senha"
        password-reveal
      ></b-input>
    </b-field>

    <button
      class="button is-primary is-medium is-fullwidth has-text-weight-bold"
    >
      Resetar
    </button>

    <br />
    <router-link to="login" tag="button" class="button">
      <b-icon icon="arrow-alt-circle-left"></b-icon>
      &nbsp; Voltar para login
    </router-link>
    <br />
    <br />
  </form>
</template>

<script>
import { commom } from "../../mixins/commom.js";
import { responsive } from "../../mixins/responsive";

export default {
  mixins: [commom, responsive],

  data() {
    return {
      isLoading: false,
      resetToken: "",
      password: ""
    };
  },

  methods: {
    resetPassword() {
      this.resetToken = this.$route.query.resetToken;
      this.isLoading = true;
      this.$store
        .dispatch("resetPassword", {
          resetToken: this.resetToken,
          password: this.password
        })
        .then(() => {
          this.$buefy.dialog.alert({
            title: "Senha atualizada!",
            message: "Você já pode fazer login com sua nova senha ;)",
            type: "is-success",
            hasIcon: true,
            icon: "check",
            iconPack: "fa",
            onConfirm: () => this.$router.push("/"),
            onCancel: () => this.$router.push("/")
          });
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
form {
  width: 60%;
  margin: auto;
  margin-top: 5vh;
}
@media (min-width: 1023px) {
  form {
    width: 80%;
    margin-top: 15vh;
  }
}
@media (max-width: 600px) {
  form {
    width: 70%;
  }
}
@media (max-width: 540px) {
  form {
    width: 90%;
  }
}
</style>
