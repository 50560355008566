<template>
  <div class="principalInformation MainBlock">
    <div class="strip"></div>

    <div class="UserName">
      <span class="name" v-if="windowWidth > 900">
        {{ account.name }} {{ account.surname }}
      </span>
      <slot></slot>
    </div>

    <div class="UserPhoto">
      <img
        :src="account.picture ? account.picture : account.googlePictureURL"
        v-if="account.picture || account.googlePictureURL"
      />
      <img src="../../../assets/img/avatar.png" v-else />
    </div>

    <div class="UserInfo">
      <div class="UserNameMobile" v-if="windowWidth <= 900">
        {{ account.name }} {{ account.surname }}
      </div>

      <div class="UserUniversity">{{ universityFirstName }}</div>

      <div class="UserLeader">Professor de {{ specializations }}</div>
      <br />

      <div class="UserEmailMobile" v-if="windowWidth <= 900">
        {{ account.email }}
      </div>
    </div>

    <div class="UserContact">
      <div class="UserEmail" v-if="windowWidth > 900">
        {{ account.email }}
      </div>

      <div class="UserSocial">
        <div @click="newAction('lattes')" style="cursor: pointer;">
          <b-tooltip
            :label="
              account.lattes != ''
                ? 'Clique para acessar a página do seu Lattes'
                : 'Clique para inserir o seu Lattes'
            "
          >
            <img
              :src="
                account.lattes != ''
                  ? require(`../../../assets/img/lattes-verde.png`)
                  : require(`../../../assets/img/lattes-cinza.png`)
              "
              class="lattesIcon"
            />
          </b-tooltip>
        </div>

        <div @click="newAction('linkedin')">
          <b-tooltip
            :label="
              account.linkedin != ''
                ? 'Clique para acessar a página do seu LinkedIn'
                : 'Clique para inserir o seu LinkedIn'
            "
          >
            <b-icon
              pack="fab"
              icon="linkedin"
              size="is-large"
              :style="
                account.linkedin != ''
                  ? 'color: rgb(2,209,168);'
                  : 'color: gray;'
              "
            ></b-icon>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { responsive } from "../../../mixins/responsive";

export default {
  name: "MainInfo",

  mixins: [responsive],

  props: ["account"],

  computed: {
    universityFirstName() {
      return this.account.university.split("-")[0];
    },
    specializations() {
      if (this.account.specialization.length == 1) {
        return this.translateSpecialization(this.account.specialization[0]);
      } else if (this.account.specialization.length == 2) {
        return (
          this.translateSpecialization(this.account.specialization[0]) +
          " e " +
          this.translateSpecialization(this.account.specialization[1])
        );
      } else if (this.account.specialization.length == 3) {
        return (
          this.translateSpecialization(this.account.specialization[0]) +
          ", " +
          this.translateSpecialization(this.account.specialization[1]) +
          " e " +
          this.translateSpecialization(this.account.specialization[2])
        );
      } else return "";
    }
  },

  methods: {
    newAction(name) {
      if (name == "lattes") {
        if (this.account.lattes) {
          window.open(this.account.lattes);
        } else {
          this.$parent.formGeneralActive = true;
        }
      }
      if (name == "linkedin") {
        if (this.account.linkedin) {
          window.open(this.account.linkedin);
        } else {
          this.$parent.formGeneralActive = true;
        }
      }
    },

    translateSpecialization(name) {
      if (name == "technologist") {
        return "Ensino Técnico";
      } else if (name == "graduation") {
        return "Graduação";
      } else if (name == "postGraduation") {
        return "Pós-Graduação";
      }
    }
  }
};
</script>

<style scoped>
.MainBlock {
  border: solid 0.5px rgb(175, 175, 175);
  border-radius: 0.5rem;
}
.strip {
  height: 0.5rem;
  background: rgb(2, 209, 168);
  background: linear-gradient(
    90deg,
    rgba(2, 209, 168, 1) 0%,
    rgba(95, 255, 223, 1) 100%,
    rgba(60, 247, 210, 1) 100%
  );
  border-radius: 0.5rem 0.5rem 0 0;
}
.UserName {
  background-image: url("../../../assets/img/headerPerfil.jpg");
  min-height: 10rem;
  display: flex;
}
.UserName .name {
  color: white;
  font-size: 3rem;
  font-weight: 800;
  width: fit-content;
  display: block;
  margin-left: 47%;
  padding-top: 4rem;
}
.UserPhoto {
  border-radius: 100%;
  width: 240px;
  height: 240px;
  margin-top: -7.5rem;
  margin-left: 10%;
}
.UserPhoto img {
  border-radius: 100%;
  border: solid 5px rgb(2, 209, 168);
  width: 240px;
  height: 240px;
  background-color: rgb(2, 209, 168);
}
.UserInfo {
  color: black;
  width: 45%;
  min-width: 25rem;
  margin-top: -6rem;
  margin-left: 47%;
}
.UserUniversity {
  font-size: 1.8rem;
  font-weight: 700;
}
.UserLeader {
  font-size: 1.4rem;
}
.UserContact {
  display: grid;
  grid-template-columns: auto auto;
  border: solid 0.5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: rgb(175, 175, 175);
  margin-top: 2rem;
  height: 5rem;
}
.UserEmail {
  display: flex;
  margin: auto;
  color: black;
  font-size: 1.2rem;
}

.UserSocial {
  display: flex;
  width: fit-content;
  margin: auto;
}

.UserSocial .icon {
  cursor: pointer;
}

.lattesIcon {
  width: 2.3rem;
  margin-top: .3rem;
}

.noLattes {
  filter: sepia(1);
}

@media (max-width: 900px) {
  .UserName {
    min-height: 6rem;
  }
  .UserPhoto {
    width: 240px;
    height: 240px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -5rem;
  }
  .UserInfo {
    margin-top: 0rem;
    margin-left: auto;
    margin-right: auto;
  }
  .UserNameMobile {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  }
  .UserUniversity {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
  }
  .UserLeader {
    text-align: center;
  }
  .UserEmailMobile {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .UserContact {
    grid-template-columns: auto;
  }
}

@media (max-width: 500px) {
  .UserInfo {
    width: fit-content;
    min-width: 10rem;
  }
  .UserNameMobile {
    font-size: 1.6rem;
  }
  .UserUniversity {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .UserLeader {
    font-size: 1.2rem;
  }
}
</style>
