<template>
  <div class="MainBlock">
    <div class="profileHeader">
      <span class="profileTitle">MEU PERFIL</span>
      <slot />
    </div>
    <div class="mainInfo">
      <p class="titleProfile">Dados Pessoais</p>
      <p class="infoProfile">Nome: {{ account.name }}</p>
      <p class="infoProfile">Sobrenome: {{ account.surname }}</p>
      <p class="infoProfile">
        Celular:
        <span v-if="account.cellphone">
          ({{ account.areacode }}) {{ account.cellphone }}
        </span>
        <span v-else class="inform" @click="$emit('inform')">Informar</span>
      </p>
      <p class="infoProfile">
        E-mail Institucional: {{ account.originalEmail }}
      </p>
      <p class="infoProfile">
        Plataforma Lattes:
        <span v-if="account.lattes">{{ account.lattes }}</span>
        <span v-else class="inform" @click="$emit('inform')">Informar</span>
      </p>
      <p class="infoProfile">
        LinkedIn:
        <span v-if="account.linkedin"> {{ account.linkedin }} </span>
        <span v-else class="inform" @click="$emit('inform')"> Informar </span>
      </p>
    </div>
    <hr />
    <div class="mainInfo">
      <p class="titleProfile">Universidade</p>
      <p class="infoProfile">Universidade: {{ account.university }}</p>
      <p class="infoProfile">Local: {{ account.city }} - {{ account.state }}</p>
      <p class="infoProfile">Leciona para:</p>
      <div class="infoProfile" v-if="teacherForTechnologist">
        Ensino Técnico
        <div v-if="account.technologistGrade">
          <span class="infoProfile"> - Cursos: </span>
          <span class="inform" v-if="!account.technologistGrade.courses" @click="$emit('inform')"
            >Informar</span
          >
          <span v-else>
            <span
              class="infoProfile"
              v-for="(course, i) in account.technologistGrade.courses"
              :key="i"
              >{{ course }}<span v-if="account.technologistGrade.courses[i+1]">, </span><span v-else>.</span></span
            >
          </span>
          <br />
          <span class="infoProfile"> - Disciplinas: </span>
          <span class="inform" v-if="!account.technologistGrade.subjects" @click="$emit('inform')"
            >Informar</span
          >
          <span v-else>
            <span
              class="infoProfile"
              v-for="(subject, i) in account.technologistGrade.subjects"
              :key="i"
              >{{ subject }}<span v-if="account.technologistGrade.subjects[i+1]">, </span><span v-else>.</span></span
            >
          </span>
        </div>
        <div v-else>
          <span class="infoProfile"> - Cursos: </span>
          <span class="inform" @click="$emit('inform')">Informar</span>
          <br />
          <span class="infoProfile"> - Disciplinas: </span>
          <span class="inform" @click="$emit('inform')">Informar</span>
        </div>
      </div>
      <div class="infoProfile" v-if="teacherForGraduation">
        Graduação
        <div v-if="account.graduationGrade">
          <span class="infoProfile"> - Cursos: </span>
          <span class="inform" v-if="!account.graduationGrade.courses" @click="$emit('inform')"
            >Informar</span
          >
          <span v-else>
            <span
              class="infoProfile"
              v-for="(course, i) in account.graduationGrade.courses"
              :key="i"
              >{{ course }}<span v-if="account.graduationGrade.courses[i+1]">, </span><span v-else>.</span></span
            >
          </span>
          <br />
          <span class="infoProfile"> - Disciplinas: </span>
          <span class="inform" v-if="!account.graduationGrade.subjects" @click="$emit('inform')"
            >Informar</span
          >
          <span v-else>
            <span
              class="infoProfile"
              v-for="(subject, i) in account.graduationGrade.subjects"
              :key="i"
              >{{ subject }}<span v-if="account.graduationGrade.subjects[i+1]">, </span><span v-else>.</span></span
            >
          </span>
        </div>
        <div v-else>
          <span class="infoProfile"> - Cursos: </span>
          <span class="inform" @click="$emit('inform')">Informar</span>
          <br />
          <span class="infoProfile"> - Disciplinas: </span>
          <span class="inform" @click="$emit('inform')">Informar</span>
        </div>
      </div>
      <div class="infoProfile" v-if="teacherForpostGraduation">Pós-Graduação</div>
      <br>
      <span v-if="account.guidance" class="infoProfile">
        Orienta:
        <span v-for="(project, i) in account.guidance" :key="i" class="infoProfile">
          {{translateGuidance(project)}}<span v-if="account.guidance[i+1]">, </span><span v-else>.</span>
        </span>
      </span>
      <br> <br>

      <div v-if="account.anotherUniversity">
        <hr>
        <p class="infoProfile">Outra universidade que leciona:</p>
        <p class="infoProfile">{{account.anotherUniversity}}</p>
        <p class="infoProfile">Contato:
          <span v-if="account.anotherUniversityEmail" class="infoProfile">{{account.anotherUniversityEmail}}</span>
          <span v-else class="inform" @click="$emit('inform')">Informar</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInfo",
  props: ["account"],
  computed: {
    teacherForTechnologist() {
      for (let i = 0; i < this.account.specialization.length; i++) {
        if (this.account.specialization[i] == "technologist") {
          return true;
        }
      }
      return false;
    },
    teacherForGraduation() {
      for (let i = 0; i < this.account.specialization.length; i++) {
        if (this.account.specialization[i] == "graduation") {
          return true;
        }
      }
      return false;
    },
    teacherForpostGraduation() {
      for (let i = 0; i < this.account.specialization.length; i++) {
        if (this.account.specialization[i] == "postGraduation") {
          return true;
        }
      }
      return false;
    },
  },
  methods:{
    translateGuidance(name){
      if(name == "tcc"){
        return "TCC"
      } else if(name == "scientificResearch"){
        return "Iniciação Científica"
      } else if(name == "mastersDegree"){
        return "Mestrado"
      } else if(name == "doctorateDegree"){
        return "Doutorado"
      } else if(name == "organization"){
        return "Org. Estudantil / Extensão"
      } else return ""
    }
  }
};
</script>

<style scoped>
.MainBlock {
  border: solid 0.5px rgb(175, 175, 175);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
.profileHeader {
  background: rgb(2, 209, 168);
  background: linear-gradient(
    90deg,
    rgba(2, 209, 168, 1) 0%,
    rgba(95, 255, 223, 1) 100%,
    rgba(60, 247, 210, 1) 100%
  );
  border: transparent;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
}
.profileTitle {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 2rem;
  margin-top: 0.5rem;
}
hr {
  margin: 0 !important;
}
.mainInfo {
  display: block;
  padding: 1rem 2rem;
}
.titleProfile {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(97, 97, 97);
}
.mainInfo p {
  margin-top: 0.3rem;
  font-size: 1.2rem;
  color: rgb(70, 70, 70);
}

.inform {
  color: rgb(2, 209, 168);
  cursor: pointer;
}

.infoProfile {
  font-size: 1.2rem;
  color: rgb(97, 97, 97);
}
</style>
