<template>
  <form>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <div
      v-if="!validationError && !validationSuccess"
      class="has-text-centered"
    >
      <h1
        class="is-size-4 has-text-weight-semibold"
        :class="{ 'has-text-white': !isDesktop }"
      >
        Validando e-mail...
      </h1>
    </div>

    <div v-if="validationError" class="has-text-centered">
      <h1
        class="is-size-3 has-text-weight-semibold"
        :class="{ 'has-text-white': !isDesktop }"
      >
        Erro 🤯
      </h1>
      <br />

      <p :class="{ 'has-text-white': !isDesktop }">
        Não foi possível validar seu e-mail.
      </p>

      <p :class="{ 'has-text-white': !isDesktop }">
        Se estiver com dificuldades, por favor, entre em contato conosco pelo
        e-mail
        <a
          href="mailto:empower@empower.vision"
          :class="{ 'has-text-white': !isDesktop }"
        >
          empower@empower.vision
        </a>
      </p>
    </div>

    <div v-if="validationSuccess" class="has-text-centered">
      <h1
        class="is-size-3 has-text-weight-semibold"
        :class="{ 'has-text-white': !isDesktop }"
      >
        Sucesso 🤩
      </h1>
      <br />

      <p :class="{ 'has-text-white': !isDesktop }">
        Seu e-mail foi validado!
      </p>

      <p :class="{ 'has-text-white': !isDesktop }">
        Agora, você já pode entrar na plataforma Empower.
      </p>
      <br />

      <router-link to="/login" tag="button" class="button is-primary">
        Ir para login
      </router-link>
      <br />
      <br />
    </div>
  </form>
</template>

<script>
import { commom } from "../../mixins/commom.js";
import { responsive } from "../../mixins/responsive";

export default {
  mixins: [commom, responsive],

  data() {
    return {
      isLoading: false,
      emailConfirmationToken: "",
      validationError: false,
      validationSuccess: false
    };
  },

  mounted() {
    this.emailConfirmationToken = this.$route.query.emailConfirmationToken;
    if (
      this.emailConfirmationToken &&
      this.emailConfirmationToken !== null &&
      this.emailConfirmationToken !== ""
    ) {
      this.isLoading = true;
      this.$store
        .dispatch("confirmEmail", {
          emailConfirmationToken: this.emailConfirmationToken
        })
        .then(res => {
          if (res.data.result === "ok") {
            this.validationSuccess = true;
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.validationError = true;
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.validationError = true;
    }
  }
};
</script>

<style scoped>
form {
  width: 60%;
  margin: auto;
  margin-top: 5vh;
}
@media (min-width: 1023px) {
  form {
    width: 80%;
    margin-top: 15vh;
  }
}
@media (max-width: 600px) {
  form {
    width: 70%;
  }
}
@media (max-width: 540px) {
  form {
    width: 90%;
  }
}
</style>
