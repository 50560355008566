import Vue from "vue";
import router from "./router";
import store from "./store";
import http from "./axios";
// import Vuelidate from "vuelidate";

// Recaptcha
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LfIWe4eAAAAAC1PzKwnm0l5dNnJLakb4iaeofVD" });

// Buefy + Icons
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Google sign-in
import GSignInButton from "vue-google-signin-button";
Vue.use(GSignInButton);

import {
  faEye,
  faEyeSlash,
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faTimes,
  faTimesCircle,
  faArrowUp,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faBusAlt,
  faChalkboardTeacher,
  faArrowLeft,
  faClock,
  faComments,
  faSearch,
  faPaperPlane,
  faExclamation,
  faHourglassHalf,
  faInfoCircle,
  faList,
  faBook,
  faBullhorn,
  faCogs,
  faBolt,
  faTh,
  faMoneyBillAlt,
  faMinus,
  faLightbulb,
  faChevronDown,
  faChevronUp,
  faMicrophoneAlt,
  faRocket,
  faQuestionCircle,
  faCalendarAlt,
  faLink,
  faSignal,
  faTasks,
  faUsers,
  faBars,
  faCaretDown,
  faCaretUp,
  faPlus,
  faFilter,
  faEnvelope,
  faCameraRetro,
  faGraduationCap,
  faPencilAlt,
  faCheckCircle,
  faUser,
  faArrowRight,
  faBackward,
  faForward
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGoogle
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faEye,
  faEyeSlash,
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faTimes,
  faTimesCircle,
  faArrowUp,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faBusAlt,
  faChalkboardTeacher,
  faArrowLeft,
  faClock,
  faComments,
  faSearch,
  faPaperPlane,
  faExclamation,
  faHourglassHalf,
  faInfoCircle,
  faList,
  faBook,
  faBullhorn,
  faCogs,
  faBolt,
  faTh,
  faMoneyBillAlt,
  faMinus,
  faLightbulb,
  faChevronDown,
  faChevronUp,
  faMicrophoneAlt,
  faRocket,
  faQuestionCircle,
  faCalendarAlt,
  faLink,
  faSignal,
  faTasks,
  faUsers,
  faBars,
  faCaretDown,
  faCaretUp,
  faPlus,
  faFilter,
  faEnvelope,
  faCameraRetro,
  faFacebook,
  faInstagram,
  faLinkedin,
  faGraduationCap,
  faPencilAlt,
  faGoogle,
  faCheckCircle,
  faUser,
  faArrowRight,
  faBackward,
  faForward
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas"
});

// Other Vue Config
Vue.prototype.$http = http;

// Vue.use(Vuelidate);

Vue.config.productionTip = false;

import App from "./App.vue";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
