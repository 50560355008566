<template>
  <section>
    <logged-in-header />

    <back-button />

    <cover :coverImageURL="opportunityBeingVisited.page.coverImageURL" />

    <logo :businessLogoURL="opportunityBeingVisited.page.businessLogoURL" />

    <company-name
      :businessName="opportunityBeingVisited.businessName"
      :treatmentPronoun="opportunityBeingVisited.treatmentPronoun"
      :fanpage="opportunityBeingVisited.fanpage"
      :insta="opportunityBeingVisited.insta"
      :linkedin="opportunityBeingVisited.linkedin"
      :site="opportunityBeingVisited.site"
      :opportunityId="opportunityBeingVisited._id"
    />

    <info-bottom
      v-if="opportunityBeingVisited.page.content0"
      :content="opportunityBeingVisited.page.content0"
    />

    <info1
      :contentImageURL="opportunityBeingVisited.page.contentImage1URL"
      :content="opportunityBeingVisited.page.content1"
    />

    <info2
      :contentImageURL="opportunityBeingVisited.page.contentImage2URL"
      :content="opportunityBeingVisited.page.content2"
    />

    <info-bottom
      v-if="opportunityBeingVisited.page.content3"
      :content="opportunityBeingVisited.page.content3"
    />

    <carousel
      v-if="
        opportunityBeingVisited.page.carouselImages &&
          opportunityBeingVisited.page.carouselImages.length
      "
      :carouselImages="opportunityBeingVisited.page.carouselImages"
    />

    <info-bottom
      v-if="opportunityBeingVisited.page.content4"
      :content="opportunityBeingVisited.page.content4"
    />

    <lesson-info
      :opportunityBeingVisited="opportunityBeingVisited"
      @open-card="openCard"
      @close-card="closeCard"
      :isCardOpen="isLessonCardOpen"
    />

    <research-info
      :opportunityBeingVisited="opportunityBeingVisited"
      @open-card="openCard"
      @close-card="closeCard"
      :isCardOpen="isResearchCardOpen"
    />

    <visit-info
      :opportunityBeingVisited="opportunityBeingVisited"
      @open-card="openCard"
      @close-card="closeCard"
      :isCardOpen="isVisitCardOpen"
    />

    <other-info
      :opportunityBeingVisited="opportunityBeingVisited"
      @open-card="openCard"
      @close-card="closeCard"
      :isCardOpen="isOtherCardOpen"
    />

    <br /><br /><br /><br />

    <empower-footer />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import loggedInHeader from "../../components/commom/LoggedInHeader";
import backButton from "../../components/opportunity-page/BackButton";
import cover from "../../components/opportunity-page/Cover";
import logo from "../../components/opportunity-page/Logo";
import companyName from "../../components/opportunity-page/CompanyName";
import info1 from "../../components/opportunity-page/Info1";
import info2 from "../../components/opportunity-page/Info2";
import carousel from "../../components/opportunity-page/Carousel";
import infoBottom from "../../components/opportunity-page/InfoBottom";
import lessonInfo from "../../components/opportunity-page/info-cards/InfoCardLesson";
import researchInfo from "../../components/opportunity-page/info-cards/InfoCardResearch";
import visitInfo from "../../components/opportunity-page/info-cards/InfoCardVisit";
import otherInfo from "../../components/opportunity-page/info-cards/InfoCardOther";
// import warning from "../../components/opportunity-page/Warning";
import empowerFooter from "../../components/commom/EmpowerFooter";

export default {
  components: {
    loggedInHeader,
    backButton,
    cover,
    logo,
    companyName,
    info1,
    info2,
    carousel,
    infoBottom,
    lessonInfo,
    researchInfo,
    visitInfo,
    otherInfo,
    // warning,
    empowerFooter
  },

  data() {
    return {
      isLessonCardOpen: false,
      isResearchCardOpen: false,
      isVisitCardOpen: false,
      isOtherCardOpen: false
    };
  },

  methods: {
    openCard(action) {
      if (action == "lesson") this.isLessonCardOpen = true;
      else if (action == "research") this.isResearchCardOpen = true;
      else if (action == "visitProf") this.isVisitCardOpen = true;
      else if (action == "otherProf") this.isOtherCardOpen = true;
    },
    closeCard(action) {
      if (action == "lesson") this.isLessonCardOpen = false;
      else if (action == "research") this.isResearchCardOpen = false;
      else if (action == "visitProf") this.isVisitCardOpen = false;
      else if (action == "otherProf") this.isOtherCardOpen = false;
    }
  },

  computed: {
    ...mapGetters(["opportunityBeingVisited"])
  }
};
</script>

<style scoped>
section {
  padding-top: 4.37rem;
}
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
