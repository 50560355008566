import Vue from "vue";
import Vuex from "vuex";

import signup from "./modules/signup";
import login from "./modules/login";
import account from "./modules/account";
import opportunity from "./modules/opportunity";
import request from "./modules/request";
import contact from "./modules/contact";
import upload from "./modules/upload";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    signup,
    login,
    account,
    opportunity,
    request,
    contact,
    upload
  },
  plugins: [
    createPersistedState({
      paths: ["login", "account", "opportunity"]
    })
  ]
});
