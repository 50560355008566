var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"principalInformation MainBlock"},[_c('div',{staticClass:"strip"}),_c('div',{staticClass:"UserName"},[(_vm.windowWidth > 900)?_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.account.name)+" "+_vm._s(_vm.account.surname)+" ")]):_vm._e(),_vm._t("default")],2),_c('div',{staticClass:"UserPhoto"},[(_vm.account.picture || _vm.account.googlePictureURL)?_c('img',{attrs:{"src":_vm.account.picture ? _vm.account.picture : _vm.account.googlePictureURL}}):_c('img',{attrs:{"src":require("../../../assets/img/avatar.png")}})]),_c('div',{staticClass:"UserInfo"},[(_vm.windowWidth <= 900)?_c('div',{staticClass:"UserNameMobile"},[_vm._v(" "+_vm._s(_vm.account.name)+" "+_vm._s(_vm.account.surname)+" ")]):_vm._e(),_c('div',{staticClass:"UserUniversity"},[_vm._v(_vm._s(_vm.universityFirstName))]),_c('div',{staticClass:"UserLeader"},[_vm._v("Professor de "+_vm._s(_vm.specializations))]),_c('br'),(_vm.windowWidth <= 900)?_c('div',{staticClass:"UserEmailMobile"},[_vm._v(" "+_vm._s(_vm.account.email)+" ")]):_vm._e()]),_c('div',{staticClass:"UserContact"},[(_vm.windowWidth > 900)?_c('div',{staticClass:"UserEmail"},[_vm._v(" "+_vm._s(_vm.account.email)+" ")]):_vm._e(),_c('div',{staticClass:"UserSocial"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.newAction('lattes')}}},[_c('b-tooltip',{attrs:{"label":_vm.account.lattes != ''
              ? 'Clique para acessar a página do seu Lattes'
              : 'Clique para inserir o seu Lattes'}},[_c('img',{staticClass:"lattesIcon",attrs:{"src":_vm.account.lattes != ''
                ? require("../../../assets/img/lattes-verde.png")
                : require("../../../assets/img/lattes-cinza.png")}})])],1),_c('div',{on:{"click":function($event){return _vm.newAction('linkedin')}}},[_c('b-tooltip',{attrs:{"label":_vm.account.linkedin != ''
              ? 'Clique para acessar a página do seu LinkedIn'
              : 'Clique para inserir o seu LinkedIn'}},[_c('b-icon',{style:(_vm.account.linkedin != ''
                ? 'color: rgb(2,209,168);'
                : 'color: gray;'),attrs:{"pack":"fab","icon":"linkedin","size":"is-large"}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }