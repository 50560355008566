import $http from "../axios";

const state = {
  requests: [],
  openRequests: 0
};

const getters = {
  requests(state) {
    return state.requests;
  },

  openRequests(state) {
    return state.openRequests;
  },

  requestsOrderedNewerToOlder: state => {
    return state.requests.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
};

const actions = {
  getRequests({ commit }) {
    return new Promise((resolve, reject) => {
      $http
        .get("requests")
        .then(res => {
          if (res.data.result === "ok") {
            commit("SET_REQUESTS", res.data.payload.requests);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  checkOpenRequests({ commit }) {
    return new Promise((resolve, reject) => {
      $http
        .get("open-requests")
        .then(res => {
          if (res.data.result === "ok") {
            commit("SET_OPEN_REQUESTS", res.data.payload.openRequests);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  makeRequest(_, request) {
    return new Promise((resolve, reject) => {
      $http
        .post(`create-${request.to}-request`, request.data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  submitCounterpartsUrlPosts(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("submit-counterpart-posts", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  submitRealAttendeeQty(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("submit-real-attendee-qty", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  confirmFormSent(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("confirm-form-sent", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  deleteCounterpart(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("delete-counterpart", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },

  SET_OPEN_REQUESTS(state, openRequests) {
    state.openRequests = openRequests;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
