import axios from "axios";
import store from "./store";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

http.interceptors.request.use(
  config => {
    if (store.getters.isAuthenticated) {
      config.headers.common['Authorization'] = 'Bearer ' + store.getters.sessionToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default http;
