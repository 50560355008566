<template>
  <div>
    <section class="mainSection">
      <tip :info="info" />

      <div class="mainBody">
        <lateral-menu />

        <div class="mainContent">
          <main-info :account="account" />

          <general-info :account="account" @inform="formGeneralActive = true">
            <div class="botao" @click="formGeneralActive = true">
              <b-icon icon="pencil-alt" size="is-large"></b-icon>
            </div>
          </general-info>
        </div>

        <b-modal
          :active.sync="formGeneralActive"
          width="800"
          :can-cancel="false"
        >
          <b-button @click="formGeneralActive = false" class="closeModal">
            <b-icon icon="times" size="is-small"></b-icon>
          </b-button>

          <form-general-info :account="account" />
        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
import tip from "../commom/DinamicTip";
import lateralMenu from "../lateral-menu/LateralMenu";
import { mapGetters } from "vuex";
import mainInfo from "./user-profile/MainInfo";
import GeneralInfo from "./user-profile/GeneralInfo";
import FormGeneralInfo from "./edit-user/FormGeneralInfo";

export default {
  name: "UserProfilePanel",

  components: {
    tip,
    lateralMenu,
    mainInfo,
    GeneralInfo,
    FormGeneralInfo
  },

  data() {
    return {
      info: {
        infoBorder: true,
        buttonText: "DICA",
        descriptionText:
          "Mantenha o seu perfil sempre atualizado e aumente as chances de ser convidado a participar de Parcerias Exclusivas com as Empresas Mentoras."
      },
      formGeneralActive: false
    };
  },

  computed: {
    ...mapGetters(["account"])
  }
};
</script>

<style scoped>
.mainSection {
  padding-top: 5rem;
  padding-bottom: 1rem;
  max-width: 75rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.mainBody {
  display: grid;
  grid-template-columns: 20rem auto;
}
.LateralMenu {
  background-color: #e9e9e9;
  margin-right: 0.5rem;
}
.mainContent {
  padding-top: 0.7rem;
}
.MainBlock {
  background-color: white;
}
.botao {
  width: 3.5rem;
  height: 3.5rem;
  background-color: white;
  border: solid 0.5px rgb(175, 175, 175);
  border-radius: 100%;
  margin-left: auto;
  margin-right: 2rem;
}
.botao:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: ease-in-out 0.1s;
}
.botao .icon {
  color: black;
  display: block;
  margin: auto;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}
.closeModal {
  border: transparent;
  right: 0.5rem;
  display: block;
  position: absolute;
}
@media (max-width: 1023px) {
  .mainBody {
    display: block;
  }
}
@media (max-width: 459px) {
  .botao {
    margin-top: 0 !important;
  }
}
</style>
