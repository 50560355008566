<template>
  <div
    v-if="opportunityBeingVisited.research.isActive"
    id="research-info"
    class="container"
  >
    <div class="columns">
      <div id="research-box" class="column box">
        <research-title
          title="Participação em Pesquisa"
          buttonText="Solicitar"
          :description="description"
          :isCardOpen="isCardOpen"
          @open-card="$emit('open-card', 'research')"
          @close-card="$emit('close-card', 'research')"
        />

        <div class="columns action-details" v-if="isCardOpen">
          <themes
            :themes="[
              'Benefícios para a Sociedade',
              'Desenvolvimento de Novas Tecnologias',
              'Desenvolvimento de Novos Materiais',
              'Desenvolvimento de Novos Produtos',
              'Desenvolvimento de Novos Serviços',
              'Iniciativas para Aumento nas Vendas',
              'Iniciativas para Redução de Custos',
              'Outros Temas de Interesse do(a) Professor(a)'
            ]"
          />

          <prerequisites
            :advance="opportunityBeingVisited.research.advance"
            :limitDateForActions="opportunityBeingVisited.limitDateForActions"
            :courses="opportunityBeingVisited.research.courses"
            :states="['Brasil Todo']"
          />

          <counterparts
            :treatmentPronoun="opportunityBeingVisited.treatmentPronoun"
            :businessName="opportunityBeingVisited.businessName"
            :logosZipURL="opportunityBeingVisited.page.logosZipURL"
            action="a ação"
          />
        </div>
      </div>
    </div>

    <b-modal :active.sync="isRequestModalActive">
      <research-request-modal
        :advance="opportunityBeingVisited.research.advance"
      />
    </b-modal>
  </div>
</template>

<script>
import { descriptions } from "../../../assets/js/actions-descriptions";
import researchTitle from "./card/Title";
import themes from "./card/Themes";
import prerequisites from "./card/Prerequisites";
import counterparts from "./card/Counterparts";
import researchRequestModal from "../request-modal/ResearchRequestModal";

export default {
  props: ["opportunityBeingVisited", "isCardOpen"],

  components: {
    researchTitle,
    themes,
    prerequisites,
    counterparts,
    researchRequestModal
  },

  data() {
    return {
      description: "",
      isRequestModalActive: false
    };
  },

  mounted() {
    this.description = descriptions.research;
  }
};
</script>

<style scoped>
#research-info {
  margin-top: 3rem;
}
@media (max-width: 1023px) {
  #research-info {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
#research-box {
  background-color: rgb(242, 242, 242);
}
.action-details {
  padding: 2rem;
}
</style>
