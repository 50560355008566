import $http from "../axios";

const state = {
  isAuthenticated: false,
  sessionToken: ""
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },

  sessionToken(state) {
    return state.sessionToken;
  }
};

const actions = {
  login({ commit }, credentials) {
    commit("LOGOUT");
    return new Promise((resolve, reject) => {
      $http
        .post("/login", credentials)
        .then(res => {
          if (res.data.result === "ok") {
            commit("LOGIN_SUCCESS", res.data.payload.sessionToken);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  loginWithGoogle({ commit }, credentials) {
    commit("LOGOUT");
    return new Promise((resolve, reject) => {
      $http
        .post("/login-with-google", credentials)
        .then(res => {
          if (res.data.result === "ok") {
            commit("LOGIN_SUCCESS", res.data.payload.sessionToken);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  logout({ commit }) {
    commit("CLEAR_ACCOUNT_DATA");
    commit("CLEAR_OPPORTUNITIES");
    commit("LOGOUT");
  },

  forgotPassword(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("/forgot-password", {
          email: data.email
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  resetPassword(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("/reset-password", {
          resetToken: data.resetToken,
          password: data.password
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

const mutations = {
  LOGIN_SUCCESS(state, sessionToken) {
    state.isAuthenticated = true;
    state.sessionToken = sessionToken;
  },

  LOGOUT(state) {
    state.isAuthenticated = false;
    state.sessionToken = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
