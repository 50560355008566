<template>
  <section>
    <div class="head">
      <h1 class="has-text-weight-bold">SOLICITAÇÕES EM ANDAMENTO</h1>
    </div>

    <div class="header">
      <div><span>Empresa</span></div>
      <div><span>Status</span></div>
      <div><span>Ação</span></div>
      <div><span>Data</span></div>
      <div><span>Contrapartidas</span></div>
      <!-- <div><span>Nota da Ação</span></div> -->
      <div><span>Logos</span></div>
    </div>

    <div class="requests" v-if="requests.length > 0">
      <div class="request" v-for="(request, i) in requests" :key="i">
        <div>
          <img :src="request.opportunity.page.businessLogoURL" />
        </div>

        <div>
          <span> {{ requestStatus(request.requestStatus) }} </span>
        </div>

        <div>
          <span>
            <span v-if="windowWidth <= 720">Ação: </span>
            {{ requestTypeText(request.requestType, 1) }}
          </span>
        </div>

        <div>
          <span v-if="request.eventInfo.dateOptions.agreed.start">
            <span v-if="windowWidth <= 720">Data: </span>
            {{
              `${
                hasSpecificTime(request.requestType)
                  ? moment(request.eventInfo.dateOptions.agreed.start).format(
                      "DD/MM/YY HH:mm"
                    )
                  : moment(request.eventInfo.dateOptions.agreed.start).format(
                      "DD/MM/YY"
                    )
              }`
            }}
          </span>

          <span v-else @click="showDateOptions(request)">
            <span v-if="windowWidth <= 720 ">Data: </span>
            <b-icon icon="info-circle" size="is-medium"></b-icon>
          </span>
        </div>

        <div>
          <span
            v-if="request.counterparts.posts && request.counterparts.photos"
          >
            <span v-if="windowWidth <= 720 ">Contrapartidas: </span>Enviado
            <br />

            <span class="wordLink" @click="openCounterpartsModal(request)">
              (Reenviar/Corrigir)
            </span>
          </span>

          <span v-else class="wordLink" @click="openCounterpartsModal(request)">
            <span v-if="windowWidth <= 720 ">Contrapartidas: </span>Enviar
            <br />
            <span style="color: red">{{ isLate(request) }}</span>
          </span>
        </div>

        <!-- <div>
          <span
            ><span v-if="windowWidth <= 720 ">Nota da Ação: </span>★ 4,8
            <br />
            <span class="wordLink" @click="openStockNoteModal(request)">Saiba mais</span>
          </span>
        </div> -->

        <div>
          <span>
            <a :href="request.opportunity.page.logosZipURL" target="_blank">
              <span v-if="windowWidth <= 720">Logos: </span>
              Link
            </a>
          </span>
        </div>
      </div>
    </div>

    <div v-else style="text-align: center;">
      Não há solicitações em andamento
    </div>

    <b-modal :active="modalCounterparts" :width="800" :can-cancel="false">
      <b-button @click="modalCounterparts = false" class="closeModal">
        <b-icon icon="times" size="is-small"></b-icon>
      </b-button>

      <counterparts :request="requestCounterpart" />
    </b-modal>

    <b-modal :active="modalStockNote" :width="730" :can-cancel="false">
      <b-button @click="modalStockNote = false" class="closeModal">
        <b-icon icon="times" size="is-small"></b-icon>
      </b-button>
      <stockNote :request="requestStockNote" />
    </b-modal>
  </section>
</template>

<script>
import { responsive } from "../../../mixins/responsive";
import { request } from "../../../mixins/request";
import { momentMixin } from "../../../mixins/moment";
import counterparts from "./Counterparts";
import stockNote from "./StockNote";
export default {
  name: "requestOnGoing",
  props: ["requests"],
  components: { counterparts, stockNote },
  mixins: [request, momentMixin, responsive],
  data() {
    return {
      modalCounterparts: false,
      modalStockNote: false,
      requestCounterpart: null,
      requestStockNote: null
    };
  },
  methods: {
    showDateOptions(request) {
      let first = "";
      let second = "";
      let third = "";
      if (this.hasSpecificTime(request.requestType)) {
        first = this.moment(request.eventInfo.dateOptions.first.start).format(
          "DD/MM/YY HH:mm"
        );
        second = request.eventInfo.dateOptions.second.start
          ? this.moment(request.eventInfo.dateOptions.second.start).format(
              "DD/MM/YY HH:mm"
            )
          : null;
        third = request.eventInfo.dateOptions.third.start
          ? this.moment(request.eventInfo.dateOptions.third.start).format(
              "DD/MM/YY HH:mm"
            )
          : null;
      } else {
        first = this.moment(request.eventInfo.dateOptions.first.start).format(
          "DD/MM/YY"
        );
        second = request.eventInfo.dateOptions.second.start
          ? this.moment(request.eventInfo.dateOptions.second.start).format(
              "DD/MM/YY"
            )
          : null;
        third = request.eventInfo.dateOptions.third.start
          ? this.moment(request.eventInfo.dateOptions.third.start).format(
              "DD/MM/YY"
            )
          : null;
      }
      this.$buefy.dialog.alert({
        title: "Datas",
        message: `<p> A empresa mentora <b>${
          request.opportunity.businessName
        }</b> ainda não confirmou a data do evento.</p><p>As opções de datas escolhidas por você foram:</p><br/> ▫️ 1ª Opção: ${first}${
          second ? `, <br> ▫️ 2ª Opção: ${second}` : ""
        }${
          third ? `, <br> ▫️ 3ª Opção: ${third}` : ""
        }<br/><br/>Caso haja alguma dúvida, por favor entre em contato com o gestor Empower responsável pela a ação.`,
        confirmText: "Ok!",
        hasIcon: true,
        icon: "calendar-alt"
      });
    },
    openCounterpartsModal(request) {
      this.modalCounterparts = true;
      this.requestCounterpart = request;
    },
    openStockNoteModal(request){
      this.modalStockNote = true;
      this.requestStockNote = request;
    },
    isLate(request) {
      if (request.eventInfo.dateOptions.agreed.start) {
        let today = new Date();
        let dateOfAction = new Date(request.eventInfo.dateOptions.agreed.end);
        let difference =
          (today.getTime() - dateOfAction.getTime()) / 1000 / 60 / 60 / 24;
        if (Math.ceil(difference) > 7) {
          return "(Atrasado)";
        }
      }
    },
  },
};
</script>

<style scoped>
section {
  background-color: white;
  border-radius: 6px;
  padding-bottom: 0.1rem;
}
h1 {
  background-image: linear-gradient(to right, #000000, #7c41bf);
  padding: 1rem 3rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: white;
  font-size: 1.8rem;
}
.header,
.request {
  border: solid #bbbbbb 2px;
  border-radius: 0.5rem;
  display: flex;
  margin: 1rem;
}
.header div,
.request div {
  width: 15%;
  min-width: 5rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
}
.request div {
  display: flex;
}
.request span, .header span {
  margin: auto;
}
.request .icon:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: ease-in-out 0.1s;
}
.wordLink {
  color: #485fc7;
  cursor: pointer;
}
.wordLink:hover {
  color: black;
}
.closeModal {
  border: transparent;
  right: 0.5rem;
  display: block;
  position: absolute;
  background-color: rgb(200, 200, 200);
}
@media (max-width: 720px){
  .header{
    display: none;
  }
  .request {
  display: block;
  }
  .request img{
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  width: 100%;
  }
  .request div{
  width: 100%;
  }
}
</style>