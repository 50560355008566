<template>
  <div>
    <logged-in-header />
    <opportunities-panel />
    <empower-footer />
  </div>
</template>

<script>
import loggedInHeader from "../../components/commom/LoggedInHeader";
import empowerFooter from "../../components/commom/EmpowerFooter";
import opportunitiesPanel from "../../components/opportunities/OpportunitiesPanel";

export default {
  components: {
    loggedInHeader,
    empowerFooter,
    opportunitiesPanel
  }
};
</script>
