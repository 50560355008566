<template>
  <section>
    <div class="head">
      Nota da Ação: ★ 4,8
    </div>
    <div class="body">
      <div class="mainInfo">
        <img :src="request.opportunity.page.businessLogoURL" />
        <div class="actionInfo">
          {{ requestTypeText(request.requestType, 1) }}
          <br />
          <span v-if="request.eventInfo.title">
            Tema: {{ request.eventInfo.title }}
          </span>
        </div>
      </div>
      <div class="notes">
        <div class="titleNote">Público Presente ★ 4,8</div>
        <span class="description">
          Regras: <br />
          NOTA 5: Se o PÚBLICO PRESENTE foi igual ou maior que o PÚBLICO
          ESTIMADO <br />
          NOTA 3: Se o PÚBLICO PRESENTE estiver entre 75% e 99% do PÚBLICO
          ESTIMADO <br />
          NOTA 1: Se o PÚBLICO PRESENTE foi menor que 75% do PÚBLICO ESTIMADO
        </span>

        <div class="titleNote">Post Pós-Evento ★ 4,8</div>
        <span class="description">
          Regras: <br />
          NOTA 5: Se o POST foi publicado, compartilhado com a Empower e segue
          todos os critérios abaixo: <br />
          O post é pós-evento (não valem posts de divulgação e inscrição) <br />
          O post é exclusivo para a ação da Empower <br />
          Os logos da Empower e da empresa estão corretos no post <br />
          O post está no feed da rede social <br />
          <br />
          NOTA 3: Se o POST foi publicado e compartilhado com a Empower, mas não
          cumpre algum dos critérios abaixo: <br />
          O post é pós-evento (não valem posts de divulgação e inscrição) <br />
          O post é exclusivo para a ação da Empower <br />
          Os logos da Empower e da empresa estão corretos no post <br />
          O post está no feed da rede social <br />
          <br />
          NOTA 0: Se o POST não foi enviado.
        </span>

        <div class="titleNote">Prints/Fotos ★ 4,8</div>
        <span class="description">
          Regras: <br />
          NOTA 5: Se os PRINTS foram enviados e com boa qualidade <br />
          NOTA 3: Se os PRINTS foram enviados, mas com baixa qualidade <br />
          NOTA 0: Se os PRINTS não foram enviados
        </span>

        <div class="titleNote">FATOR DE PONTUALIDADE</div>
        <span class="description">
          Regras: <br />
          FATOR 1: Se todas as contrapartidas foram enviadas até 7 dias após a
          realização da ação <br />
          FATOR 0,9: Se todas as contrapartidas foram enviadas até 14 dias após
          a realização da ação <br />
          FATOR 0,7: Se todas as contrapartidas foram enviadas até 21 dias após
          a realização da ação <br />
          FATOR 0,5: Se todas as contrapartidas foram enviadas até 28 dias após
          a realização da ação <br />
          FATOR 0,3: Se as contrapartidas foram enviadas a mais de 28 dias após
          a realização da ação
        </span>

        <div class="titleNote">Cálculo da Nota ★ 4,8</div>
        <span class="description">
          ((NOTA_PUBLICO * 2 + NOTA_FOTOSPRINTS + NOTA_FORMULÁRIO) / 4) *
          FATOR_PONTUALIDADE = NOTA_FINAL
        </span>
      </div>
      <br />
      <div style="margin-left: auto; margin-right: auto; width: fit-content;">
        Dúvidas? Acesse o gestor Empower responsável pela ação.
      </div>
    </div>
  </section>
</template>

<script>
import { request } from "../../../mixins/request";
export default {
  name: "StockNote",
  props: ["request"],
  mixins: [request]
};
</script>

<style scoped>
.head {
  text-align: center;
  background-color: rgb(200, 200, 200);
  font-size: 1.4rem;
  padding: 1rem;
  color: black;
  border-radius: 0.3rem 0.3rem 0 0;
}
.body {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}
.mainInfo {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}
.mainInfo img {
  max-width: 20rem;
  width: 60%;
}
.actionInfo {
  font-size: 1.3rem;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
.titleNote {
  font-size: 1.3rem;
  font-weight: bold;
  color: black;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
</style>
