<template>
  <section>
    <logged-in-header />
    <user-profile-panel class="UpPanel" />
    <empower-footer />
  </section>
</template>

<script>
import loggedInHeader from "../../components/commom/LoggedInHeader";
import empowerFooter from "../../components/commom/EmpowerFooter";
import UserProfilePanel from "../../components/user/UserProfilePanel";
export default {
  name: 'UserProfile',
  components: {
    loggedInHeader,
    empowerFooter,
    UserProfilePanel
  }
}
</script>

<style scoped>
.UpPanel{
  background-color: #e9e9e9;
}
</style>