<template>
  <section class="hero is-fullheight painelGrid">
    <lateral-menu class="menuLateral" />
    <div class="hero-body">
      <b-loading
        :is-full-page="true"
        :active="isLoading"
        :can-cancel="false"
      ></b-loading>

      <div class="container box has-text-centered">
        <h1 class="title">Configurações</h1>
        <hr />

        <h2 class="subtitle" @click="isMyDataOpen = !isMyDataOpen">
          Meus dados&nbsp;&nbsp;
          <b-icon icon="angle-down" v-if="!isMyDataOpen"></b-icon>
          <b-icon icon="angle-up" v-if="isMyDataOpen"></b-icon>
        </h2>

        <div v-if="isMyDataOpen" class="has-text-left">
          <b-field
            :label="localAccount.name ? 'Nome' : ''"
            label-position="on-border"
          >
            <b-input
              v-model="localAccount.name"
              class="is-expanded"
              type="text"
              placeholder="Nome"
            ></b-input>
          </b-field>

          <b-field
            :label="localAccount.surname ? 'Sobrenome' : ''"
            label-position="on-border"
          >
            <b-input
              v-model="localAccount.surname"
              class="is-expanded"
              type="text"
              placeholder="Sobrenome"
            ></b-input>
          </b-field>

          <b-field label="E-mail" label-position="on-border">
            <b-input v-model="localAccount.originalEmail" disabled></b-input>
          </b-field>

          <b-field
            :label="localAccount.state ? 'UF / Cidade' : ''"
            label-position="on-border"
          >
            <b-field>
              <b-select v-model="localAccount.state" placeholder="UF">
                <option value="">UF</option>
                <option value="Acre">AC</option>
                <option value="Alagoas">AL</option>
                <option value="Amapá">AP</option>
                <option value="Amazonas">AM</option>
                <option value="Bahia">BA</option>
                <option value="Ceará">CE</option>
                <option value="Distrito Federal">DF</option>
                <option value="Espírito Santo">ES</option>
                <option value="Goiás">GO</option>
                <option value="Maranhão">MA</option>
                <option value="Mato Grosso">MT</option>
                <option value="Mato Grosso do Sul">MS</option>
                <option value="Minas Gerais">MG</option>
                <option value="Pará">PA</option>
                <option value="Paraíba">PB</option>
                <option value="Paraná">PR</option>
                <option value="Pernambuco">PE</option>
                <option value="Piauí">PI</option>
                <option value="Rio de Janeiro">RJ</option>
                <option value="Rio Grande do Norte">RN</option>
                <option value="Rio Grande do Sul">RS</option>
                <option value="Rondônia">RO</option>
                <option value="Roraima">RR</option>
                <option value="Santa Catarina">SC</option>
                <option value="São Paulo">SP</option>
                <option value="Sergipe">SE</option>
                <option value="Tocantins">TO</option>
              </b-select>
              <b-autocomplete
                v-model="localAccount.city"
                expanded
                :data="filteredCitiesArray"
                :open-on-focus="true"
                :keep-first="true"
                placeholder="Cidade"
                @select="option => selected = option"
                :disabled="!isValidState"
              >
                <template slot="empty">Sem resultados</template>
              </b-autocomplete>
            </b-field>
          </b-field>

          <b-field
            :label="localAccount.university ? 'Universidade' : ''"
            label-position="on-border"
          >
            <b-autocomplete
              v-model="localAccount.university"
              :data="filteredUniversitiesArray"
              :open-on-focus="true"
              :keep-first="true"
              placeholder="Universidade"
              @select="option => selected = option"
              :disabled="!isValidState"
            >
              <template slot="empty">
                Sem resultados
              </template>
            </b-autocomplete>
          </b-field>

          <b-field
            :label="localAccount.anotherUniversity ? 'Qual outra universidade?' : ''"
            v-show="isAnotherUniversity"
          >
            <b-input
              v-model="localAccount.anotherUniversity"
              placeholder="Qual outra universidade?"
            ></b-input>
          </b-field>

          <b-field
            :label="localAccount.course ? 'Curso' : ''"
            label-position="on-border"
          >
            <b-autocomplete
              v-model="localAccount.course"
              expanded
              :data="filteredCoursesArray"
              :open-on-focus="true"
              :keep-first="true"
              placeholder="Seu curso"
              @select="option => selected = option"
            >
              <template slot="empty">
                Sem resultados
              </template>
            </b-autocomplete>
          </b-field>

          <br />

          <div class="has-text-centered">
            <button class="button is-primary" @click="updateAccount">
              Atualizar Dados
            </button>
          </div>
        </div>
        <hr />

        <h2
          class="subtitle"
          @click="isChangePasswordOpen = !isChangePasswordOpen"
        >
          Alterar Senha&nbsp;&nbsp;
          <b-icon icon="angle-down" v-if="!isChangePasswordOpen"></b-icon>
          <b-icon icon="angle-up" v-if="isChangePasswordOpen"></b-icon>
        </h2>

        <div v-if="isChangePasswordOpen">
          <b-field
            :label="password ? 'Senha Atual' : ''"
            label-position="on-border"
          >
            <b-input
              v-model="password"
              type="password"
              placeholder="Senha Atual"
              password-reveal
            ></b-input>
          </b-field>
          <br />

          <b-field
            :label="newPassword ? 'Nova Senha' : ''"
            label-position="on-border"
          >
            <b-input
              v-model="newPassword"
              type="password"
              placeholder="Nova Senha"
              password-reveal
            ></b-input>
          </b-field>
          <br />

          <button class="button is-primary" @click="updatePassword">
            Alterar Senha
          </button>
        </div>
        <hr />

        <h2
          class="subtitle"
          @click="isDeleteAccountOpen = !isDeleteAccountOpen"
        >
          Deletar Conta&nbsp;&nbsp;
          <b-icon icon="angle-down" v-if="!isDeleteAccountOpen"></b-icon>
          <b-icon icon="angle-up" v-if="isDeleteAccountOpen"></b-icon>
        </h2>

        <div v-if="isDeleteAccountOpen">
          <b-field
            :label="deleteAccountPassword ? 'Senha Atual' : ''"
            label-position="on-border"
          >
            <b-input
              v-model="deleteAccountPassword"
              type="password"
              placeholder="Senha Atual"
              password-reveal
            ></b-input>
          </b-field>
          <br />

          <button class="button is-danger" @click="deleteAccount">
            Deletar Conta
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { cidades_por_estado } from "../../assets/js/cidades-por-estado";
import { estados } from "../../assets/js/estados";
import { cursos } from "../../assets/js/cursos";
import { universidades_por_estado } from "../../assets/js/universidades-por-estado";
import { commom } from "../../mixins/commom.js";
import LateralMenu from '../lateral-menu/LateralMenu.vue';

export default {
  components: { LateralMenu },
  mixins: [commom],

  data() {
    return {
      localAccount: {
        name: "",
        surname: "",
        originalEmail: "",
        city: "",
        state: "",
        university: "",
        anotherUniversity: "",
        course: ""
      },

      isLoading: false,
      isMyDataOpen: false,
      isChangePasswordOpen: false,
      isDeleteAccountOpen: false,
      password: "",
      newPassword: "",
      deleteAccountPassword: "",
      citiesList: {},
      coursesList: [],
      universitiesList: {},
      statesList: []
    };
  },

  computed: {
    ...mapGetters(["account"]),
    filteredCitiesArray() {
      let filteredCitiesArray = [];
      if (this.isValidState) {
        filteredCitiesArray = this.citiesList[this.localAccount.state].filter(option => {
          return option
            .toString()
            .toLowerCase()
            .indexOf(this.localAccount.city.toLowerCase()) >= 0
        });
      }
      return filteredCitiesArray;
    },
    filteredUniversitiesArray() {
      let filteredUniversitiesArray = [];
      if (this.isValidState) {
        filteredUniversitiesArray = this.universitiesList[this.localAccount.state].filter(option => {
          return option
            .toString()
            .toLowerCase()
            .indexOf(this.localAccount.university.toLowerCase()) >= 0
        });
      }
      return filteredUniversitiesArray;
    },
    filteredCoursesArray() {
      let filteredCoursesArray = [];
      filteredCoursesArray = this.coursesList.filter(option => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.localAccount.course.toLowerCase()) >= 0
      });
      return filteredCoursesArray;
    },
    isAnotherUniversity() {
      return this.localAccount.university === "Outra";
    },
    isValidState() {
      return this.statesList.includes(this.localAccount.state);
    }
  },

  methods: {
    updateAccount() {
      this.isLoading = true;
      this.$store
        .dispatch("updateAccount", this.localAccount)
        .then(() => {
          this.isMyDataOpen = false;
          this.$buefy.dialog.alert({
            title: "Sucesso!",
            message: "Seus dados foram atualizados ;)",
            type: "is-success",
            hasIcon: true,
            icon: "check",
            iconPack: "fa"
          });
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updatePassword() {
      this.isLoading = true;
      this.$store
        .dispatch("updatePassword", {
          password: this.password,
          newPassword: this.newPassword
        })
        .then(() => {
          this.password = "";
          this.newPassword = "";
          this.isChangePasswordOpen = false;
          this.$buefy.dialog.alert({
            title: "Sucesso!",
            message: "Sua senha foi alterada ;)",
            type: "is-success",
            hasIcon: true,
            icon: "check",
            iconPack: "fa"
          });
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteAccount() {
      this.isLoading = true;
      this.$store
        .dispatch("deleteAccount", {
          "password": this.deleteAccountPassword
        })
        .then(() => {
          this.$buefy.dialog.alert({
            title: "Conta deletada!",
            message: "Sentimos pela sua partida, mas vida que segue :(",
            type: "is-success",
            hasIcon: true,
            icon: "check",
            iconPack: "fa",
            onConfirm: () => this.$router.push("/"),
            onCancel: () => this.$router.push("/")
          });
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.citiesList = cidades_por_estado;
    this.coursesList = cursos;
    this.universitiesList = universidades_por_estado;
    this.statesList = estados;
    this.localAccount = this.account;
  }
};
</script>

<style scoped>
section {
  background-image: linear-gradient(#02f8c7, #e9e9e9 40%);
}
.error-background {
  background-color: #ff9797 !important;
}
@media (min-width: 500px) {
  .container {
    max-width: 70%;
  }
}
@media (min-width: 800px) {
  .container {
    max-width: 50%;
  }
}
@media (min-width: 1024px){
  .painelGrid{
    display: grid;
    grid-template-columns: auto 50% auto;
  }
  .menuLateral{
    margin-top: 40px;
    margin-left: 20px;
    background-color: #e9e9e9;
  }
  .container{
    max-width: 100%;
  }
}
</style>
