<template>
  <section
    class="section has-text-white"
    :class="{ 'upper-border': hasUpperBorder }"
  >
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <div class="columns is-vcentered columns-parceria">
      <div class="column">
        <p
          class="parceria"
          :class="{
            'has-text-right': !isMobile,
            'has-text-centered': isMobile
          }"
        >
          Parceria Institucional
        </p>
      </div>

      <div class="column" :class="{ 'has-text-centered': isMobile }">
        <img
          id="logo-cia-de-talentos"
          src="../../assets/img/logo-cia-de-talentos.png"
          alt=""
        />
      </div>
    </div>

    <hr />

    <div class="columns is-mobile is-multiline columns-links">
      <div class="column is-half-mobile">
        <h3>SAIBA MAIS</h3>

        <a href="https://empower.vision/empower" class="link" target="_blank">
          <p>A Empower</p>
        </a>

        <a href="https://empower.vision/inovacao" class="link" target="_blank">
          <p>Inovação</p>
        </a>

        <a
          href="https://s3.amazonaws.com/assets.empower.vision/pdf/terms-and-conditions/professor/EMPOWER+-+Professores+-+Termos+de+Uso+e+Poli%CC%81tica+de+Privacidade+(1).pdf"
          target="_blank"
        >
          <p>Termos e Política</p>
        </a>

        <span v-if="!isMobile">
          <br />
          <br />
          <img
            id="logo-empower-branco"
            src="../../assets/img/logos-empower/logo-empower-branco.png"
            alt=""
            v-if="!isMobile"
          />
        </span>
      </div>

      <div class="column is-half-mobile">
        <h3>JOVENS</h3>

        <a href="https://empower.vision" class="link" target="_blank">
          <p>Rede Empower</p>
        </a>

        <a href="https://empower.vision" class="link" target="_blank">
          <p>Parcerias</p>
        </a>

        <a href="https://empower.vision/banco-de-talentos" class="link" target="_blank">
          <p>Trabalhe Conosco</p>
        </a>

        <span v-if="!isMobile">
          <br />
          <br />
          <a href="https://www.instagram.com/empower.oficial/" target="_blank">
            <img
              class="logo-rede-social"
              src="../../assets/img/redes-sociais/logo-instagram-verde.png"
              alt=""
            />
          </a>
          <a href="https://www.facebook.com/empower.oficial" target="_blank">
            <img
              class="logo-rede-social"
              src="../../assets/img/redes-sociais/logo-facebook-verde.png"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/empower-relacionamento-e-evolucao"
            target="_blank"
          >
            <img
              class="logo-rede-social"
              src="../../assets/img/redes-sociais/logo-linkedin-verde.png"
              alt=""
            />
          </a>
        </span>
      </div>

      <div class="column is-half-mobile">
        <h3>EMPRESAS</h3>

        <a href="https://empower.vision/empresas" class="link" target="_blank">
          <p>Employer Branding</p>
        </a>
      </div>

      <div class="column is-half-mobile">
        <h3>CONTATO</h3>

        <a
          href="https://empower.vision/fale-conosco-empresas"
          class="link"
          target="_blank"
        >
          <p>Para Empresas</p>
        </a>

        <a
          href="https://empower.vision/fale-conosco-jovens"
          class="link"
          target="_blank"
        >
          <p>Para Jovens</p>
        </a>
      </div>

      <div class="column is-half-mobile" v-if="isMobile">
        <br />
        <img
          id="logo-empower-branco"
          src="../../assets/img/logos-empower/logo-empower-branco.png"
          alt=""
        />
      </div>

      <div class="column is-half-mobile has-text-centered" v-if="isMobile">
        <br />
        <a href="https://www.instagram.com/empower.oficial/" target="_blank">
          <img
            class="logo-rede-social"
            src="../../assets/img/redes-sociais/logo-instagram-verde.png"
            alt=""
          />
        </a>
        <a href="https://www.facebook.com/empower.oficial" target="_blank">
          <img
            class="logo-rede-social"
            src="../../assets/img/redes-sociais/logo-facebook-verde.png"
            alt=""
          />
        </a>
        <a
          href="https://www.linkedin.com/company/empower-relacionamento-e-evolucao"
          target="_blank"
        >
          <img
            class="logo-rede-social"
            src="../../assets/img/redes-sociais/logo-linkedin-verde.png"
            alt=""
          />
        </a>
      </div>
    </div>

    <hr />

    <nav class="columns columns-copywright">
      <div
        class="column"
        :class="isMobile ? 'has-text-centered' : 'has-text-left'"
      >
        <p>
          © {{ new Date().getFullYear() }} Empower. Todos os direitos
          reservados.
        </p>
      </div>

      <div
        class="column"
        :class="isMobile ? 'has-text-centered' : 'has-text-right'"
      >
        <p>
          <img
            id="simbolo-empower-verde-rodape"
            src="../../assets/img/logos-empower/simbolo-verde.png"
            alt=""
            v-if="!isMobile"
          />
          Conectamos o potencial das universidades com a potência do mercado.
          <span v-if="isMobile">
            <br />
            <br />
            <img
              id="simbolo-empower-verde-rodape"
              src="../../assets/img/logos-empower/simbolo-verde.png"
              alt=""
            />
          </span>
        </p>
      </div>
    </nav>
  </section>
</template>

<script>
import { responsive } from "../../mixins/responsive";
import { commom } from "../../mixins/commom";

export default {
  props: ["hasUpperBorder"],

  mixins: [commom, responsive],

  data() {
    return {
      email: "",
      isLoading: false
    };
  }
}
</script>

<style scoped>
.upper-border {
  border-top: 1px solid #555555;
}
section {
  background-color: black;
  padding: 2rem 0 1.5rem;
}
h3, p {
  color: white;
}
p {
  margin-top: .8rem;
}
hr {
  height: 1px;
  background-color: #555555;
  width: 100%;
}
.columns {
  margin: 0;
  padding: 2rem 5%;
}
@media (max-width: 1196px) {
  .columns {
    padding: 2rem 5%;
  }
}
@media (max-width: 940px) {
  .columns {
    padding: 2rem 2%;
  }
}
.columns-parceria {
  padding: 0;
}
.columns-copywright {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1400px;
  margin: auto;
}
#logo-empower-branco {
  height: 23px;
}
.logo-rede-social {
  width: 22px;
  margin-right: .7rem;
  cursor: pointer;
}
#logo-cia-de-talentos {
  max-width: 160px;
}
#simbolo-empower-verde-rodape {
  max-width: 26px;
  margin-right: 6px;
}
.parceria {
  font-size: 20px;
  margin-top: 0;
}
@media (min-width: 769px) {
  .parceria {
    margin-right: 2rem;
  }
  #logo-cia-de-talentos {
    margin-left: 2rem;
  }
}
.columns-links {
  max-width: 1400px;
  margin: auto;
}
.link {
  cursor: pointer;
}
</style>