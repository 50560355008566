/* https://pt.wikipedia.org/wiki/Lista_de_cursos_superiores_do_Brasil */
const cursos = [
  "Administração",
  "Agrimensura",
  "Agroecologia",
  "Agroindústria",
  "Agronomia",
  "Análise e Desenvolvimento de Sistemas",
  "Antropologia",
  "Aquacultura",
  "Arqueologia",
  "Arquitetura",
  "Arquivística",
  "Artes Cênicas",
  "Artes Visuais",
  "Astronomia",
  "Automação Industrial",
  "Bacharelado em Ciência e Tecnologia",
  "Biblioteconomia",
  "Biocombustíveis",
  "Bioengenharia",
  "Biofísica",
  "Biologia",
  "Biologia Marinha",
  "Biomedicina",
  "Bioquímica",
  "Biotecnologia",
  "Botânica",
  "Cafeicultura",
  "Ciência & Tecnologia",
  "Ciência da Computação",
  "Ciência dos Alimentos",
  "Ciência dos Materiais",
  "Ciência Política",
  "Ciências Aeronáuticas",
  "Ciências Agrárias",
  "Ciências Ambientais",
  "Ciências Atuariais",
  "Ciências Biológicas",
  "Ciências Biomoleculares",
  "Ciências Contábeis",
  "Ciências da Saúde",
  "Ciências do Estado",
  "Ciências do Meio Aquático",
  "Ciências Humanas",
  "Ciências Matemáticas da Terra",
  "Ciências Naturais",
  "Ciências Náuticas",
  "Ciências Sociais",
  "Cinema",
  "Comércio Exterior",
  "Comunicação Social",
  "Construção de Edifícios",
  "Construção de Estradas",
  "Construção Naval",
  "Cooperativismo",
  "Cosmetologia",
  "Dança",
  "Defesa e Gestão Estratégica Internacional",
  "Desenho Industrial",
  "Design de Interiores",
  "Design de Jogos Digitais",
  "Design de Moda",
  "Design de Produto",
  "Design Gráfico",
  "Direito",
  "Drenagem e Irrigação",
  "Ecologia",
  "Economia",
  "Economia Doméstica",
  "Editoração",
  "Educação Artística",
  "Educação do Campo",
  "Educação Física",
  "Energias Renováveis",
  "Enfermagem",
  "Engenharia Aeroespacial",
  "Engenharia Aeronáutica",
  "Engenharia Agrícola",
  "Engenharia Agronômica",
  "Engenharia Ambiental",
  "Engenharia Automotiva",
  "Engenharia Bioquímica",
  "Engenharia Biomédica",
  "Engenharia Cartográfica",
  "Engenharia Civil",
  "Engenharia Civil de Infraestrutura",
  "Engenharia da Informação",
  "Engenharia de Alimentos",
  "Engenharia de Bioprocessos",
  "Engenharia de Biossistemas",
  "Engenharia de Computação",
  "Engenharia de Controle e Automação",
  "Engenharia de Energia",
  "Engenharia de Manufatura",
  "Engenharia de Materiais",
  "Engenharia de Minas",
  "Engenharia de Mobilidade",
  "Engenharia de Pesca",
  "Engenharia de Petróleo",
  "Engenharia de Produção",
  "Engenharia de Saúde e Segurança",
  "Engenharia de Sistemas Eletrônicos",
  "Engenharia de Software",
  "Engenharia de Tecidos",
  "Engenharia de Telecomunicações",
  "Engenharia de Transportes e Logística",
  "Engenharia Elétrica",
  "Engenharia Eletrônica",
  "Engenharia Ferroviária e Metroviária",
  "Engenharia Física",
  "Engenharia Florestal",
  "Engenharia Geográfica",
  "Engenharia Geológica",
  "Engenharia Hídrica",
  "Engenharia Industrial Madeireira",
  "Engenharia Mecânica",
  "Engenharia Mecatrônica",
  "Engenharia Metalúrgica",
  "Engenharia Militar",
  "Engenharia Naval",
  "Engenharia Naval e Oceânica",
  "Engenharia Nuclear",
  "Engenharia Química",
  "Engenharia Sanitária",
  "Engenharia Têxtil",
  "Enologia",
  "Escultura",
  "Esporte e Lazer",
  "Estatística",
  "Eventos",
  "Farmácia",
  "Filosofia",
  "Física",
  "Física Computacional",
  "Física Médica",
  "Fisioterapia",
  "Fonoaudiologia",
  "Fotografia",
  "Fruticultura",
  "Gastronomia",
  "Geofísica",
  "Geografia",
  "Geologia",
  "Geoprocessamento e Sensoriamento Remoto",
  "Gerontologia",
  "Gestão Ambiental",
  "Gestão Comercial",
  "Gestão da Informação",
  "Gestão da Qualidade",
  "Gestão da Tecnologia da Informação",
  "Gestão de Petróleo e Gás",
  "Gestão de Recursos Hídricos",
  "Gestão de Segurança Privada",
  "Gestão do Agronegócio",
  "Gestão em Serviços Jurídicos e Notariais",
  "Gestão Financeira",
  "Gestão Hospitalar",
  "Gestão Portuária",
  "Gestão Pública",
  "Gravurismo",
  "História",
  "História da Arte",
  "Horticultura",
  "Hotelaria",
  "Informática",
  "Informática Biomédica",
  "Jornalismo",
  "Letras",
  "Linguística",
  "Logística",
  "Manutenção Industrial",
  "Marketing",
  "Matemática",
  "Matemática Aplicada",
  "Matemática Computacional",
  "Mecatrônica e Mecânica de Precisão",
  "Medicina",
  "Medicina Veterinária",
  "Meliponicultura",
  "Mercadologia",
  "Meteorologia",
  "Microbiologia e Imunologia",
  "Mineração",
  "Museologia",
  "Música",
  "Nanotecnologia",
  "Naturologia",
  "Negócios Imobiliários",
  "Neurociência",
  "Nutrição",
  "Oceanografia",
  "Odontologia",
  "Oftálmica",
  "Ordenamento do Território",
  "Paisagismo",
  "Pedagogia",
  "Pintura",
  "Processamento de Dados",
  "Processos Escolares",
  "Processos Metalúrgicos",
  "Produção Audiovisual",
  "Produção Cultural",
  "Produção de Aguardente",
  "Produção de Laticínios",
  "Produção de Materiais Plásticos",
  "Produção Fonográfica",
  "Produção Sucroalcooeira",
  "Produção Têxtil",
  "Projetos de Estruturas Aeronáuticas",
  "Psicologia",
  "Publicidade & Propaganda",
  "Química",
  "Química Ambiental",
  "Química Industrial",
  "Quiropraxia",
  "Radiologia",
  "Recursos Humanos",
  "Redes de Computadores",
  "Redes de Telecomunicações",
  "Relações Internacionais",
  "Relações Públicas",
  "Saneamento Ambiental",
  "Saúde Coletiva",
  "Secretariado",
  "Segurança da Informação",
  "Segurança no Trabalho",
  "Segurança Pública",
  "Serviço Social",
  "Silvicultura",
  "Sistemas Biomédicos",
  "Sistemas de Informação",
  "Sistemas de Navegação Fluvial",
  "Sistemas Elétricos",
  "Sistemas Embarcados",
  "Sistemas para Internet",
  "Sociologia",
  "Teatro",
  "Telemática",
  "Teologia",
  "Terapia Ocupacional",
  "Transporte Aéreo",
  "Transporte Terrestre",
  "Turismologia",
  "Urbanismo",
  "Zootecnia"
];

export { cursos };
