import $http from "../axios";

const state = {
  account: {
    name: "",
    surname: "",
    originalEmail: "",
    city: "",
    state: "",
    university: "",
    specialization: [],
    guidance: [],
    areacode: "",
    cellphone: "",
    picture: "",
    googlePictureURL: "",
    lattes: "",
    linkedin: "",
    technologistGrade: {
      courses: [],
      subjects: []
    }
  }
};

const getters = {
  account: state => {
    return state.account;
  }
};

const actions = {
  getAccountData({ commit }, account) {
    if (!account.email) {
      return new Promise((resolve, reject) => {
        $http
          .get("account-data")
          .then(res => {
            if (res.data.result === "ok") {
              commit("SET_ACCOUNT_DATA", res.data.payload.professor);
            }
            resolve(res);
          })
          .catch(e => {
            reject(e);
          });
      });
    } else {
      return { data: "ok" };
    }
  },

  updateAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("update-account-data", data)
        .then(res => {
          if (res.data.result === "ok") {
            commit("SET_ACCOUNT_DATA", res.data.payload.professor);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  updatePassword(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("update-password", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  deleteAccount(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("delete-account", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  deleteProfessorImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("delete-professor-image", data)
        .then(res => {
          if (res.data.result === "ok") {
            commit("DELETE_PROFESSOR_IMAGE");
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

const mutations = {
  SET_ACCOUNT_DATA(state, accountData) {
    state.account = accountData;
  },

  DELETE_PROFESSOR_IMAGE(state) {
    state.account.picture = null;
    state.account.googlePictureURL = null;
  },

  CLEAR_ACCOUNT_DATA(state) {
    state.account = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
