<template>
  <div
    v-if="opportunityBeingVisited.otherProf.isActive"
    id="other-info"
    class="container"
  >
    <div class="columns">
      <div id="other-box" class="column box">
        <others-title
          title="Outras Ações"
          buttonText="Solicitar"
          :description="description"
          :isCardOpen="isCardOpen"
          @open-card="$emit('open-card', 'otherProf')"
          @close-card="$emit('close-card', 'otherProf')"
        />

        <div class="columns action-details" v-if="isCardOpen">
          <themes
            :themes="[
              'Concursos',
              'Desafios',
              'Hackathons',
              'Mesas-redondas',
              'Outras ações...'
            ]"
          />

          <prerequisites
            :advance="opportunityBeingVisited.otherProf.advance"
            :limitDateForActions="opportunityBeingVisited.limitDateForActions"
            :courses="opportunityBeingVisited.otherProf.courses"
            :states="['Brasil Todo']"
          />

          <counterparts
            :treatmentPronoun="opportunityBeingVisited.treatmentPronoun"
            :businessName="opportunityBeingVisited.businessName"
            :logosZipURL="opportunityBeingVisited.page.logosZipURL"
            action="a ação"
          />
        </div>
      </div>
    </div>

    <b-modal :active.sync="isRequestModalActive">
      <other-request-modal />
    </b-modal>
  </div>
</template>

<script>
import { descriptions } from "../../../assets/js/actions-descriptions";
import othersTitle from "./card/Title";
import themes from "./card/Themes";
import prerequisites from "./card/Prerequisites";
import counterparts from "./card/Counterparts";
import otherRequestModal from "../request-modal/OtherRequestModal";

export default {
  props: ["opportunityBeingVisited", "isCardOpen"],

  components: {
    othersTitle,
    themes,
    prerequisites,
    counterparts,
    otherRequestModal
  },

  data() {
    return {
      description: "",
      isRequestModalActive: false
    };
  },

  mounted() {
    this.description = descriptions.otherProf;
  }
};
</script>

<style scoped>
#other-info {
  margin-top: 3rem;
}
@media (max-width: 1023px) {
  #other-info {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
#other-box {
  background-color: rgb(242, 242, 242);
}
.action-details {
  padding: 2rem;
}
</style>
