import $http from "../axios";

const state = {
  opportunities: [],
  opportunityBeingVisited: {}
};

const getters = {
  opportunities: state => {
    return state.opportunities;
  },

  opportunitiesOrderedByOrder: state => {
    return state.opportunities.sort((a, b) => {
      return a.order - b.order;
    });
  },

  opportunityBeingVisited(state) {
    return state.opportunityBeingVisited.opportunity;
  }
};

const actions = {
  getOpportunities({ commit }) {
    return new Promise((resolve, reject) => {
      $http
        .get("opportunities")
        .then(res => {
          if (res.data.result === "ok") {
            commit("SET_OPPORTUNITIES", res.data.payload.opportunities);
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  accountLinkClick(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("account-link-click", data)
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },

  setOpportunityBeingVisited({ commit }, opportunity) {
    commit("SET_OPPORTUNITY_BEING_VISITED", opportunity);
  }
};

const mutations = {
  SET_OPPORTUNITIES(state, opportunities) {
    state.opportunities = opportunities;
  },

  SET_OPPORTUNITY_BEING_VISITED(state, opportunity) {
    state.opportunityBeingVisited = opportunity;
  },

  CLEAR_OPPORTUNITIES(state) {
    state.opportunities = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
