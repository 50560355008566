const commom = {
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },

    alertError(data) {
      if (data.message === "EXPIRED_TOKEN") {
        this.alertExpiredToken();
      } else {
        this.alertCustomError(data.message);
      }
    },

    alertExpiredToken() {
      this.$buefy.dialog.alert({
        title: "Sua sessão expirou",
        message: "Faça login novamente",
        type: "is-danger",
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        onConfirm: () => this.$router.push("/"),
        onCancel: () => this.$router.push("/")
      });
    },

    alertCustomError(err) {
      this.$buefy.dialog.alert({
        title: "Erro :(",
        message: this.translateError(err),
        type: "is-warning",
        hasIcon: true,
        icon: "exclamation-triangle",
        iconPack: "fa"
      });
    },

    translateError(message) {
      const translations = {
        ACCOUNT_DELETED:
          "Sua conta foi deletada, por favor, entre em contato conosco para recupera-la.",
        ACCOUNT_NOT_FOUND:
          "Não encontramos cadastro em nossa base com este email.",
        ADD_AT_LEAST_ONE_URL_POST_COUNTERPART:
          "Você deve adicionar no mínimo uma URL referente ao post exigido pela contrapartida.",
        AT_LEAST_ONE_SOCIAL_NETWORK_IS_REQUIRED:
          "Você deve adicionar no mínimo uma rede social",
        BEGIN_DATE_CANNOT_BE_AFTER_END_DATE:
          "A data de início não pode ser posterior à data de fim",
        BEGIN_DATE_CANNOT_BE_EQUAL_TO_END_DATE:
          "A data de início não pode ser igual à data de fim",
        BEGIN_DATE_MUST_BE_AFTER_TODAY:
          "A data de início deve ser posterior a hoje",
        BEGIN_DATE_TOO_SOON:
          "Data de início da parceria é anterior à antecedência mínima estabelecida nos pré-requisitos.",
        CELLPHONE_CANNOT_BE_EMPTY: "O celular não pode ser vazio",
        COULD_NOT_SIGN_IN_WITH_GOOGLE: "Não foi possível logar com o Google",
        DATES_1_AND_2_ARE_REPEATED:
          "Não são permitidas opções de datas repetidas (data 1 e data 2)",
        DATES_1_AND_3_ARE_REPEATED:
          "Não são permitidas opções de datas repetidas (data 1 e data 3)",
        DATES_2_AND_3_ARE_REPEATED:
          "Não são permitidas opções de datas repetidas (data 2 e data 3)",
        DATE_TOO_SOON:
          "A data é anterior à antecedência mínima estabelecida nos pré-requisitos.",
        DUPLICATED_CONTACTS:
          "Por favor, informe ao menos 2 outros contatos que não sejam repetidos",
        DUPLICATED_COURSES:
          "Existem cursos duplicados na discriminação de graduandos por curso. Por favor, unifique-os.",
        END_DATA_MUST_BE_AFTER_BEGIN_DATE:
          "A data de término deve ser posterior à data de início",
        END_DATE_MUST_BE_AFTER_TODAY: "A data de fim deve ser posterior a hoje",
        EMAIL_ALREADY_REGISTERED: "Este e-mail já está registrado, faça login.",
        EMAIL_CONFIRMATION_TOKEN_NOT_FOUND:
          "Não foi possível validar sua conta. Se estiver com dificuldades, por favor, entre em contato conosco pelo e-mail empower@empower.vision",
        EMAIL_CANNOT_BE_EMPTY: "O e-mail não pode ser vazio",
        FILE_EXTENSION_NOT_ALLOWED: "Esta extensão de arquivo não é permitida",
        FILE_IS_TOO_LARGE: "O arquivo é muito grande (max. 5mb)",
        FILE_IS_REQUIRED: "É obrigatório o envio de um arquivo",
        FIRST_DATE_IS_INVALID: "A data é inválida (data 1)",
        FIRST_DATE_MUST_BE_AFTER_TODAY:
          "A data precisa ser maior que hoje (data 1)",
        FIRST_DATE_OPTION_TOO_SOON:
          "A primeira opção de data é anterior à antecedência mínima estabelecida nos pré-requisitos.",
        FIRST_DATE_OPTION_AFTER_LIMIT_DATE:
          "A primeira opção de data é posterior à data limite.",
        FIRST_DATE_END_TIME_IS_INVALID: "O horário de fim é inválido (data 1)",
        FIRST_DATE_END_TIME_CANNOT_BE_BEFORE_START_TIME:
          "O horário de fim não pode ser menor que o de início (data 1)",
        FIRST_DATE_START_TIME_IS_INVALID:
          "O horário de início é inválido (data 1)",
        FIRST_KNOWLEDGE_DEMAND_CANNOT_BE_EMPTY:
          "A 1a demanda (área / subárea) de conhecimento não pode ser vazia.",
        INVALID_AREA_CODE: "DDD inválido",
        INVALID_CATEGORY: "Categoria inválida",
        INVALID_CELLPHONE: "Celular inválido",
        INVALID_CITY:
          "Cidade inválida. Por favor, informe uma cidade presente na lista.",
        INVALID_COUNTERPART: "Contrapartida inválida",
        INVALID_COURSE:
          "Curso inválido. Por favor, informe um curso presente na lista.",
        INVALID_COURSE_QUANTITY:
          "Digite um valor válido para a quantidade de graduandos em cada curso",
        INVALID_CREDENTIALS: `Credenciais inválidas.<br /><br />Caso você seja um Estudante/Membro de OE, faça login através da nossa Plataforma Talents:<br />https://talents.empower.vision`,
        INVALID_EMAIL: "E-mail inválido",
        INVALID_FANPAGE:
          "Por favor, insira apenas a parte da URL que aparece após http://www.facebook.com/",
        INVALID_GRADUATION_DATE: "Data de formatura inválida",
        INVALID_INSTA:
          "Por favor, insira apenas a parte da URL que aparece após http://www.instagram.com/",
        INVALID_LINKEDIN:
          "Por favor, insira apenas a parte da URL que aparece após http://www.linkedin.com/company/",
        INVALID_FILE_NAME: "Nome da imagem inválido",
        INVALID_FIRST_KNOWLEDGE_AREA_DEMAND:
          "A área da demanda 1 de conhecimento é inválida",
        INVALID_FIRST_KNOWLEDGE_SUBAREA_DEMAND:
          "A sub-área da demanda 1 de conhecimento é inválida",
        INVALID_GUIDANCE: "Opção inválida para orientação",
        INVALID_MENTORING_MIN_PUBLIC: "Público mínimo para mentoria inválido",
        INVALID_MONTH: "Mês inválido",
        INVALID_NAME: "Nome do representante inválido.",
        INVALID_OPPORTUNITY: "Oportunidade inválida",
        INVALID_RECAPTCHA_TOKEN: "Ops, parece que o Recaptcha nào foi carregado corretamente. Por favor, tente recarregar a tela.",
        INVALID_REQUEST: "Requisição inválida",
        INVALID_REQUEST_IS_ONLINE_FIELD:
          "Informe se a solicitação é para uma ação online ou presencial",
        INVALID_REQUEST_MIN_PUBLIC:
          "Não é permitido um valor menor que 1 no público estimado",
        INVALID_SECOND_KNOWLEDGE_AREA_DEMAND:
          "A área da demanda 2 de conhecimento é inválida",
        INVALID_SECOND_KNOWLEDGE_SUBAREA_DEMAND:
          "A sub-área da demanda 2 de conhecimento é inválida",
        INVALID_SPECIALIZATION: "Opção inválida para 'Leciona para'",
        INVALID_SPONSORSHIP_VALUE: "Valor de patrocínio inválido",
        INVALID_STATE:
          "Estado inválido. Por favor, informe uma UF presente na lista.",
        INVALID_STUDY_PERIOD: "Período de estudos inválido",
        INVALID_THIRD_KNOWLEDGE_AREA_DEMAND:
          "A área da demanda 3 de conhecimento é inválida",
        INVALID_THIRD_KNOWLEDGE_SUBAREA_DEMAND:
          "A sub-área da demanda 3 de conhecimento é inválida",
        INVALID_TREATMENT_PRONOUN: "Pronome de tratamento inválido",
        INVALID_TOKEN:
          "Token inválido ou expirado. Por favor, tente refazer o processo de recuperação de senha. Em caso de dúvida, mande um e-mail para empower@empower.vision.",
        INVALID_UNIVERSITY:
          "Universidade inválida. Por favor, informe uma universidade presente na lista.",
        INVALID_YEAR: "Ano inválido",
        LECTURE_NOT_AVAIABLE:
          "Palestra não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        LESSON_NOT_AVAIABLE:
          "Aula não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        MENTORING_NOT_AVAIABLE:
          "Mentoria não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        MISSING_BEGIN_DATE: "Insira a data de início",
        MISSING_END_DATE: "Insira a data de término",
        MISSING_EVENT_TYPE: "Informe o tipo de ação que deseja",
        MISSING_EVENT_NAME_PROJECT: "Informe o nome do evento / projeto",
        MISSING_EVENT_DESCRIPTION: "Adicione uma descrição do evento",
        MISSING_FIRST_DATE:
          "Escolha ao menos a primeira opção de data para o evento",
        MISSING_FIRST_DATE_END_TIME:
          "Escolha um horario de fim para a primeira data",
        MISSING_FIRST_DATE_START_TIME:
          "Escolha um horario de início para a primeira data",
        MISSING_MENTORING_DESCRIPTION:
          "É preciso incluir uma descrição para a mentoria",
        MISSING_MENTORING_QUESTION:
          "É preciso indicar as 3 principais questões a serem respondidas da mentoria (min. 20 caracteres cada resposta)",
        MISSING_MENTORING_THEME: "Insira um tema para a mentoria",
        MISSING_LECTURE_THEME: "Insira pelo menos um tema para a palestra",
        MISSING_LESSON_THEME: "Insira pelo menos um tema para a aula",
        MISSING_OTHER_UNIVERSITY:
          "Especifique qual outra universidade você faz parte",
        MISSING_PLANT: "É preciso escolher uma planta",
        MISSING_PROJECT_NAME: "Insira o nome do projeto",
        MISSING_PROJECT_THEME: "Insira pelo menos um tema para o projeto",
        MISSING_PROJECT_TYPE: "Insira a categoria da parceria",
        MISSING_RESEARCH_THEME: "Insira pelo menos um tema para a pesquisa",
        MISSING_SECOND_DATE_END_TIME:
          "Escolha um horario de fim para a segunda data",
        MISSING_SECOND_DATE_START_TIME:
          "Escolha um horario de início para a segunda data",
        MISSING_THIRD_DATE_END_TIME:
          "Escolha um horario de fim para a terceira data",
        MISSING_THIRD_DATE_START_TIME:
          "Escolha um horario de início para a terceira data",
        MISSING_TRAINING_THEME: "Insira pelo menos um tema para o treinamento",
        MISSING_WEBINAR_THEME: "Insira pelo menos um tema para o webinar",
        MUST_ACCEPT_TERMS_AND_CONDITIONS:
          "É preciso ler e aceitar a nossa Política de Privacidade e os Termos e Condições de Uso da plataforma",
        MUST_INFORM_EMPOWER_DISCOVERY:
          "Por favor, informe como ficou sabendo da Empower",
        NAME_CANNOT_BE_EMPTY: "O nome não pode ser vazio",
        OPPORTUNITY_NOT_ACTIVE: "Esta oportunidade não está ativa.",
        OPPORTUNITY_NOT_FOUND:
          "Oportunidade não encontrada, por favor, entre em contato conosco.",
        OTHER_ACTIONS_NOT_AVAIABLE:
          "Esta empresa não aceita outras ações. Por favor, entre em contato com a Empower.",
        PASSWORD_MUST_HAVE_AT_LEAST_5_CHARACTERS:
          "Por favor, escolha uma senha com, no mínimo, 5 caracteres",
        PLANT_NOT_AVAIABLE:
          "Esta planta não está disponível, por favor, entre em contato conosco.",
        PLEASE_REGISTER_BEFORE_LOGIN:
          "Por favor, crie uma conta com este e-mail antes de fazer login.",
        PROJECT_DESCRIPTION_TOO_SHORT: "Adicione uma descrição para o projeto",
        PROJECT_NOT_AVAIABLE:
          "Projeto não está disponível, por favor, entre em contato conosco.",
        REQUEST_NOT_FOUND: "Solicitação não encontrada",
        REQUEST_TYPE_MUST_BE_LECTURE:
          "É preciso que o tipo de solicitação seja 'Palestra'",
        REQUEST_TYPE_MUST_BE_MENTORING:
          "É preciso que o tipo de solicitação seja 'Mentoria'",
        REQUEST_TYPE_MUST_BE_OTHER:
          "É preciso que o tipo de solicitação seja 'Outra'",
        REQUEST_TYPE_MUST_BE_PROJECT:
          "É preciso que o tipo de solicitação seja 'Projeto'",
        REQUEST_TYPE_MUST_BE_SPONSORSHIP:
          "É preciso que o tipo de solicitação seja 'Patrocínio'",
        REQUEST_TYPE_MUST_BE_TRAINING:
          "É preciso que o tipo de solicitação seja 'Treinamento'",
        REQUEST_TYPE_MUST_BE_VISIT:
          "É preciso que o tipo de solicitação seja 'Visita'",
        REQUEST_TYPE_MUST_BE_WEBINAR:
          "É preciso que o tipo de solicitação seja 'Webinar'",
        RESEARCH_NOT_AVAIABLE:
          "Pesquisa não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        SECOND_DATE_END_TIME_CANNOT_BE_BEFORE_START_TIME:
          "O horário de fim não pode ser menor que o de início (data 2)",
        SECOND_DATE_MUST_BE_AFTER_TODAY:
          "A data precisa ser maior que hoje (data 2)",
        SECOND_DATE_OPTION_TOO_SOON:
          "A segunda opção de data é anterior à antecedência mínima estabelecida nos pré-requisitos.",
        SECOND_DATE_OPTION_AFTER_LIMIT_DATE:
          "A segunda opção de data é posterior à data limite.",
        SECOND_KNOWLEDGE_DEMAND_CANNOT_BE_EMPTY:
          "A 2a demanda (área / subárea) de conhecimento não pode ser vazia.",
        SEEMS_A_BOT:
          "Ops, o Google Recaptcha acha que você é um bot. Por favor, preencha novamente as informações do formulário digitando-as, sem colá-las.",
        SPONSORSHIP_NOT_AVAIABLE:
          "Patrocínio não está disponível, por favor, entre em contato conosco.",
        STATE_NOT_AVAIABLE_FOR_LESSON:
          "Sua Universidade não está em um dos estados na qual essa empresa mentora oferece aulas. Em caso de dúvida, por favor, entre em contato conosco.",
        STATE_NOT_AVAIABLE_FOR_RESEARCH:
          "Sua Universidade não está em um dos estados na qual essa empresa mentora oferece pesquisa. Em caso de dúvida, por favor, entre em contato conosco.",
        SURNAME_CANNOT_BE_EMPTY: "O sobrenome não pode ser vazio",
        TRAINING_NOT_AVAIABLE:
          "Treinamento não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        THEMES_DESCRIPTION_TOO_SHORT: "Descreva as sugestões de temas com",
        THIRD_DATE_END_TIME_CANNOT_BE_BEFORE_START_TIME:
          "O horário de fim não pode ser menor que o de início (data 3)",
        THIRD_DATE_MUST_BE_AFTER_TODAY:
          "A data precisa ser maior que hoje (data 3)",
        THIRD_DATE_OPTION_TOO_SOON:
          "A terceira opção de data é anterior à antecedência mínima estabelecida nos pré-requisitos.",
        THIRD_DATE_OPTION_AFTER_LIMIT_DATE:
          "A terceira opção de data é posterior à data limite.",
        THIRD_KNOWLEDGE_DEMAND_CANNOT_BE_EMPTY:
          "A 3a demanda (área / subárea) de conhecimento não pode ser vazia.",
        TOO_FEW_ATTENDEES:
          "Quantidade de participantes inferior ao mínimo estabelecido nos pré-requisitos.",
        TOO_MANY_LOGIN_ATTEMPTS:
          "Você atingiu o limite de 5 tentativas malsucedidas de login a cada 5 minutos. Por segurança, aguarde 5 minutos para tentar novamente ou redefina sua senha.",
        TOO_MUCH_ATTENDEES:
          "Quantidade de participantes superior ao máximo estabelecido nos pré-requisitos.",
        UNEXPECTED_ERROR: "Por favor, tente novamente mais tarde..",
        USER_MUST_ACCEPT_TERMS:
          "Você deve aceitar os termos de contrapartida ao fazer a solicitação",
        USER_NOT_FOUND: "Este e-mail não está cadastrado em nossa base.",
        VISIT_NOT_AVAIABLE:
          "Visita não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        WEBINAR_NOT_AVAIABLE:
          "Webinar não está disponível para essa oportunidade, por favor, entre em contato conosco.",
        WRONG_PASSWORD: "Senha atual incorreta!"
      };
      return translations[message] || "Por favor, tente novamente mais tarde..";
    }
  }
};

export { commom };
