<template>
  <form @submit.prevent="login">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <h1
      class="is-size-3 has-text-weight-bold"
      :class="{ 'has-text-white': !isDesktop }"
    >
      Entrar
    </h1>

    <p :class="{ 'has-text-white': !isDesktop }">
      Acessar com:
    </p>
    <br />

    <div class="columns">
      <div class="column">
        <b-button type="is-primary" id="email-button" expanded>
          E-mail
        </b-button>
      </div>

      <div class="column has-text-centered">
        <g-signin-button
          :params="googleSignInParams"
          @success="onSignInSuccess"
          @error="onSignInError"
        >
          <img
            src="../../assets/img/g-logo.png"
            alt="Google Logo"
            id="g-logo"
          />
          <span id="google-button-text">Google</span>
        </g-signin-button>
      </div>
    </div>

    <b-field
      :label="email && isDesktop ? 'E-mail institucional' : ''"
      label-position="on-border"
    >
      <b-input
        size="is-medium"
        v-model="email"
        class="is-expanded"
        type="email"
        placeholder="E-mail institucional"
        validation-message="Informe um e-mail válido."
      ></b-input>
    </b-field>

    <b-field
      :label="password && isDesktop ? 'Senha' : ''"
      label-position="on-border"
    >
      <b-input
        size="is-medium"
        v-model="password"
        class="is-expanded"
        type="password"
        placeholder="Senha"
        password-reveal
      ></b-input>
    </b-field>

    <br />

    <button
      class="button is-primary is-medium is-fullwidth has-text-weight-bold"
    >
      Entrar
    </button>

    <br />
    <h3 class="is-size-6">
      <span
        class="create-account-large"
        :class="{ 'has-text-white': !isDesktop }"
      >
        Não tem conta?

        <router-link
          to="signup"
          class="has-text-primary has-text-weight-semibold"
        >
          Clique aqui
        </router-link>
      </span>

      <span
        class="create-account-small"
        :class="{ 'has-text-white': !isDesktop }"
      >
        <router-link
          to="signup"
          class="has-text-primary has-text-weight-semibold"
        >
          Criar conta
        </router-link>
      </span>

      <router-link
        to="forgotpassword"
        class="is-pulled-right"
        :class="{ 'has-text-white': !isDesktop, 'text-underline': !isDesktop }"
      >
        Esqueci minha senha
      </router-link>
    </h3>
    <br />
    <br />
    <b-modal :active="emailValidateModal" :width="430" :can-cancel="false">
      <div class="modalTitle">
        Erro - Confirmação de E-mail
      </div>
      <div class="modalBody">
        <div class="modalIcon">
          <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
        </div>
        <div class="modalMessage">
          <div class="modalText">
            Antes de fazer login, é preciso validar seu e-mail clicando no link
            que lhe enviamos.
          </div>
          <div class="modalButtons">
            <b-button class="buttonOk" @click="emailValidateModal = false">
              ok
            </b-button>

            <b-button class="buttonLink" @click="resendEmail">
              Reenviar Link
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </form>
</template>

<script>
import { commom } from "../../mixins/commom.js";
import { responsive } from "../../mixins/responsive";

export default {
  mixins: [commom, responsive],

  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      googleSignInParams: {
        client_id:
          "775299412180-ppi59j8ie7s4ul8k1rfb3oqrufs4403h.apps.googleusercontent.com"
      },

      label: {
        email: "",
        password: ""
      },
      status: null,
      errors: {},
      emailValidateModal: false
    };
  },

  methods: {
    onSignInSuccess(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      this.isLoading = true;
      const id_token = googleUser.getAuthResponse().id_token;
      this.$store
        .dispatch("loginWithGoogle", { id_token })
        .then(res => {
          if (res.data.result === "ok") {
            this.$router.push("opportunities");
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.$recaptchaInstance.hideBadge();
          this.isLoading = false;
        });
    },

    onSignInError(error) {
      // `error` contains any error occurred.
      console.log('OH NOES', error);
      this.alertError({ message: "COULD_NOT_SIGN_IN_WITH_GOOGLE" });
    },

    async login() {
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");

      this.isLoading = true;
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
          token
        })
        .then(res => {
          if (res.data.result === "ok") {
            this.$router.push("opportunities");
          } else if (res.data.message === "EMAIL_NOT_VALIDATED") {
            this.emailValidateModal = true;
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.$recaptchaInstance.hideBadge();
          this.isLoading = false;
        });
    },

    resendEmail() { //Reenvia o link de validação de email
      this.isLoading = true;
      this.$store
        .dispatch("resendEmailValidationLink", { email: this.email })
        .then(res => {
          if (res.data.result === "ok") {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Link reenviado",
              type: "is-primary",
              position: "is-bottom"
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "Erro ao reenviar link",
            type: "is-danger",
            position: "is-bottom"
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.emailValidateModal = false;
        });
    }
  }
};
</script>

<style scoped>
form {
  width: 60%;
  margin: auto;
  margin-top: 5vh;
}
.create-account-small {
  display: none;
}
@media (min-width: 1023px) {
  form {
    width: 80%;
    margin-top: 15vh;
  }
}
@media (max-width: 600px) {
  form {
    width: 70%;
  }
}
@media (max-width: 540px) {
  form {
    width: 90%;
  }
  .create-account-large {
    display: none;
  }
  .create-account-small {
    display: inline-block;
  }
}
.text-underline {
  text-decoration: underline;
  text-underline-position: above;
}
#g-logo {
  width: 20px;
  position: relative;
  top: 3px;
  left: -3px;
}
#email-button,
.g-signin-button {
  border-radius: 18px;
}
.g-signin-button {
  background-color: white;
  width: 100%;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
#google-button-text {
  position: relative;
  right: -3px;
  bottom: 1px;
}

/* Estilização do Modal de Validação de Email */

.modalTitle{
  background-color: #e9e9e9;
  border: solid .2px #c9c7c7;
  color: #000;
  font-weight: bold;
  padding-top: .3rem;
  padding-bottom: .3rem;
  padding-left: 1.5rem;
}

.modalBody{
  height: 9.5rem;
  background-color: #fff;
  display: flex;
}

.modalIcon{
  width: 10rem;
  height: 100%;
  display: flex;
}

.modalIcon .icon{
  margin: auto;
}

.modalText{
  padding: 1rem;
  padding-left: .5rem;
  padding-right: 1.5rem;
  color: #000;
}

.modalButtons{
  height: 4.5rem;
  display: flex;
  padding-right: 2rem;
}

.modalButtons .button{
  width: 9rem;
  margin: auto;
}

.buttonOk{
  border: solid .2px #000;
}

.buttonLink{
  background-color: #02f8c7;
}
</style>
