<template>
  <div
    class="card"
    :class="{ 'opportunity-hover': opportunity.status === 'active' }"
    v-on="
      opportunity.status === 'active' ? { click: visitOpportunityPage } : null
    "
    style="margin-bottom: 2rem;"
  >
    <div class="card-cover">
      <figure v-if="opportunity.page.resizedCoverImageURL">
        <img :src="opportunity.page.resizedCoverImageURL" />
      </figure>

      <figure v-else>
        <img :src="opportunity.page.coverImageURL" />
      </figure>
    </div>

    <div class="card-image">
      <div
        :class="opportunity.spotlight"
        class="has-text-centered has-text-white has-text-weight-bold"
      >
        {{
          opportunity.spotlight === "new"
            ? "Novo!"
            : opportunity.spotlight === "lastUnits"
            ? "Acabando!"
            : ""
        }}
      </div>

      <figure :class="{ 'image-gray': opportunity.status !== 'active' }">
        <img :src="opportunity.page.businessLogoURL" />
      </figure>
    </div>

    <h2 class="has-text-centered is-size-5 has-text-weight-bold">
      {{ opportunity.businessName }}
    </h2>

    <br />
    <hr />

    <div class="card-content">
      <div v-if="opportunity.status === 'active'" class="columns is-mobile">
        <card-lesson-icon :lessonIsActive="opportunity.lesson.isActive" />

        <card-research-icon :researchIsActive="opportunity.research.isActive" />

        <card-visit-icon :visitProfIsActive="visitProfIsActive" />

        <card-other-icon :otherIsActive="opportunity.otherProf.isActive" />
      </div>

      <div v-else-if="opportunity.status == 'soldout'" class="columns">
        <div class="column has-text-centered">
          <b-icon icon="exclamation-circle"></b-icon>

          <br />
          Esgotado
        </div>

        <div class="column has-text-centered">
          <a :href="opportunity.page.logosZipURL" target="_blank">
            <button class="button">Baixar Logos</button>
          </a>
        </div>
      </div>

      <div v-else class="columns">
        <div class="column has-text-centered">
          <b-icon icon="hourglass-half"></b-icon>

          <br />
          Em Breve
        </div>

        <div class="column has-text-centered">
          <a :href="opportunity.page.logosZipURL" target="_blank">
            <button class="button">Baixar Logos</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardLessonIcon from "./card-icons/CardLessonIcon.vue";
import cardResearchIcon from "./card-icons/CardResearchIcon.vue";
import cardVisitIcon from "./card-icons/CardVisitIcon";
import cardOtherIcon from "./card-icons/CardOtherIcon";

export default {
  props: ["opportunity"],

  components: {
    cardLessonIcon,
    cardResearchIcon,
    cardVisitIcon,
    cardOtherIcon
  },

  methods: {
    visitOpportunityPage() {
      if (this.opportunity.status === "active") {
        this.$store.dispatch("setOpportunityBeingVisited", {
          opportunity: JSON.parse(JSON.stringify(this.opportunity))
        });
        this.$router.push("/opportunity-page");
      }
    }
  },

  computed: {
    visitProfIsActive() {
      return this.opportunity.plantsProf.some(p => p.visitIsActive);
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: -3.5rem;
}
.opportunity-hover:hover {
  box-shadow: 3px 3px 10px #333333;
  cursor: pointer;
}
hr {
  margin: 0;
}
.opportunity-item {
  padding-right: 0;
  padding-left: 0;
}
.image-gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.card-image {
  position: relative;
  height: fit-content;
}
.card-image img {
  position: relative;
  display: block;
  width: 70% !important;
  height: 70% !important;
  margin-left: auto;
  margin-right: auto;
}
.new {
  position: absolute;
  right: 0;
  height: 0;
  top: -10px;
  width: 100%;
  margin: 0 0 10px 0;
  border-bottom: 40px solid rgb(0, 175, 86);
  z-index: 10;
  font-size: 24px;
}
.lastUnits {
  position: absolute;
  right: 0;
  height: 0;
  top: -10px;
  width: 100%;
  margin: 0 0 10px 0;
  border-bottom: 40px solid red;
  z-index: 10;
  font-size: 24px;
}
.card-cover img {
  height: 80px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 768px){
  .card{
    min-width: 19rem;
  }
}
</style>
