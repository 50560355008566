<template>
  <section>
    <p><b>Empower</b></p>

    <a
      href="https://empower.vision/#AcoesDeDesenvolvimento"
      target="_blank"
      class="navbar-item"
    >
      <img
        src="../../assets/img/logos-empower/simbolo-branco.png"
        class="logoIcon"
      />
      A Empower
    </a>

    <router-link to="/opportunities" class="navbar-item">
      <b-icon icon="rocket" size="is-medium"></b-icon>
      Ações de Desenvolvimento
    </router-link>

    <a
      href="https://empower.vision/#PerguntasFrequentes"
      target="_blank"
      class="navbar-item"
    >
      <b-icon icon="question-circle" size="is-medium"></b-icon>
      Perguntas Frequentes
    </a>

    <a
      href="https://www.facebook.com/pg/empower.oficial/events/"
      target="_blank"
      class="navbar-item"
    >
      <b-icon icon="calendar-alt" size="is-medium"></b-icon>
      Próximos Eventos
    </a>

    <a
      href="https://empower.vision/inovacao"
      target="_blank"
      class="navbar-item"
    >
      <b-icon icon="lightbulb" size="is-medium"></b-icon>
      Parcerias para Projetos
    </a>

    <b-navbar-item :active="isActive" @click.prevent="changeCollapse">
      <b-icon icon="link" size="is-medium"></b-icon>
      Redes Sociais Empower
    </b-navbar-item>

    <b-navbar-item
      href="https://www.facebook.com/empower.oficial"
      target="_blank"
      v-if="isActive"
      class="item-collapse"
    >
      Facebook
    </b-navbar-item>

    <b-navbar-item
      href="https://www.instagram.com/empower.oficial/"
      target="_blank"
      v-if="isActive"
      class="item-collapse"
    >
      Instagram
    </b-navbar-item>

    <b-navbar-item
      href="https://br.linkedin.com/company/empower-oficial"
      target="_blank"
      v-if="isActive"
      class="item-collapse"
    >
      LinkedIn
    </b-navbar-item>

    <p><b>Área do Professor</b></p>

    <router-link
      to="/userprofile"
      class="navbar-item"
    >
      <b-icon icon="signal" size="is-medium"></b-icon>
      Meu Perfil
    </router-link>

    <router-link
      to="/requests"
      class="navbar-item"
    >
      <b-icon icon="tasks" size="is-medium"></b-icon>
      Gerenciar Solicitações
    </router-link>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HamburgerMenu",
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    ...mapGetters(["account"])
  },
  methods: {
    changeCollapse() {
      //Abrir o menu suspenso de redes sociais
      this.isActive = !this.isActive;
    }
  }
};
</script>

<style scoped>
.navbar-item {
  color: white;
}
.logoIcon {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1.8rem !important;
  height: 1rem;
}

.icon {
  color: white;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.item-collapse {
  padding-left: 5rem !important;
}
p {
  color: white;
  background-color: rgb(90, 90, 90);
  padding-left: 2.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
