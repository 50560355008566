<template>
  <div class="box">
    <b-loading
      v-if="isLoading"
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <h2 class="has-text-weight-bold">Dados Pessoais</h2>
    <br />

    <div class="columns">
      <!-- Foto do Perfil -->
      <div class="column is-narrow">
        <div
          class="logo-upload-div"
          :style="{ 'background-image': `url(${previewImage})` }"
          @click="$refs.fileInput.click()"
        >
          <b-icon
            icon="camera-retro"
            size="is-large"
            style="position: relative; left: 23px; top: 12px; color: #999"
            v-if="!previewImage"
          ></b-icon>

          <p
            class="has-text-centered"
            style="font-size: 0.8rem; position: relative; top: 6px; color: #999"
            v-if="!previewImage"
          >
            Foto PNG
          </p>
        </div>

        <p
          class="has-text-centered delete-professor-image"
          @click="deleteProfessorImage"
        >
          <u>Excluir foto</u>
        </p>

        <input
          ref="fileInput"
          type="file"
          @input="pickFile"
          style="display: none"
          accept="image/png"
        />
      </div>

      <div class="columns userName">
        <b-field
          style="width: 100%"
          :label="account.name ? 'Nome' : ''"
          label-position="on-border"
        >
          <b-input v-model="account.name" placeholder="Nome" expanded></b-input>
        </b-field>

        <b-field
          style="width: 100%"
          :label="account.surname ? 'Sobrenome' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="account.surname"
            placeholder="Sobrenome"
            expanded
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns userContact">
      <b-field
        style="width: 100%; margin-left: 0.5rem"
        label="E-mail"
        label-position="on-border"
      >
        <b-input
          v-model="account.originalEmail"
          placeholder="E-mail"
          expanded
          disabled
        >
        </b-input>
      </b-field>

      <b-field
        style="width: 100%; margin-left: 0.5rem; margin-right: 1rem"
        :label="account.cellphone ? 'Celular' : ''"
        label-position="on-border"
      >
        <b-select placeholder="DDD" v-model="account.areacode">
          <option v-for="(ddd, i) in ddds" :value="ddd" :key="i">
            {{ ddd }}
          </option>
        </b-select>

        <b-input
          placeholder="Celular"
          expanded
          v-model="account.cellphone"
        ></b-input>
      </b-field>
    </div>

    <b-field
      :label="account.lattes ? 'Plataforma Lattes - Link' : ''"
      label-position="on-border"
    >
      <b-input
        v-model="account.lattes"
        placeholder="Plataforma Lattes - Link"
        expanded
      />
    </b-field>

    <b-field
      :label="account.linkedin ? 'LinkedIn - Link' : ''"
      label-position="on-border"
    >
      <b-input
        v-model="account.linkedin"
        placeholder="LinkedIn - Link"
        expanded
      />
    </b-field>

    <h2 class="has-text-weight-bold">Universidade</h2>
    <span>Sobre a universidade em que leciona</span>

    <div class="columns" style="margin-top: 1rem">
      <b-field
        :label="account.state ? 'UF' : ''"
        label-position="on-border"
        class="userUF"
      >
        <b-autocomplete
          v-model="account.state"
          :data="filteredStatesArray"
          :open-on-focus="true"
          :keep-first="true"
          placeholder="UF"
          @select="option => (selected = option)"
        >
          <template slot="empty"> Sem resultados </template>
        </b-autocomplete>
      </b-field>

      <b-field
        :label="account.city ? 'Cidade' : ''"
        label-position="on-border"
        style="width: 100%; margin-right: 1rem"
      >
        <b-autocomplete
          v-model="account.city"
          :data="filteredCitiesArray"
          :open-on-focus="true"
          :keep-first="true"
          placeholder="Cidade"
          @select="(option) => (selected = option)"
          :disabled="!isValidState"
        >
          <template slot="empty"> Sem resultados </template>
        </b-autocomplete>
      </b-field>
    </div>

    <div class="columns">
      <b-field
        :label="account.university ? 'Universidade' : ''"
        label-position="on-border"
        class="userUniversity"
      >
        <b-autocomplete
          v-model="account.university"
          :data="filteredUniversitiesArray"
          :open-on-focus="true"
          :keep-first="true"
          placeholder="Universidade"
          @select="(option) => (selected = option)"
          :disabled="!isValidState"
        >
          <template slot="empty"> Sem resultados </template>
        </b-autocomplete>
      </b-field>
    </div>

    <span>Leciona para:</span>
    <br /><br />

    <div>
      <b-field>
        <b-checkbox
          v-model="account.specialization"
          native-value="technologist"
        >
          Tecnólogo (nível superior)
        </b-checkbox>
      </b-field>

      <b-field v-if="account.specialization.indexOf('technologist') > -1">
        <b-taginput placeholder="Cursos" v-model="technologistGrade.courses" />
      </b-field>

      <b-field v-if="account.specialization.indexOf('technologist') > -1">
        <b-taginput
          placeholder="Disciplinas"
          v-model="technologistGrade.subjects"
        />
      </b-field>

      <b-field>
        <b-checkbox v-model="account.specialization" native-value="graduation">
          Graduação
        </b-checkbox>
      </b-field>

      <b-field v-if="account.specialization.indexOf('graduation') > -1">
        <b-taginput placeholder="Cursos" v-model="graduationGrade.courses" />
      </b-field>

      <b-field v-if="account.specialization.indexOf('graduation') > -1">
        <b-taginput
          placeholder="Disciplinas"
          v-model="graduationGrade.subjects"
        />
      </b-field>

      <b-field>
        <b-checkbox
          v-model="account.specialization"
          native-value="postGraduation"
        >
          Pós-Graduação
        </b-checkbox>

        <span @click="postGraduationInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>
    </div>

    <br />

    <span>Orienta:</span>
    <br />

    <div class="column">
      <b-field>
        <b-checkbox native-value="tcc" v-model="account.guidance">
          TCC
        </b-checkbox>

        <span @click="tccInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>

      <b-field>
        <b-checkbox
          native-value="scientificResearch"
          v-model="account.guidance"
        >
          Iniciação Científica
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="mastersDegree" v-model="account.guidance">
          Mestrado
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="doctorateDegree" v-model="account.guidance">
          Doutorado
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="organization" v-model="account.guidance">
          Org. Estudantis / Extensão
        </b-checkbox>

        <span @click="organizationInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>
    </div>

    <p style="margin-top: 1rem; margin-bottom: 0.5rem">
      Leciona em outra universidade?
    </p>

    <div class="columns">
      <div class="column">
        <b-field>
          <b-radio v-model="teacherAtAnotherUniversity" native-value="true">
            Sim
          </b-radio>

          <b-radio v-model="teacherAtAnotherUniversity" native-value="false">
            Não
          </b-radio>
        </b-field>
      </div>
    </div>

    <div class="columns" v-if="teacherAtAnotherUniversity === 'true'">
      <div class="column">
        <b-field
          :label="account.anotherUniversity ? 'Universidade' : ''"
          label-position="on-border"
          class="userUniversity"
        >
          <b-autocomplete
            v-model="account.anotherUniversity"
            :data="filteredAnotherUniversitiesArray"
            :open-on-focus="true"
            :keep-first="true"
            placeholder="Universidade"
            @select="(option) => (selected = option)"
            :disabled="!isValidState"
          >
            <template slot="empty"> Sem resultados </template>
          </b-autocomplete>
        </b-field>
      </div>

      <div class="column">
        <b-field
          :label="account.anotherUniversityEmail ? 'E-mail Institucional' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="account.anotherUniversityEmail"
            placeholder="E-mail Institucional"
          />
        </b-field>
      </div>
    </div>

    <hr />
    <!-- <Pendencies :pendencies="pendencies" /> -->

    <div class="columns">
      <div class="column"></div>

      <div class="column is-narrow">
        <b-button
          class="button is-danger is-rounded is-pulled-left"
          @click="confirmAccountDeletion"
          outlined
        >
          Excluir conta
        </b-button>

        <span class="is-pulled-left">
          &nbsp;&nbsp;&nbsp;
        </span>

        <button
          class="button is-primary is-rounded is-pulled-left"
          @click="updateAccount"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { commom } from "../../../mixins/commom.js";
import { responsive } from "../../../mixins/responsive";
import { estados } from "../../../assets/js/estados";
import { cidades_por_estado } from "../../../assets/js/cidades-por-estado";
import { universidades_por_estado } from "../../../assets/js/universidades-por-estado";
import { ddds } from "../../../assets/js/ddds";
// import Pendencies from "../../commom/Pendencies"

export default {
  name: "FormGeneralInfo",

  props: ["account"],

  mixins: [commom, responsive],

  // components: {
  //   Pendencies
  // },

  data() {
    return {
      isLoading: false,
      previewImage: null,
      statesList: [],
      citiesList: {},
      universitiesList: {},
      ddds: [],
      technologistGrade: {
        courses: [],
        subjects: []
      },
      graduationGrade: {
        courses: [],
        subjects: []
      },
      teacherAtAnotherUniversity: null,
      file: null
    };
  },

  methods: {
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.file = file[0];
      }
    },

    setGraduationDate() {
      if (this.account.graduationDate) {
        this.graduationMonth = this.account.graduationDate.split("/")[0];
        this.graduationYear = this.account.graduationDate.split("/")[1];
      }
    },

    updateAccount() {
      this.isLoading = true;
      if (this.account.specialization.indexOf("technologist") > -1) {
        this.account.technologistGrade = this.technologistGrade;
      } else {
        this.account.technologistGrade = null;
      }

      if (this.account.specialization.indexOf("graduation") > -1) {
        this.account.graduationGrade = this.graduationGrade;
      } else {
        this.account.technologistGrade = null;
      }

      if (this.teacherAtAnotherUniversity == "false") {
        this.account.anotherUniversity = "";
        this.account.anotherUniversityEmail = "";
      }

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("account", JSON.stringify(this.account));

      this.$store
        .dispatch("updateAccount", formData)
        .then(res => {
          if (res.data.result === "ok") {
            this.$buefy.dialog.alert({
              title: "Sucesso!",
              message: "As informações do seu perfil foram atualizadas! 😉",
              type: "is-success",
              hasIcon: true,
              icon: "check",
              iconPack: "fa",
              onConfirm: () => this.$parent.close(),
              onCancel: () => this.$parent.close()
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    confirmAccountDeletion() {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja excluir sua conta?",
        type: "is-danger",
        cancelText: "Cancelar",
        confirmText: "Sim, excluir",
        onConfirm: () => this.deleteAccount()
      });
    },

    deleteAccount() {
      this.isLoading = true;
      this.$store
        .dispatch("deleteAccount")
        .then(res => {
          if (res.data.result === "ok") {
            this.$buefy.dialog.alert({
              title: "Sucesso!",
              message:
                "Sua conta foi deletada. Você será redirecionado para a página inicial.",
              type: "is-success",
              hasIcon: true,
              icon: "check",
              iconPack: "fa",
              onConfirm: () => {
                this.$store.dispatch("logout");
                this.$router.replace("/");
              }
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    postGraduationInfo() {
      this.$buefy.dialog.alert({
        title: "Pós-Graduação",
        message: " • Mestrado <br> • Doutorado <br> • MBA",
        confirmText: "OK",
      });
    },

    tccInfo() {
      this.$buefy.dialog.alert({
        title: "TCC",
        message:
          " • TCC - Trabalho de Conclusão de Curso <br> • TFG - Trabalho de Finalização de Graduação <br> • TG - Trabalho de Graduação",
        confirmText: "OK",
      });
    },

    organizationInfo() {
      this.$buefy.dialog.alert({
        title: "Org. Estudantil / Extensão",
        message:
          " • Empresa Júnior <br> • Equipes de Competição <br> • Centros Acadêmicos <br> • etc.",
        confirmText: "OK",
      });
    },

    deleteProfessorImage() {
      if (!this.previewImage) {
        this.$buefy.toast.open({
          message: "Não existe imagem",
          type: "is-warning"
        });
        return false;
      }
      this.isLoading = true;
      this.$store
        .dispatch("deleteProfessorImage")
        .then(res => {
          if (res.data.result === "ok") {
            this.previewImage = null;
            this.$buefy.toast.open({
              message: "Imagem deletada",
              type: "is-success"
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    filteredStatesArray() {
      return this.statesList.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.account.state.toLowerCase()) >= 0
        );
      });
    },

    isValidState() {
      return this.statesList.includes(this.account.state);
    },

    filteredCitiesArray() {
      let filteredCitiesArray = [];
      if (this.isValidState) {
        filteredCitiesArray = this.citiesList[this.account.state].filter(
          option => {
            return (
              option
                .toString()
                .toLowerCase()
                .indexOf(this.account.city.toLowerCase()) >= 0
            );
          }
        );
      }
      return filteredCitiesArray;
    },

    filteredUniversitiesArray() {
      let filteredUniversitiesArray = [];
      if (this.isValidState) {
        filteredUniversitiesArray = this.universitiesList[
          this.account.state
        ].filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.account.university.toLowerCase()) >= 0
          );
        });
      }
      return filteredUniversitiesArray;
    },

    filteredAnotherUniversitiesArray() {
      let filteredUniversitiesArray = [];
      if (this.isValidState) {
        filteredUniversitiesArray = this.universitiesList[
          this.account.state
        ].filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.account.anotherUniversity.toLowerCase()) >= 0
          );
        });
      }
      return filteredUniversitiesArray;
    }
  },
  mounted() {
    this.previewImage = this.account.picture
      ? this.account.picture
      : this.account.googlePictureURL;
    this.statesList = estados;
    this.citiesList = cidades_por_estado;
    this.universitiesList = universidades_por_estado;
    this.ddds = ddds;
    if (this.account.anotherUniversity) {
      this.teacherAtAnotherUniversity = "true";
    }
    if (this.account.technologistGrade) {
      this.technologistGrade = this.account.technologistGrade;
    }
    if (this.account.graduationGrade) {
      this.graduationGrade = this.account.graduationGrade;
    }
  },
};
</script>

<style scoped>
.box {
  padding: 2rem 2rem;
  max-width: 800px !important;
  margin: auto;
}
.logo-upload-div {
  width: 96px;
  height: 96px;
  border: 1px solid #999;
  color: #999;
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.logoEditing {
  border: solid gray 0.5px;
  border-radius: 100%;
  background-color: white;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: auto;
  margin-bottom: -10rem;
}
.logoEditing .icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: gray;
}
.userName {
  width: 100%;
  margin: 1rem;
  display: block !important;
}
.userUF {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.userUniversity {
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 1rem;
}
.infoIcon {
  color: #209cee;
  cursor: pointer;
}

.delete-professor-image {
  font-size: 80%;
  color: red;
  cursor: pointer;
}

@media (max-width: 769px) {
  .logo-upload-div {
    margin-left: auto;
    margin-right: auto;
  }
  .userName {
    margin: 0;
  }
  .userUF {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
  }
  .userUniversity {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
  }

  .userContact {
    margin-left: -1.25rem;
    margin-top: 1.5rem;
    margin-right: -0.25rem;
  }
}

@media (max-width: 450px) {
  .box {
    padding: 2rem 1.5rem;
  }
}
</style>
