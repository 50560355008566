var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.opportunityBeingVisited.research.isActive)?_c('div',{staticClass:"container",attrs:{"id":"research-info"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column box",attrs:{"id":"research-box"}},[_c('research-title',{attrs:{"title":"Participação em Pesquisa","buttonText":"Solicitar","description":_vm.description,"isCardOpen":_vm.isCardOpen},on:{"open-card":function($event){return _vm.$emit('open-card', 'research')},"close-card":function($event){return _vm.$emit('close-card', 'research')}}}),(_vm.isCardOpen)?_c('div',{staticClass:"columns action-details"},[_c('themes',{attrs:{"themes":[
            'Benefícios para a Sociedade',
            'Desenvolvimento de Novas Tecnologias',
            'Desenvolvimento de Novos Materiais',
            'Desenvolvimento de Novos Produtos',
            'Desenvolvimento de Novos Serviços',
            'Iniciativas para Aumento nas Vendas',
            'Iniciativas para Redução de Custos',
            'Outros Temas de Interesse do(a) Professor(a)'
          ]}}),_c('prerequisites',{attrs:{"advance":_vm.opportunityBeingVisited.research.advance,"limitDateForActions":_vm.opportunityBeingVisited.limitDateForActions,"courses":_vm.opportunityBeingVisited.research.courses,"states":['Brasil Todo']}}),_c('counterparts',{attrs:{"treatmentPronoun":_vm.opportunityBeingVisited.treatmentPronoun,"businessName":_vm.opportunityBeingVisited.businessName,"logosZipURL":_vm.opportunityBeingVisited.page.logosZipURL,"action":"a ação"}})],1):_vm._e()],1)]),_c('b-modal',{attrs:{"active":_vm.isRequestModalActive},on:{"update:active":function($event){_vm.isRequestModalActive=$event}}},[_c('research-request-modal',{attrs:{"advance":_vm.opportunityBeingVisited.research.advance}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }