const request = {
  methods: {
    adjustRequestDateAndTime(date, time) {
      if (date && time){
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          time.getHours(),
          time.getMinutes()
        );
      }
      return null;
    },
    requestTypeText(type, capitalization = 0) {
      // 0 --> all lower case
      return capitalization === 0
        ? type === "visit"
          ? "visita"
          : type === "lesson"
          ? "participação em aula"
          : type === "research"
          ? "participação em pesquisa"
          : type === "other"
          ? "outra ação"
          : "-"
        : capitalization === 1 // 1 --> first upper case
        ? type === "visit"
          ? "Visita"
          : type === "lesson"
          ? "Participação em aula"
          : type === "research"
          ? "Participação em pesquisa"
          : type === "other"
          ? "Outra Ação"
          : "-"
        : // 2 --> all upper case
        type === "visit"
        ? "VISITA"
        : type === "lesson"
        ? "PARTICIPAÇÃO EM AULA"
        : type === "research"
        ? "PARTICIPAÇÃO EM PESQUISA"
        : type === "other"
        ? "OUTRA AÇÃO"
        : "-";
    },
    requestStatus(status) {
      return status === "newRequest"
        ? "Solicitação recebida"
        : status === "underEvaluation"
        ? "Aguardando resposta"
        : status === "confirmed"
        ? "Solicitação aceita"
        : status === "alignment"
        ? "Alinhamento com o gestor"
        : status === "preEvent"
        ? "Preparativos pré-evento"
        : status === "waitingCounterparts"
        ? "Enviar contrapartidas"
        : status === "buildingCase"
        ? "Construindo case"
        : status === "finishedWithCounterparts"
        ? "Ação finalizada - com contrapartidas"
        : status === "finishedWithoutCounterparts"
        ? "Ação finalizada - sem contrapartidas"
        : status === "noAvailability"
        ? "Sem disponibilidade empresa"
        : status === "canceledByProfessor"
        ? "Cancelado pelo professor"
        : "---";
    },
    requestTypeTreatmentPronoun(type, capitalization = 0) {
      return capitalization === 0 // lower case
        ? type === "webinar" ||
          type === "training" ||
          type === "sponsorship" ||
          type === "project"
          ? "o"
          : "a"
        : type === "webinar" ||
          type === "training" ||
          type === "sponsorship" ||
          type === "project"
        ? "O"
        : "A";
    },
    hasSpecificTime(requestType) {
      return !(
        requestType === "visit" ||
        requestType === "other" ||
        requestType === "sponsorship" ||
        requestType === "project"
      );
    }
  },

  computed: {
    isLesson() {
      return this.request.requestType === "lesson";
    },
    isResearch() {
      return this.request.requestType === "research";
    },
    isVisit() {
      return this.request.requestType === "visit";
    },
    isOther() {
      return this.request.requestType === "other";
    }
  }
};

export { request };
