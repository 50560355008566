<template>
  <section class="requestPanel">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <div class="requestPrincipal">
      <tip :info="info" />

      <div class="requestBody">
        <lateralMenu />

        <div class="requestContent">
          <requestOnGoing :requests="ongoingRequests" />
          <br />
          <completedRequests :requests="finishedRequests" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { commom } from "../../mixins/commom.js";
import tip from "../commom/DinamicTip";
import lateralMenu from "../lateral-menu/LateralMenu";
import requestOnGoing from "./requestList/requestOnGoing";
import completedRequests from "./requestList/CompletedRequests.vue";

export default {
  name: "RequestPanel",

  mixins: [commom],

  components: {
    tip,
    lateralMenu,
    requestOnGoing,
    completedRequests
  },

  data() {
    return {
      info: {
        infoBorder: true,
        buttonText: "DICA",
        descriptionText:
          "Mantenha o seu perfil sempre atualizado e aumente as chances de ser convidado a participar de Parcerias Exclusivas com as Empresas Mentoras."
      },
      isLoading: false
    };
  },

  computed: {
    ...mapGetters(["requestsOrderedNewerToOlder"]),

    ongoingRequests() {
      return this.requestsOrderedNewerToOlder.filter(r => {
        return (
          r.requestStatus === "newRequest" ||
          r.requestStatus === "underEvaluation" ||
          r.requestStatus === "confirmed" ||
          r.requestStatus === "alignment" ||
          r.requestStatus === "preEvent"
        );
      });
    },

    finishedRequests() {
      return this.requestsOrderedNewerToOlder.filter(r => {
        return (
          r.requestStatus === "waitingCounterparts" ||
          r.requestStatus === "buildingCase" ||
          r.requestStatus === "finishedWithCounterparts" ||
          r.requestStatus === "finishedWithoutCounterparts" ||
          r.requestStatus === "noAvailability" ||
          r.requestStatus === "canceledByOrganization"
        );
      });
    }
  },

  mounted() {
    this.isLoading = true;
    this.$store
      .dispatch("getRequests")
      .then(res => {
        if (res.data.result === "error") {
          this.alertError(res.data);
        }
      })
      .catch(() => {
        this.alertError({ message: "UNEXPECTED_ERROR" });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>

<style scoped>
.requestPanel {
  background-color: #e9e9e9;
  padding-top: 4rem;
}
.LateralMenu {
  background-color: #e9e9e9;
}
.requestPrincipal {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 90rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.requestBody {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
}
.requestContent {
  margin-top: 0.7rem;
}
@media (max-width: 1007px) {
  .requestBody {
    grid-template-columns: auto;
  }
}
</style>
