const mask = {
  methods: {
    formatCellphoneWithoutAreacode(inputname) {
      this[inputname] = this[inputname].replace(/\D/g, '');
      const s1 = this[inputname].substring(0, 5);
      const s2 = this[inputname].substring(5, 9);
      const len = this[inputname].length;
      if (len > 5) this[inputname] = `${s1}-${s2}`;
    }
  }
};

export { mask };
