/* https://pt.wikipedia.org/wiki/Lista_de_institui%C3%A7%C3%B5es_de_ensino_superior_do_Brasil */
const universidades_por_estado = {
  "Acre": [
    "Faao - Faculdade da Amazônia Ocidental",
    "Fadisi - Faculdade Diocesana São José",
    "Fameta - Faculdade Meta",
    "Iesacre - Instituição de Ensino Superior do Acre",
    "Ifac - Instituto Federal do Acre",
    "Ufac - Universidade Federal do Acre",
    "Uninorte - União Educacional do Norte",
    "Outra",
    "Várias"],
  "Alagoas": [
    "CESMAC - Centro de Estudos Superiores de Maceió",
    "FAP - Faculdade Pitágoras",
    "FITS - Faculdade Integrada Tiradentes",
    "Ifal - Instituto Federal de Alagoas",
    "Ufal - Universidade Federal de Alagoas",
    "Uncisal - Universidade Estadual de Ciências da Saúde de Alagoas",
    "Uneal - Universidade Estadual de Alagoas",
    "Outra",
    "Várias"],
  "Amazonas": [
    "Cesf - Instituto de Ensino Superior Fucapi",
    "Ciesa - Centro Universitário de Ensino Superior do Amazonas",
    "Esbam - Escola Superior Batista do Amazonas",
    "Fal - Faculdade Literatus",
    "Fametro - Faculdade Metropolitana de Manaus",
    "FBNCTSB - Faculdade Boas Novas de Ciências Teológicas, Sociais e Biotecnológicas",
    "FMF - Faculdade Martha Falcão",
    "Fom - Faculdade de Odontologia de Manaus",
    "FSDB - Faculdade Salesiana Dom Bosco",
    "FT - Faculdade Táhirih",
    "Iaes - Faculdade do Amazonas",
    "Ifam - Instituto Federal do Amazonas",
    "Uea - Universidade do Estado do Amazonas",
    "Ufam - Universidade Federal do Amazonas",
    "ULBRA - Universidade Luterana do Brasil",
    "UNINILTONLINS - Centro Universitário Nilton Lins",
    "Uninorte - Centro Universitário do Norte",
    "Unip - Universidade Paulista",
    "Unisalle - Faculdade La Salle",
    "Outra",
    "Várias"],
  "Amapá": [
    "CEAP - Centro de Ensino Superior do Amapá",
    "FAMA - Faculdade do Amapá",
    "IESAP - Instituto de Ensino Superior do Amapá",
    "Ifap - Instituto Federal do Amapá",
    "IMMES - Instituto Macapaense do Melhor Ensino Superior",
    "META - Faculdade Meta",
    "SEAMA - Faculdade SEAMA",
    "Ueap - Universidade Estadual do Amapá",
    "Unifap - Universidade Federal do Amapá",
    "Outra",
    "Várias"],
  "Bahia": [
    "ACADEPOL - Academia da Polícia Civil do Estado da Bahia",
    "AMAEC - Faculdade Amec Trabuco",
    "APM - Academia de Polícia Militar",
    "ÁREA 1 - Faculdade Área 1",
    "EBMSP - Escola Bahiana de Medicina e Saúde Pública",
    "ESAEX - Escola de Administração do Exército",
    "ESEEMB - Escola de Engenharia Eletromecânica da Bahia",
    "ESTÁCIO FIB - Centro Universitário Estácio da Bahia",
    "F2J - Faculdade Dois de Julho",
    "FABAC - Faculdade Baiana de Ciências Contábeis",
    "FACCEBA - Faculdade Católica de Ciências Econômicas da Bahia",
    "FACDELTA - Faculdade Delta",
    "FACEMP - Faculdade de Ciências Empresarias",
    "FACESA - Faculdade Evangélica de Salvador",
    "FACET - Faculdade de Artes, Ciências e Tecnologias",
    "FACIBA - Faculdade de Ciências da Bahia",
    "Faculdade AGES",
    "FADBA - Faculdade Adventista da Bahia",
    "FAESF - Faculdade de Ensino Superior da Cidade de Feira de Santana",
    "FAINOR - Faculdade Independente do Nordeste",
    "FAMA - Faculdade Montessoriano de Salvador",
    "FAMAM - Faculdade Maria Milza",
    "FAMEC - Faculdade Metropolitana de Camaçari",
    "FAMETTIG - Faculdades Integradas Olga Mettig",
    "FAN - Faculdade Nobre de Feira de Santana",
    "FARB - Faculdade Regional da Bahia",
    "FASB - Faculdade do Sul da Bahia",
    "FAVIV - Faculdade Visconde de Cairú",
    "FBB - Faculdade Batista Brasileira",
    "FBD - Faculdade Baiana de Direito",
    "FCA - Faculdade Castro Alves",
    "FCCB - Faculdade de Ciências e Cultura da Bahia",
    "FCCC - Faculdade de Ciências e Cultura de Cajazeiras",
    "FDPII - Faculdade Dom Pedro II",
    "FG - Faculdade de Guanambi",
    "FGG - Faculdade de Ciências Educacionais Capim Grosso",
    "FHR - Faculdade Hélio Rocha",
    "FJT - Faculdade Juvêncio Terra",
    "FRB - Faculdade Ruy Barbosa",
    "FSBA - Faculdade Social da Bahia",
    "FSBB - Faculdade São Bento da Bahia",
    "FSC - Faculdade São Camilo",
    "FSSAL - Faculdade São Salvador",
    "FSTA - Faculdade São Tomaz de Aquino",
    "FTC - Faculdade de Tecnologia e Ciências",
    "FTE-BA - Faculdade de Tecnologia Empresarial",
    "IBES - Instituto Baiano de Ensino Superior",
    "IESUS - Instituto de Educação Superior Unyahna de Salvador",
    "IF Baiano - Instituto Federal Baiano",
    "IFBA - Instituto Federal da Bahia",
    "SENAI/CIMATEC - Faculdade de Tecnologia SENAI CIMATEC",
    "UCSAL - Universidade Católica de Salvador",
    "UEFS - Universidade Estadual de Feira de Santana",
    "UESB - Universidade Estadual do Sudoeste da Bahia",
    "UESC - Universidade Estadual de Santa Cruz",
    "UFBA - Universidade Federal da Bahia",
    "UFOB - Universidade Federal do Oeste da Bahia",
    "UFRB - Universidade Federal do Recôncavo da Bahia",
    "UFSB - Universidade Federal do Sul da Bahia",
    "UNEB - Universidade do Estado da Bahia",
    "UNICENID - Faculdade de Ciências Gerenciais da Bahia",
    "UNIFACS - Universidade Salvador",
    "UNIJORGE - Centro Universitário Jorge Amado",
    "UNILAB - Universidade da Integração Internacional da Lusofonia Afro-Brasileira ",
    "UNIME - União Metropolitana de Educação e Cultura",
    "UNIVASF - Fundação Universidade Federal do Vale do São Francisco",
    "UNIVERSO - Universidade Salgado de Oliveira",
    "Outra",
    "Várias"],
  "Ceará": [
    "CENTEC - Falcudade de Tecnologia",
    "ESTÁCIO FIC - Faculdade Integrada do Ceará",
    "FA7 - Faculdade 7 de Setembro",
    "FAC - Faculdades Cearenses",
    "FACE - Faculdade Evolutivo",
    "FALS - Faculdade de Ciências Aplicadas Doutor Leão Sampaio",
    "FAMETRO - Faculdade Metropolitana de Fortaleza",
    "FANOR - Faculdades Nordeste",
    "FAP - Faculdade Paraíso do Ceará",
    "FAS - Faculdade Ari de Sá",
    "FATE - Faculdade Ateneu",
    "FATENE - Faculdade Terra Nordeste",
    "FCC - Faculdade Católica do Ceará",
    "FCF - Faculdade Católica de Fortaleza",
    "FCRS - Faculdade Católica Rainha do Sertão",
    "FFB - Faculdade Farias Brito",
    "FGF - Faculdade Integrada da Grande Fortaleza",
    "FJN - Faculdade de Juazeiro do Norte",
    "FLF - Faculdade Luciano Feijão",
    "FMJ - Faculdade de Medicina de Juazeiro do Norte",
    "FTA - Faculdade de Tecnologia Apoena",
    "FVJ - Faculdade Vale do Jaguaribe",
    "IESF - Instituto de Ensino Superior de Fortaleza",
    "IFCE - Instituto Federal do Ceará",
    "INTA - Instituto Superior de Teologia Aplicada",
    "IVA - Instituto de Estudos e Pesquisas do Vale do Acaraú",
    "UECE - Universidade Estadual do Ceará",
    "UFC - Universidade Federal do Ceará",
    "UFCA - Universidade Federal do Cariri",
    "UNICHRISTUS - Centro Universitário Christus",
    "UNIFOR - Universidade de Fortaleza",
    "UNILAB - Universidade Federal de Integração Luso-Afro-Brasileira",
    "UPA - Universidade Patativa do Assaré",
    "URCA - Universidade Regional do Cariri",
    "UVA - Universidade Estadual Vale do Acaraú",
    "Outra",
    "Várias"],
  "Distrito Federal": [
    "CEUB - Centro Universitário de Brasília",
    "ESCS - Escola Superior de Ciências da Saúde",
    "FACICESP - FACULDADES INTEGRADAS ICESP - UNICESP - Brasília",
    "FACIPLAC - Faculdade de Ciência e Tecnologia do Planalto Central",
    "FACITEC - Faculdade de Ciências Sociais e Tecnológicas",
    "FASEP - Faculdade Serrana",
    "FATE - Faculdade Ateneu",
    "IESB - Instituto de Educação Superior de Brasília",
    "IFB - Instituto Federal de Brasília",
    "SENAC - Faculdade Senac - Distrito Federal",
    "UCB - Universidade Católica de Brasília",
    "UDF - Centro Universitário do Distrito Federal",
    "UnB - Universidade de Brasília",
    "UNIDESC - Centro Universitário de Desenvolvimento do Centro Oeste",
    "UNIEURO - Centro Universitário Euroamericano",
    "UNIP - Universidade Paulista",
    "UNIPLAN - Centro Universitário Planalto do Distrito Federal",
    "UNISEB - Centro Universitário Estácio UniSEB - Brasília",
    "UNOPAR - Universidade Norte do Paraná",
    "UPIS - União Pioneira de Integração Social",
    "Outra",
    "Várias"],
  "Espírito Santo": [
    "CESAT - Escola de Ensino Superior Anísio Teixeira",
    "CESV - Centro de Ensino Superior de Vitória",
    "CUSC - Centro Universitário São Camilo",
    "Doctum - Instituto Doctum",
    "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória",
    "ESFA - Escola Superior São Francisco de Assis",
    "FAACZ - Faculdade de Aracruz",
    "FABAVI - Faculdade Batista de Vitoria",
    "FACE - Faculdade Casa do Estudante",
    "FACELI - Faculdade de Ensino Superior de Linhares",
    "FAESA - Faculdades Integradas Espírito-Santenses",
    "FAFIA - Faculdade de Filosofia Ciências e Letras de Alegre",
    "FAMES - Faculdade De Musica do Espírito Santo \"Mauricio de Oliveira\"",
    "FAPECS - Instituto de Pesquisa em Ciências da Saude",
    "FASE - Faculdade da Serra",
    "Favi/FACES - Faculdade do Espírito Santo",
    "FCB - Faculdade Castelo Branco",
    "FCMV - Faculdade Candido Mendes de Vitória",
    "FDV - Faculdades Integradas de Vitória",
    "FUCAPE - Fundação Instituto Capixaba de Pesquisas em Contabilidade, Economia e Finanças",
    "IFES - Instituto Federal do Espírito Santo",
    "MULTIVIX - Faculdade Brasileira",
    "PIO XII - Faculdade de Estudos Sociais do Espírito Santo",
    "SABERES - Faculdade Saberes",
    "UCL - Faculdade do Centro Leste",
    "UFES - Universidade Federal do Espírito Santo",
    "UNESA - Universidade Estácio de Sá",
    "UNESC - Centro Universitário do Espírito Santo",
    "UVV - Universidade Vila Velha",
    "Outra",
    "Várias"],
  "Goiás": [
    "ALFA - Faculdade Alves Faria",
    "CESUC - Centro de Ensino Superior de Catalão",
    "FAA - Faculdade Anhanguera de Anápolis",
    "Fac - Faculdade Católica de Anápolis",
    "FACEG - Faculdade Evangelica de Goianesia",
    "FACER - Faculdade de Ciências de Rubiataba",
    "FACERES - Faculdade de Ceres",
    "FASAM - Faculdade Sul-Americana",
    "FMB - Faculdade Montes Belos",
    "IAESUP - Faculdades e Colégio Aphonsiano",
    "IF Goiano - Instituto Federal Goiano",
    "IFG - Instituto Federal de Goiás",
    "INSJAR - Instituto Jordão de Arruda",
    "IPOG - Instituto de Pós-Graduação",
    "IUESO - Faculdades Objetivo",
    "PUC - Pontifícia Universidade Católica de Goiás",
    "UEG - Universidade Estadual de Goiás",
    "UFCat - Universidade Federal de Catalão",
    "UFG - Universidade Federal de Goiás",
    "UFJat - Universidade Federal de Jataí",
    "ULBRA - Universidade Luterana do Brasil",
    "UNESA - Universidade Estácio de Sá",
    "Unianhanguera - Universidade Anhanguera",
    "UniEvangelica - Universidade Evangélica",
    "UNIFASC - Faculdade Santa Rita de Cássia",
    "UNIP - Universidade Paulista",
    "UniRV (FESURV) - Universidade de Rio Verde",
    "UNIVERSO - Universidade Salgado de Oliveira",
    "Outra",
    "Várias"],
  "Maranhão": [
    "CEST - Faculdade Santa Terezinha",
    "FACEMA - Faculdade de Ciências e Tecnologia do Maranhão",
    "FACIMP - Faculdade de Imperatriz",
    "FAMA - Faculdade Atenas Maranhense",
    "FAP - Faculdade do Baixo Paranaíba",
    "FEST - Faculdade de Educação Santa Terezinha",
    "FSL - Faculdade São Luís",
    "IESF - Instituto de Ensino Superior Franciscano",
    "IFMA - Instituto Federal do Maranhão",
    "UEMA - Universidade Estadual do Maranhão",
    "UFMA - Universidade Federal do Maranhão",
    "UNDB - Unidade de Ensino Superior Dom Bosco",
    "UNICEUMA - Centro Universitário do Maranhão",
    "UNIVIMA - Universidade Virtual do Maranhão",
    "Outra",
    "Várias"],
  "Mato Grosso": [
    "Anhanguera - Faculdade Anhanguera",
    "CATHEDRAL - Faculdades Cathedral-Barra do Garças",
    "EDUVALE - Faculdade de Ciências Sociais Aplicadas do Vale do São Lourenço",
    "FADAF - Faculdade de Direito de Alta Floresta",
    "FAPAN - Faculdade do Pantanal",
    "FASIPE - Faculdade FASIPE",
    "IFMT - Instituto Federal do Mato Grosso",
    "LA SALLE - Faculdade La Salle",
    "UFMT - Universidade Federal de Mato Grosso",
    "ULBRA - Universidade Luterana do Brasil - EAD",
    "UNEMAT - Universidade do Estado de Mato Grosso",
    "UNIC - Universidade de Cuiabá",
    "UNIP - Universidade Paulista",
    "UNIRONDON - Centro Universitário Cândido Rondon",
    "UNIVAG - Centro Universitário de Várzea Grande",
    "UNIVAR - Universidades Unidas do Vale do Araguaia-Barra do Garças",
    "Outra",
    "Várias"],
  "Mato Grosso do Sul": [
    "AEMS - Faculdades Integradas de Três Lagoas",
    "FACSUL - Faculdade Mato Grosso do Sul",
    "FCG - Faculdade Campo Grande",
    "IFMS - Instituto Federal de Mato Grosso do Sul",
    "UCDB - Universidade Católica Dom Bosco",
    "UEMS - Universidade Estadual do Mato Grosso do Sul",
    "UFGD - Universidade Federal da Grande Dourados",
    "UFMS - Universidade Federal do Mato Grosso do Sul",
    "UFPAN - Universidade Federal do Pantanal",
    "UNAES - Centro Universitário de Campo Grande",
    "UNIDERP - Universidade para o Desenvolvimento do Estado e Região do Pantanal",
    "UNIGRAN - Centro Universitário da Grande Dourados",
    "Outra",
    "Várias"],
  "Minas Gerais": [
    "APM-MG - Academia de Polícia Militar de Minas Gerais",
    "ASMEC - Associação Sul Mineira de Educação e Cultura",
    "CECON - Centro Educacional Conceição Nunes",
    "CEFET-MG - Centro Federal de Educação Tecnológica de Minas Gerais",
    "CENSI - Centro de Ensino Superior de Itabira",
    "Centro Universitário Newton Paiva",
    "CES/JF - Centro de Ensino Superior de Juiz de Fora",
    "CUEA - Centro Universitário Eaprenda Elearning",
    "Doctum - Instituto Doctum",
    "EEK - Escola de Engenharia Kennedy",
    "EEWB - Escola de Enfermagem Wenceslau Braz",
    "ESDHC - Escola Superior Dom Helder Câmara",
    "FAC - Faculdade Arquidiocesana de Curvelo",
    "FACAPA - Faculdade Católica de Pouso Alegre",
    "FACECA - Faculdade Cenecista de Varginha",
    "FACED - Faculdade de Ciências Contabeis de Divinópolis",
    "FACESM - Faculdade de Ciências Sociais Aplicadas do Sul de Minas",
    "FACICA - Faculdade de Ciências da Saúde de Campos Gerais",
    "FACTU - Faculdade de Ciências e Tecnologia de Unaí",
    "FADMINAS - Faculdades Integradas Adventistas de Minas Gerais",
    "FAGAMMON - Faculdade Presbiteriana Gammon",
    "FAI - Centro de Ensino Superior em Gestão, Tecnologia e Educação",
    "FASF/Unisa - Faculdade de Filosofia, Ciências e Letras do Alto São Francisco",
    "FAVENORTE - Instituto Superior de Educação Verde Norte",
    "FAZU - Faculdades Associadas de Uberaba",
    "FCMMG - Faculdade de Ciências Médicas de Minas Gerais",
    "FCMS/JF - Faculdade de Ciências Médicas e da Saúde de Juiz de Fora",
    "FDSM - Faculdade de Direito do Sul de Minas",
    "FEAD - Faculdade de Estudos Administrativos de Minas Gerais",
    "FEMG - Faculdade de Engenharia de Minas Gerais",
    "FJP - Fundação João Pinheiro",
    "FMC - Faculdades Milton Campos",
    "FMG - Faculdade Metodista Granbery",
    "FMIT - Faculdade de Medicina de Itajubá",
    "FPAS - Faculdade Pitágoras de Administração Superior",
    "FPMG - Faculdade Promove de Minas Gerais",
    "FPU - Faculdade Politécnica de Uberlândia",
    "FSA - Faculdades Santo Agostinho",
    "FUMEC - Fundação Mineira de Educação e Cultura",
    "FUNCESI - Fundação Comunitária de Ensino Superior de Itabira",
    "FUNEDI - Fundação Educacional de Divinópolis",
    "FUNJOB - Faculdade de Medicina de Barbacena",
    "IESP - Instituto de Educação Superior de Patrocínio",
    "IFMG - Instituto Federal de Minas Gerais",
    "IFNMG - Instituto Federal do Norte de Minas Gerais",
    "IFSM - Instituto Federal do Sudeste de Minas",
    "IFSUM - Instituto Federal do Sul de Minas",
    "IFTM - Instituto Federal do Triângulo Mineiro",
    "Inatel - Instituto Nacional de Telecomunicações",
    "INESC - Instituto de Ensino Superior Cenecista",
    "Instituto Vianna Júnior",
    "IPTAN - Instituto de Ensino Superior Presidente Tancredo Neves",
    "ISEC - Instituto Superior de Educação de Campos Gerais",
    "PUCMG - Pontifícia Universidade Católica de Minas Gerais",
    "SECAL - Faculdade Santa Amélia",
    "UEMG - Universidade do Estado de Minas Gerais",
    "UFJF - Universidade Federal de Juiz de Fora",
    "UFLA - Universidade Federal de Lavras",
    "UFMG - Universidade Federal de Minas Gerais",
    "UFOP - Universidade Federal de Ouro Preto",
    "UFSJ - Universidade Federal de São João del-Rei",
    "UFTM - Universidade Federal do Triângulo Mineiro",
    "UFU - Universidade Federal de Uberlândia",
    "UFV - Universidade Federal de Viçosa",
    "UFVJM - Universidade Federal dos Vales do Jequitinhonha e Mucuri",
    "UIT - Universidade de Itaúna",
    "UNA - Centro Universitário UNA",
    "UNIARAXÁ - Centro Universitário do Planalto de Araxá",
    "UNIBH - Centro Universitário de Belo Horizonte",
    "UNICERP - Centro Universitário do Cerrado - Patrocínio",
    "UNIFAL - Universidade Federal de Alfenas",
    "UNIFEI - Universidade Federal de Itajubá",
    "UNIFEMM - Centro Universitário de Sete Lagoas \"Monsenhor Messias\"",
    "UNIFENAS - Universidade de Alfenas José do Rosário Vellano",
    "UNIFORMG - Centro Universitário de Formiga",
    "UNILAVRAS - Centro Universitário de Lavras",
    "UNILESTE - Centro Universitário do Leste de Minas Gerais",
    "UNIMONTES - Universidade Estadual de Montes Claros",
    "UNITPAC - Universidade Presidente Antônio Carlos",
    "UNIPAM - Centro Universitário de Patos de Minas",
    "UNITRI - Centro Universitário do Triângulo",
    "UNIUBE - Universidade de Uberaba",
    "UNIVALE - Universidade Vale do Rio Doce",
    "UNIVAS - Universidade do Vale do Sapucaí",
    "UNIVERSITAS - Centro Universitário de Itajubá",
    "UNIVERSO - Universidade Salgado de Oliveira",
    "UNIVIÇOSA - União de Ensino Superior de Viçosa",
    "SKEMA - Skema Business School",
    "Outra",
    "Várias"],
  "Pará": [
    "CEAMA - Centro de Educação da Amazônia",
    "CESUPA - Centro Universitário do Pará",
    "CIABA - Centro de Instrução Almirante Braz de Aguiar",
    "ESAMAZ - Escola Superior da Amazônia",
    "ESMAC - Escola Superior Madre Celeste",
    "ESTÁCIO FAP - Estácio de Sá Faculdade do Pará",
    "FAAM - Faculdade da Amazônia",
    "FAAMA - Faculdade Adventista da Amazônia",
    "FABEL - Faculdade de Belém",
    "FACETE - Faculdade de Ensino Tecnológico do Estado do Pará",
    "FACI - Faculdade Ideal",
    "FACIP - Faculdade Ipiranga",
    "FAMAZ - Faculdade Metropolitana da Amazônia",
    "FAPAN - Faculdade Pan Amazônica",
    "FCAT - Faculdade de Castanhal",
    "FEAPA - Faculdade de Estudos Avançados do Pará",
    "FIBRA - Faculdade Integrada Brasil Amazônia",
    "FIT - Faculdades Integradas do Tapajós (Santarém",
    "FMM - Faculdade Metropolitana de Marabá",
    "FPA - Faculdade Pan Americana",
    "IESAM - Instituto de Ensinos Superiores da Amazônia",
    "IFPA - Instituto Federal do Pará",
    "UEPA - Universidade do Estado do Pará",
    "UFOPA - Universidade Federal do Oeste do Pará",
    "UFPA - Universidade Federal do Pará",
    "UFRA - Universidade Federal Rural da Amazônia",
    "UNAMA - Universidade da Amazônia",
    "UNIFESSPA - Universidade Federal do Sul e Sudeste do Pará",
    "UNIP - Universidade Paulista",
    "Outra",
    "Várias"],
  "Paraíba": [
    "DEVRY - DeVry João Pessoa",
    "ESAC - Escola Superior de Aviação Civil",
    "ESBJ - Faculdade Maurício de Nassau",
    "FAC - Faculdade de Campina Grande",
    "FACENE - Faculdade de Enfermagem Nova Esperança",
    "FAESNE - Faculdade de Ensino Superior do Nordeste",
    "FAFIC - Faculdade de Filosofía, Ciências e Letras de Cajazeiras",
    "FAFIL - Faculdade de Filosofía Ciências e Letras",
    "FAP - Faculdade Paraibana",
    "FARR - Faculdade Reinaldo Ramos",
    "FASER - Faculdade Santa Emília de Rodat",
    "FASP - Faculdade São Francisco da Paraíba",
    "FATEC - Faculdade de Tecnologia de João Pessoa",
    "FATECPB - Faculdade de Tecnología da Paraíba",
    "FCM - Faculdade de Ciências Médicas de Campina Grande",
    "FCM-PB - Faculdade de Ciências Médicas da Paraíba",
    "FEF - Faculdade Evilásio Formiga",
    "FESP - Faculdade de Ensino Superior da Paraíba",
    "FESVIP - Faculdade de Enfermagem São Vicente de Paula",
    "FIAA - Faculdades Integradas Anglo - Americano",
    "FIP - Faculdades Integradas de Patos",
    "FPB - Faculdade Internacional da Paraíba",
    "FPB - Faculdade Potiguar da Paraíba",
    "FPPD - Faculdade Paraibana de Processamento de Dados",
    "FSM - Faculdade Santa María",
    "IESP - Instituto de Educação Superior da Paraíba",
    "IFPB - Instituto Federal da Paraíba",
    "INPER - Insituto Paraibano de Ensino Renovado",
    "IPOG - Instituto de Pós-Graduação & Graduação",
    "ISEC - Instituto Superior de Educação de Cajazeiras",
    "LUMEN FAC - Faculdade de Ciências Contábeis Luiz Mendes",
    "UEPB - Universidade Estadual da Paraíba",
    "UFCG - Universidade Federal de Campina Grande",
    "UFPB - Universidade Federal da Paraíba",
    "UNIBRATEC - Faculdade de Tecnologia Ibratec",
    "UNIPB - Faculdade Unida da Paraíba",
    "UNIPÊ - Centro Universitário de João Pessoa",
    "UNIUOL - Faculdade de Tecnologia do Uniuol",
    "Outra",
    "Várias"],
  "Paraná": [
    "CAMPO REAL - Faculdade Campo Real",
    "CESCAGE - Centro de Ensino Superior dos Campos Gerais",
    "CESUMAR - Centro Universitário de Maringá",
    "FAAB - Faculdade Alfa Brasil",
    "FAE - FAE Centro Universitário",
    "FAG - Faculdade Guairacá",
    "FAG - Fundação Assis Gurgacz",
    "FAJAR - Faculdade Jaguariaíva",
    "FANP - Faculdade do Noroeste Paranaense",
    "FAPI - Faculdades de Pinhais",
    "FARESC - Faculdades Integradas Santa Cruz de Curitiba",
    "FASF - Faculdade Sagrada Família",
    "FATEB - Faculdade de Telêmaco Borba",
    "FEITEP - Faculdade de Engenharia e Inovação Técnico Profissional",
    "FEPAR - Faculdade Evangélica do Paraná",
    "FESC - Fundação de Ensino Superior de Clevelândia",
    "FG - Faculdade Guarapuava",
    "FIES - Faculdades Integradas Espírita",
    "FML - Faculdade Metropolitana Londrinense",
    "FPP - Faculdades Pequeno Príncipe",
    "FTSA - Faculdade Teológica Sul Americana",
    "IFPR - Instituto Federal do Paraná",
    "OPET - Organização Paranaense de Ensino Técnico",
    "PUCPR - Pontifícia Universidade Católica do Paraná",
    "SECAL - Faculdade Santa Amélia",
    "UCP - Faculdades do Centro do Paraná",
    "UDC - Centro Universitário União Dinâmica das Cataratas",
    "UEB - Universidade Eletrônica do Brasil",
    "UEL - Universidade Estadual de Londrina",
    "UEM - Universidade Estadual de Maringá",
    "UENP - Universidade Estadual do Norte do Paraná",
    "UEPG - Universidade Estadual de Ponta Grossa",
    "UFFS - Universidade Federal da Fronteira do Sul",
    "UFPR - Universidade Federal do Paraná",
    "UNESPAR - Universidade Estadual do Paraná",
    "UNIANDRADE - Centro Universitário Campos de Andrade",
    "UNICAMPO - Faculdade União de Campo Mourão",
    "UNICENTRO - Universidade Estadual do Centro-Oeste",
    "UNICURITIBA - Centro Universitário Curitiba",
    "UNIFIL - Centro Universitário Filadélfia",
    "UNILA - Universidade Federal da Integração Latino-Americana",
    "UNINGÁ - Faculdade Ingá",
    "UNINTER - Grupo Educacional Uninter",
    "UNIOESTE - Universidade Estadual do Oeste do Paraná",
    "UNIPAR - Universidade Paranaense",
    "UNISEP - União de Ensino do Sudoeste do Paraná",
    "Univel - União Educacional de Cascavel",
    "UNOPAR - Universidade do Norte do Paraná",
    "UP - Universidade Positivo",
    "UTFPR - Universidade Tecnológica Federal do Paraná",
    "UTP - Universidade Tuiuti do Paraná",
    "VINCIT - Faculdade VINCIT",
    "Outra",
    "Várias"],
  "Pernambuco": [
    "CESA - Centro de Ensino Superior de Arcoverde",
    "CESVASF - Centro de Ensino Superior do Vale do São Francisco",
    "UNIBRA - Centro Universitário Brasileiro",
    "UNIFAVIP | Devry - Centro Universitário do Vale do Ipojuca",
    "UNINASSAU - Centro Universitário Mauricio de Nassau",
    "UNISÃOMIGUEL - Centro Universitário São Miguel",
    "ASCES-UNITA - Centro Universitário Tabosa de Almeida",
    "POLI - Escola Politécnica de Pernambuco",
    "ESEF - Escola Superior de Educação Física",
    "ESM - Escola Superior de Marketing",
    "Esurp - Escola Superior de Relações Públicas",
    "ESSA - Escola Superior de Saúde de Arcoverde",
    "ESUSPE - Escola Superior de Secretariado de Pernambuco",
    "FBV - Faculdade Boa Viagem",
    "FAESC - Faculdade da Escada",
    "FADIC - Faculdade Damas da Instrução Cristã",
    "FAUPE - Faculdade de Arquitetura e Urbanismo de Pernambuco",
    "FACIAGRA - Faculdade de Ciências Agrárias de Araripina",
    "FACAPE - Faculdade de Ciências Aplicadas e Sociais de Petrolina",
    "FACCOR - Faculdade de Ciências Contábeis de Recife",
    "FAGA - Faculdade de Ciências da Administração de Garanhuns",
    "FCAP - Faculdade de Ciências da Administração de Pernambuco",
    "FACAL - Faculdade de Ciências da Administração do Limoeiro",
    "FACET - Faculdade de Ciências de Timbaúba",
    "FACISST - Faculdade de Ciências e Saúde de Serra Talhada",
    "FACITEC - Faculdade de Ciências e Tecnologia de Caruaru",
    "FACHO - Faculdade de Ciências Humanas de Olinda",
    "FCHPE - Faculdade de Ciências Humanas de Pernambuco",
    "FACHUSC - Faculdade de Ciências Humanas do Sertão Central",
    "FACESF - Faculdade de Ciências Humanas e Exatas do Sertão de Pernambuco",
    "FACHUCA - Faculdade de Ciências Humanas e Sociais Aplicadas do Cabo de Santo Agostinho",
    "FACISA - Faculdade de Ciências Humanas e Sociais de Araripina",
    "FACIG - Faculdade de Ciências Humanas e Sociais de Igarassu",
    "ESUDA - Faculdade de Ciências Humanas Esuda",
    "FCM - Faculdade de Ciências Médicas de Pernambuco",
    "FACIP - Faculdade de Ciências Sociais dos Palmares",
    "FACETEG - Faculdade de Ciências, Educação e Tecnologia de Garanhuns",
    "FACOTTUR - Faculdade de Comunicação e Turismo de Olinda",
    "FADIRE - Faculdade de Desenvolvimento e Integração Regional",
    "FDG - Faculdade de Direito de Garanhuns",
    "FAEST - Faculdade de Educação Superior de Timbaúba",
    "FAEB - Faculdade de Enfermagem de Belo Jardim",
    "FENSG - Faculdade de Enfermagem Nossa Senhora das Graças",
    "FAFICA - Faculdade de Filosofia, Ciências e Letras de Caruaru",
    "FAMASUL - Faculdade de Formação de Professores da Mata Sul",
    "FAFOPAI - Faculdade de Formação de Professores de Afogados da Ingazeira",
    "FAFOPA - Faculdade de Formação de Professores de Araripina",
    "FABEJA - Faculdade de Formação de Professores de Belo Jardim",
    "FFPG - Faculdade de Formação de Professores de Goiana",
    "FFPNM - Faculdade de Formação de Professores de Nazaré da Mata",
    "FFPP - Faculdade de Formação de Professores de Petrolina",
    "FAFOPST - Faculdade de Formação de Professores de Serra Talhada",
    "FACIR - Faculdade de Informática do Recife",
    "FMO - Faculdade de Medicina de Olinda",
    "FOP - Faculdade de Odontologia de Pernambuco",
    "FOR - Faculdade de Odontologia do Recife",
    "FOCCA - Faculdade de Olinda",
    "FATEC - Faculdade de Tecnologia e Ciências de Pernambuco",
    "FAMA - Faculdade de Tecnologia Fama",
    "UNIBRATEC - Faculdade de Tecnologia Ibratec",
    "FATIN - Faculdade de Teologia Integrada",
    "FADE - Faculdade Decisão",
    "FAREC - Faculdade do Recife",
    "FG - Faculdade dos Guararapes",
    "FDC - Faculdade Duarte Coelho",
    "FACOL - Faculdade Escritor Osman da Costa Lins",
    "ESTÁCIO-FIR - Faculdade Estácio do Recife",
    "FEPAM - Faculdade Européia de Administração e Marketing",
    "FACIPE - Faculdade Integrada de Pernambuco",
    "FJN - Faculdade Joaquim Nabuco",
    "FAJOLCA - Faculdade José Lacerda Filho de Ciências Aplicadas",
    "FALUB - Faculdade Luso-Brasileira",
    "FMR - Faculdade Marista",
    "UNESJ - Faculdade Metropolitana da Grande Recife",
    "FNR - Faculdade Nova Roma",
    "FADEPE - Faculdade para o Desenvolvimento de Pernambuco",
    "Fape - Faculdade Pernambucana",
    "FPS - Faculdade Pernambucana de Saúde",
    "FASNE - Faculdade Salesiana do Nordeste",
    "FASC - Faculdade Santa Catarina",
    "FACRUZ - Faculdade Santa Cruz",
    "FASH - Faculdade Santa Helena",
    "FSM - Faculdade Santa Maria",
    "SENAC - Faculdade Senac Pernambuco",
    "AESO - Faculdades Integradas Barros Melo",
    "FAINTVISA - Faculdades Integradas da Vitória de Santo Antão",
    "ICB - Instituto de Ciências Biológicas",
    "IESO - Instituto de Ensino Superior de Olinda",
    "IESP - Instituto de Ensino Superior de Piedade",
    "INEVI - Instituto Educacional Vitória",
    "IFPE - Instituto Federal de Pernambuco",
    "IF sertão-PE - Instituto Federal do Sertão Pernambucano",
    "IPESU - Instituto Pernambucano de Ensino Superior",
    "INSAF - Instituto Salesiano de Filosofia",
    "ISEA - Instituto Superior de Educação Anchieta",
    "ISEF - Instituto Superior de Educação de Floresta",
    "ISEG - Instituto Superior de Educação de Goiana",
    "ISEP - Instituto Superior de Educação de Pesqueira",
    "ISES - Instituto Superior de Educação de Salgueiro",
    "ISESP - Instituto Superior de Educação do Sertão do Pajeú",
    "ISED - Instituto Superior de Educação Santa Cruz",
    "UNICAP - Universidade Católica de Pernambuco",
    "UPE - Universidade de Pernambuco",
    "UFPE - Universidade Federal de Pernambuco",
    "UFAPE - Universidade Federal do Agreste de Pernambuco",
    "UNIVASF - Universidade Federal do Vale do São Francisco",
    "UFRPE - Universidade Federal Rural de Pernambuco",
    "UNOPAR - Universidade Norte do Paraná",
    "UNIP - Universidade Paulista",
    "UNIVERSO - Universidade Salgado de Oliveira",
    "Outra",
    "Várias"],
  "Piauí": [
    "Aespi - Associação de Ensino Superior do Piauí",
    "ALIANÇA - Faculdade Aliança",
    "CET - Centro de Educação Tecnológico",
    "CEUT - Centro de Ensino Unificado de Teresina",
    "FACID - Faculdade Integral Diferencial",
    "FAMEP - Faculdade do Médio Parnaíba",
    "FAP - Faculdade Piauiense",
    "FSA - Faculdade Santo Agostinho",
    "ICF - Instituto Camillo Filho",
    "IERSA - Instituto Educacional Raimundo Sá (Faculdade Raimundo Sá)",
    "IFPI - Instituto Federal do Piauí",
    "ISEAF - Instituto Superior de Educação Antonino Freire",
    "Novafapi - Faculdade de Saúde, Ciências Humanas e Tecnológicas do Piauí",
    "NovaUnesc - Faculdade Nova Unesc",
    "UESPI - Universidade Estadual do Piauí",
    "UFPI - Universidade Federal do Piauí",
    "Outra",
    "Várias"],
  "Rio de Janeiro": [
    "ABMDP II - Academia de Bombeiro Militar Dom Pedro II",
    "AMAN - Academia Militar de Agulhas Negras",
    "APMDJVI - Academia de Polícia Militar Dom João VI",
    "BENNETT - Instituto Metodista BENNETT",
    "CEFET/RJ - Centro Federal de Educação Tecnológica Celso Suckow da Fonseca",
    "CELSO LISBOA - Centro Universitário Celso Lisboa",
    "CIAGA - Centro de Instrução Almirante Graça Aranha",
    "EN - Escola Naval",
    "ENCE - Escola Nacional de Ciências Estatísticas",
    "FACHA - Faculdades Integradas Hélio Alonso",
    "FAETERJ - Faculdade de Educação Tecnológica do Estado do Rio de Janeiro",
    "FeMASS - Faculdade Professor Miguel Ângelo da Silva Santos - Macaé",
    "FFSD - Faculdade de Filosofia Santa Doroteia",
    "FGV - Fundação Getúlio Vargas",
    "FIJ - Faculdades Integradas de Jacarepaguá",
    "FMC - Faculdade de Medicina de Campos",
    "FSB/RJ - Faculdade de São Bento do Rio de Janeiro",
    "FSMA - Faculdade Salesiana de Macaé",
    "FTESM - Fundação Técnico Educacional Souza Marques",
    "FUNENSEG - Escola Nacional de Seguros",
    "Ibmec - Ibmec (Instituição de Ensino)",
    "IBMR - Centro Universitário Hermínio da Silveira",
    "IFF - Instituto Federal Fluminense",
    "IFRJ - Instituto Federal do Rio de Janeiro",
    "IME - Instituto Militar de Engenharia",
    "INFNET - Instituto Infnet",
    "Mackenzie Rio - Faculdade Presbiteriana Mackenzie RJ",
    "PUCRIO - Pontifícia Universidade Católica do Rio de Janeiro",
    "REDENTOR - Sociedade Universitária Redentor (Faculdade Redentor)",
    "SENAC - Faculdade Senac Rio de Janeiro",
    "UBM - Centro Universitário de Barra Mansa",
    "UCAM - Universidade Cândido Mendes",
    "UCB - Universidade Castelo Branco",
    "UCP - Universidade Católica de Petrópolis",
    "UENF - Universidade Estadual do Norte Fluminense",
    "UERJ - Universidade do Estado do Rio de Janeiro",
    "UEZO - Universidade Estadual da Zona Oeste",
    "UFF - Universidade Federal Fluminense",
    "UFRJ - Universidade Federal do Rio de Janeiro",
    "UFRRJ - Universidade Federal Rural do Rio de Janeiro",
    "UGB - Centro Universitário Geraldo Di Biase",
    "UGS - Universidade Gama e Souza",
    "UNESA - Universidade Estácio de Sá",
    "UNIABEU - UNIABEU Centro Universitário",
    "UNICARIOCA - Centro Universitário Carioca",
    "UNIFOA - Centro Universitário de Volta Redonda",
    "UNIGRANRIO - Universidade do Grande Rio",
    "UniRedentor - Centro de Ensino Técnico Redentor",
    "UNIRIO - Universidade Federal do Estado do Rio de Janeiro",
    "UNISUAM - Centro Universitário Augusto Motta",
    "UNIVERCIDADE - Centro Universitário da Cidade do Rio de Janeiro",
    "UNIVERSO - Universidade Salgado de Oliveira",
    "USS - Universidade Severino Sombra",
    "USU - Universidade Santa Úrsula",
    "UVA - Universidade Veiga de Almeida",
    "Outra",
    "Várias"],
  "Rio Grande do Norte": [
    "Facene/RN - Faculdade de Enfermagem e Medicina Nova Esperança de Mossoró",
    "Facex - Faculdade de Ciências, Cultura e Extensão do RN",
    "FAL - Faculdade de Natal",
    "Fanec - Faculdade Natalense de Ensino e Cultura",
    "FARN - Faculdade Natalense para o Desenvolvimento do Rio Grande do Norte",
    "Fatern Gama Filho - Faculdade de Excelência do Rio Grande do Norte",
    "FCC - Faculdade Câmara Cascudo",
    "FCNSN - Faculdade Católica Nossa Senhora Das Neves",
    "IFRN - Instituto Federal do Rio Grande do Norte",
    "IPOG - Instituto de Pós-Graduação (Pólo Natal",
    "Mater Christi - Faculdade Mater Christi",
    "UERN - Universidade do Estado do Rio Grande do Norte",
    "UFERSA - Universidade Federal Rural do Semi-Árido",
    "UFRN - Universidade Federal do Rio Grande do Norte",
    "ULBRA - Universidade Luterana do Brasil (Polo Natal)",
    "UNINASSAU - Faculdade Maurício de Nassau - (Pólo Natal)",
    "UNOPAR - Universidade Norte do Paraná (Polo Natal)",
    "UNP - Universidade Potiguar",
    "Outra",
    "Várias"],
  "Rio Grande do Sul": [
    "CASTELLI - Escola Superior de Hotelaria Castelli",
    "CESF - Centro de Ensino Superior Cenecista de Farroupilha",
    "CNECSAN - Faculdade CNEC Santo Ângelo",
    "ESMARGS - Escola Superior de Música e Artes do Rio Grande do Sul",
    "EST - Faculdades EST",
    "FAACS - Faculdade Anglo-Americano de Caxias do Sul - Grupo Educacional",
    "FACCAT - Faculdades Integradas de Taquara",
    "FACENSA - Faculdade Cenecista Nossa Senhora dos Anjos",
    "FACOS - Faculdade Cenecista de Osório",
    "FADISMA - Faculdade de Direito de Santa Maria",
    "FAE - Faculdade Anglicana de Erechim",
    "FAE - Faculdades Equipe - Sapucaia do Sul",
    "FAM - Faculdade Antonio Meneghetti",
    "FAPA - Faculdade Porto Algrense",
    "FEEVALE - Universidade Feevale",
    "FEMA - Escola Técnica e Superior Machado de Assis - Santa Rosa",
    "FISMA - Faculdade Integrada de Santa Maria",
    "FSG - Faculdade da Serra Gaúcha",
    "FTEC - Faculdade de Tecnologia TecBrasil",
    "FURG - Universidade Federal do Rio Grande",
    "IACS - Instituto Adventista Cruzeiro do Sul",
    "IDEAU - Instituto de Desenvolvimento Educacional do Alto Uruguai - Getúlio Vargas",
    "IFFarroupilha - Instituto Federal Farroupilha",
    "IFRS - Instituto Federal do Rio Grande do Sul",
    "IFSul - Instituto Federal Sul-rio-grandense",
    "IPA - Centro Universitário Metodista do Instituto Porto Alegre",
    "MARQUÊS - Instituto de Educação Cenecista Marquês de Herval",
    "PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul",
    "SENAC - Faculdade Senac - Rio Grande do Sul",
    "SJT - Instituição Educacional São Judas Tadeu",
    "UCPEL - Universidade Católica de Pelotas",
    "UCS - Universidade de Caxias do Sul",
    "UERGS - Universidade Estadual do Rio Grande do Sul",
    "UFCSPA - Universidade Federal de Ciências da Saúde de Porto Alegre",
    "UFFS - Universidade Federal da Fronteira do Sul",
    "UFN - Universidade Franciscana",
    "UFPEL - Universidade Federal de Pelotas",
    "UFRGS - Universidade Federal do Rio Grande do Sul",
    "UFSM - Universidade Federal de Santa Maria",
    "ULBRA - Universidade Luterana do Brasil",
    "UNIANHANGUERA - Anhanguera Educacional S.A.",
    "UNICRUZ - Universidade de Cruz Alta",
    "UNIJUÍ - Universidade Regional do Noroeste do Estado do Rio Grande do Sul",
    "UNILASALLE-RJ - Universidade La Salle - RJ",
    "UNIPAMPA - Universidade Federal do Pampa",
    "UNIRITTER - Centro Universitário Ritter dos Reis",
    "UNISC - Universidade de Santa Cruz do Sul",
    "UNISINOS - Universidade do Vale do Rio dos Sinos",
    "UNIVATES - Centro Universitário UNIVATES",
    "UPF - Universidade de Passo Fundo",
    "URCAMP - Universidade da Região da Campanha",
    "URI - Universidade Regional Integrada do Alto Uruguai e das Missões",
    "Outra",
    "Várias"],
  "Rondônia": [
    "AVEC - Associação Vilhenense de Educação e Cultura",
    "CEULJI/ULBRA - Centro Universitário Luterano de Ji-Paraná",
    "CLARETIANO - centro universitário",
    "FAAR - Faculdades Associadas de Ariquemes",
    "FACIMED - Faculdade de Ciências Biomédicas de Cacoal",
    "FAEMA - Faculdade de Educação e Meio Ambiente",
    "FAEV/UNESC - Faculdade de Educação e Cultura de Vilhena",
    "FAMA - Faculdade da Amazônia",
    "FARO - Faculdade de Ciências Humanas, Exatas e Letras de Rondônia",
    "FIAR - Faculdades Integradas de Ariquemes",
    "FIMCA - Faculdades Integradas Aparício Carvalho",
    "FSL - Faculdade São Lucas",
    "Funorte - Faculdades Unidas do Norte de Minas",
    "IFRO - Instituto Federal de Rondônia",
    "ILES/ULBRA - Instituto Luterano de Ensino Superior de Porto Velho",
    "UNESC - Faculdades Integradas de Cacoal",
    "UNIJIPA - Faculdade Panamericana de Ji-Paraná",
    "UNIP - Universidade Paulista",
    "UNIR - Universidade Federal de Rondônia",
    "UNIRON - Faculdade Interamericana de Porto Velho",
    "UNOPAR - universidade á distancia do norte do Paraná",
    "Outra",
    "Várias"],
  "Roraima": [
    "Cathedral - Faculdades Cathedral",
    "Estácio - Estácio Atual",
    "FACETEN - Faculdade de Ciências, Educação e Teologia do Norte do Brasil",
    "FARES - Faculdade Roraimense de Ensino Superior",
    "IFRR - Instituto Federal de Roraima",
    "UERR - Universidade Estadual de Roraima",
    "UFRR - Universidade Federal de Roraima",
    "Outra",
    "Várias"],
  "Santa Catarina": [
    "ACE - Associação Catarinense de Ensino",
    "FAACH - Faculdade Santa Rita de Chapecó",
    "FACC - Faculdade Concórdia",
    "FAP - Faculdade Santa Rita Palmitos",
    "FMP - Faculdade Municipal de Palhoça",
    "FURB - Fundação Universidade Regional de Blumenau",
    "IFC - Instituto Federal Catarinense",
    "IFSC - Instituto Federal de Santa Catarina",
    "SENAC - Faculdade Senac - Santa Catarina",
    "SOCIESC - Sociedade Educacional de Santa Catarina",
    "UDESC - Universidade do Estado de Santa Catarina",
    "UFFS - Universidade Federal da Fronteira Sul",
    "UFSC - Universidade Federal de Santa Catarina",
    "UNC - Universidade do Contestado",
    "UNERJ - Centro Universitário de Jaraguá do Sul",
    "UNESC - Universidade do Extremo Sul Catarinense",
    "UNIARP - Universidade Alto Vale do Rio do Peixe",
    "UNIASSELVI - Centro Universitário Leonardo da Vinci",
    "UNIBAVE - Universidade Barriga Verde",
    "UNIDAVI- Universidade para o desenvolvimento do Alto Vale do Itajaí",
    "UNIFEBE - Centro Universitário de Brusque ",
    "UNIPLAC - Universidade do Planalto Catarinense",
    "UNISUL - Universidade do Sul de Santa Catarina",
    "UNIVALI - Universidade do Vale do Itajaí",
    "UNIVILLE - Universidade da Região de Joinville",
    "UNOCHAPECÓ - Universidade Comunitária Regional de Chapecó",
    "UNOESC - Universidade do Oeste de Santa Catarina",
    "USJ - Universidade Municipal de São José",
    "Outra",
    "Várias"],
  "São Paulo": [
    "AESA - Anhanguera Educacional",
    "AFA - Academia da Força Aérea",
    "APMBB - Academia de Polícia Militar do Barro Branco",
    "ASSER - Escola Superior de Tecnologia e Educação de Porto Ferreira",
    "BANDTEC - Faculdade de Tecnologia Bandeirantes",
    "BSP - Business School São Paulo",
    "CBM - Centro Universitário Barão de Mauá",
    "CEUNSP - Centro Universitário Nossa Senhora do Patrocínio",
    "Claretiano - Centro Universitário",
    "CUFSA - Centro Universitário Fundação Santo André",
    "CUSC - Centro Universitário São Camilo",
    "DOMPEDRO - Faculdades Integradas Dom Pedro II",
    "EEP/FUMEP - Escola de Engenharia de Piracicaba",
    "ESAGS - Escola Superior de Administração e Gestão",
    "ESEFIC - Escola Superior de Cruzeiro \"Prefeito Hamilton Vieira Mendes\"",
    "ESEFJ - Escola Superior de Educação Física de Jundiaí",
    "ESPM - Escola Superior de Propaganda e Marketing",
    "FAAP - Fundação Armando Álvares Penteado",
    "FACAMP - Faculdades de Campinas",
    "FACCAMP - Faculdade de Campo Limpo Paulista",
    "FACIC - Faculdade de Ciências Humanas de Cruzeiro ",
    "FACMIL - Faculdade de Administração de São José do Rio Preto",
    "Faculdade de Engenharia de Sorocaba",
    "FADI - Faculdade de Direito de Sorocaba",
    "FADISC - Faculdades Integradas de São Carlos",
    "FAFEM - Faculdades da Fundação de Ensino de Mococa",
    "FAI - Faculdades Adamantinenses Integradas",
    "FAIBI - Faculdade de Filosofia, Ciências e Letras de Ibitinga",
    "FALS - Faculdade do Litoral Sul Paulista",
    "FAM - Faculdade de Americana",
    "FAMEMA - Faculdade de Medicina de Marília",
    "FAMERP - Faculdade de Medicina de São José do Rio Preto",
    "FAPCOM - Faculdade Paulus de Comunicação e Tecnologia",
    "FATEB - Faculdade de Ciências e Tecnologia de Birigui",
    "FATEC - Faculdade de Tecnologia do Estado de São Paulo",
    "FATEC - Faculdade de Teologia e Ciências",
    "FATEC Luiz Rosa - Faculdade de Tecnologia Prof. Luiz Rosa",
    "FAZP - Faculdade Zumbi dos Palmares",
    "FCDA - Faculdade Drummond",
    "FCL - Faculdade Cásper Líbero",
    "FCMSCSP - Faculdade de Ciências Médicas da Santa Casa de São Paulo",
    "FDF - Faculdade de Direito de Franca",
    "FDSBC - Faculdade de Direito de São Bernardo do Campo",
    "FEA - Fundação Educacional Araçatuba",
    "FEBASP - Centro Universitário Belas Artes de São Paulo",
    "FECAP - Fundação Escola de Comércio Álvares Penteado",
    "FEF - Fundação Educacional de Fernandópolis",
    "FEI - Fundação Educacional Inaciana \"Pe. Sabóia de Medeiros\" (Faculdade de Engenharia Industrial)",
    "FESPSP - Fundação Escola de Sociologia e Política de São Paulo",
    "FFCL - Faculdade de Filosofia Ciências e Letras de São José do Rio Pardo",
    "FGV - Fundação Getúlio Vargas",
    "FIAP - Faculdade de Administração e Informática Paulista",
    "FIO - Faculdades Integradas de Ourinhos",
    "FIRB - Faculdades Integradas Rio Branco",
    "FIT - Faculdade Impacta Tecnologia",
    "FITO - Fundação Instituto Tecnológico de Osasco",
    "FLAMINGO - Faculdade Flamingo",
    "FMABC - Faculdade de Medicina do ABC",
    "FMJ - Faculdade de Medicina de Jundiaí",
    "FMPFM - Faculdade Municipal Professor Franco Montoro de Mogi Guaçu",
    "FMS - Faculdade Mario Schenberg",
    "FMU - Centro Universitário das Faculdades Metropolitanas Unidas",
    "FNC - Faculdade Nossa Cidade",
    "FOC - Faculdades Oswaldo Cruz",
    "FTML - Faculdade de Teologia Metodista Livre",
    "FTT - Faculdade de Tecnologia Termomecânica",
    "FUNEC - Faculdades Integradas de Santa Fé do Sul",
    "FV - Fundação Vanzolini",
    "IESF - Instituto de Ensino Superior da Fupesp",
    "IFSP - Instituto Federal de Educação, Ciência e Tecnologia de São Paulo",
    "IMES Catanduva - Instituto Municipal de Ensino Superior de Catanduva",
    "IMESA - Instituto Municipal de Ensino Superior de Assis",
    "IMESB - Instituto Municipal de Ensino Superior de Bebedouro Victório Cardassi",
    "IMESSM - Instituto Municipal de Ensino Superior de São Manuel",
    "IMMES - Instituto Matonense Municipal de Ensino Superior",
    "IMT - Instituto Mauá de Tecnologia",
    "Insper - Instituto de Ensino e Pesquisa",
    "ISES - Instituto Sumaré de Educação Superior",
    "ITA - Instituto Tecnológico de Aeronáutica",
    "ITE - Instituição Toledo de Ensino",
    "JAPI - Faculdade Japi",
    "LSB - Link School of Business",
    "MACK - Universidade Presbiteriana Mackenzie",
    "PUC-Campinas - Pontifícia Universidade Católica de Campinas",
    "PUCSP - Pontifícia Universidade Católica de São Paulo",
    "SENAC - Centro Universitário Senac - São Paulo",
    "SLMANDIC - Faculdade de Odontologia e Centro de Pesquisas Odontológicas São Leopoldo Mandic",
    "TECMED - Faculdade de Tecnologia Tecmed",
    "UAM - Universidade Anhembi Morumbi",
    "UBC - Universidade Braz Cubas",
    "UCESP - Faculdade União Cultural do Estado de São Paulo",
    "UFABC - Universidade Federal do ABC",
    "UFSCar - Universidade Federal de São Carlos",
    "UMC - Universidade de Mogi das Cruzes",
    "UMESP - Universidade Metodista de São Paulo",
    "UNAERP - Universidade de Ribeirão Preto",
    "UNASP - Centro Universitário Adventista de São Paulo",
    "UNESP - Universidade Estadual Paulista",
    "UNG - Universidade Guarulhos",
    "Uni-FACEF - Centro Universitário de Franca",
    "UniABC - Universidade do Grande ABC",
    "UNIARA - Centro Universitário de Araraquara",
    "UNIARARAS - Centro Universitário da Fundação Hermínio Ometto",
    "UNIBAN - Universidade Bandeirante de São Paulo",
    "UNIBERO - Centro Universitário Anhanguera de São Paulo",
    "UNICAMP - Universidade Estadual de Campinas",
    "UNICASTELO - Universidade Camilo Castelo Branco",
    "UNICEP - Centro Universitário Central Paulista",
    "UNICSUL - Universidade Cruzeiro do Sul",
    "UNIFAE - Centro Universitário das Faculdades Associadas de Ensino",
    "UNIFAAT - Centro Universitário UNIFAAT",
    "UNIFAI - Centro Universitário Assunção",
    "UNIFEB - Centro Universitário da Fundação Educacional de Barretos",
    "UNIFEOB - Centro Universitário da Fundação de Ensino Octávio Bastos",
    "UNIFESP - Universidade Federal de São Paulo",
    "UNIFIEO - Centro Universitário FIEO",
    "UNIFRAN - Universidade de Franca",
    "UNILAGO - União das Faculdades dos Grandes Lagos",
    "UNIMAR - Universidade de Marília",
    "UNIMEP - Universidade Metodista de Piracicaba",
    "UNIMES - Universidade Metropolitana de Santos",
    "UNIMONTE - Centro Universitário Monte Serrat",
    "UNINOVE - Universidade Nove de Julho",
    "UNIP - Universidade Paulista",
    "UNIRP - Centro Universitário de Rio Preto",
    "UNISA - Universidade de Santo Amaro",
    "UNISAL - Centro Universitário Salesiano de São Paulo",
    "UNISALESIANO - Centro Universitário Católico Salesiano Auxilium",
    "UNISANT'ANNA - Centro Universitário Sant'Anna",
    "UNISANTA - Universidade Santa Cecília",
    "UNISANTOS - Universidade Católica de Santos",
    "UniSãopaulo - Instituto São Paulo de Formação e Especialização Profissional",
    "UNISEB - Centro Universitário Estácio UniSEB -São Paulo",
    "UNISO - Universidade de Sorocaba",
    "UNITAU - Universidade de Taubaté",
    "UNIVAP - Universidade do Vale do Paraíba",
    "UNIVEM - Centro Universitário Eurípides de Marília",
    "Universidade Paulista - Campus Paraíso - São Paulo/SP",
    "Univesp - Universidade Virtual do Estado de São Paulo",
    "UNOESTE - Universidade do Oeste Paulista",
    "UNORP - Centro Universitário do Norte Paulista",
    "USCS - Universidade Municipal de São Caetano do Sul",
    "USF - Universidade São Francisco",
    "USJT - Universidade São Judas Tadeu",
    "USP - Universidade de São Paulo",
    "SPTECH - São Paulo Tech School",
    "Outra",
    "Várias"],
  "Sergipe": [
    "ESTÁCIO - Faculdade Estácio de Sergipe",
    "FA - Faculdade Atlântico",
    "FACAR - Faculdade de Aracaju",
    "FAJAR - Faculdade Jardins",
    "FAMA - Faculdade Amadeus",
    "FANESE - Faculdade de Administração e Negócios de Sergipe",
    "FASER - Faculdade Sergipana",
    "FPD - Faculdade Pio Décimo",
    "FSLF - Faculdade São Luis de França",
    "IFS - Instituto Federal de Sergipe",
    "UFS - Universidade Federal de Sergipe",
    "UniCOC - Sistema COC de Educação",
    "UNINASSAU - Faculdade Maurício de Nassau",
    "UNIRB - Faculdade Serigy",
    "Unit - Universidade Tiradentes",
    "UNOPAR - Universidade Norte do Paraná",
    "USE - Universidade Estadual de Sergipe",
    "Outra",
    "Várias"],
  "Tocantins": [
    "CEULP/ULBRA - Centro Universitário Luterano de Palmas",
    "FACDO - Faculdade Católica Dom Orione",
    "FACIT - Faculdade de Ciências do Tocantins",
    "FACTO - Faculdade Católica do Tocantins",
    "FAG - Faculdade Guaraí",
    "FAPAL - Faculdade de Palmas",
    "FASEC - Faculdade Serra do Carmo",
    "FECIPAR - Faculdade de Educação Ciências e Letras de Paraíso",
    "FIESC - Faculdade Integrada de Ensino Superior de Colinas",
    "IEPO - Instituto de Ensino e Pesquisa Objetivo",
    "IFTO - Instituto Federal do Tocantins",
    "ITPAC - Instituto Tocantinense Presidente Antônio Carlos",
    "ITPAC Porto - Instituto Tocantinense Presidente Antônio Carlos de Porto Nacional",
    "UFT - Universidade Federal do Tocantins",
    "UNEST - União Educacional de Ensino Superior do Médio Tocantins",
    "UnirG - Centro Universitário UnirG",
    "UNITINS - Fundação Universidade do Tocantins",
    "Outra",
    "Várias"]
};

export { universidades_por_estado };
