<template>
  <section>
    <div class="head">
      Contrapartidas
    </div>

    <div class="body">
      <b-loading
        v-if="isLoading"
        :is-full-page="true"
        :active="isLoading"
        :can-cancel="false"
      ></b-loading>

      <div class="titleCounterparts">
        <span>Formulário</span>
        <span v-if="request.counterparts.form">✅</span>
        <span v-else>❌</span> <br />
      </div>

      <span class="caption">
        Envie este formulário para os participantes da ação:
        <a :href="request.opportunity.form">
          {{ request.opportunity.form }}
        </a>
      </span>
      <br /><br />

      <span class="caption">
        Caso já tenha enviado o formulário, confirme ao lado:
        <b-button
          class="button is-primary is-rounded"
          style="position: relative; bottom: 8px;"
          @click="confirmFormSent"
        >
          Confirmar envio do formulário
        </b-button>
      </span>

      <div class="titleCounterparts">
        <span>Post</span>
        <span v-if="request.counterparts.posts">✅</span>
        <span v-else>❌</span> <br />
      </div>

      <span class="caption">Urls para os posts da contrapartida</span>

      <b-field
        label="URLs"
        label-position="on-border"
        custom-class="has-text-dark-gray"
      >
        <b-taginput
          v-model="urlPosts"
          placeholder="Adicione uma URL"
        ></b-taginput>
      </b-field>

      <span class="instruction"
        >*Tecle enter na barra de digitar para adicionar mais de uma URL</span
      >
      <div class="has-text-centered">
        <br />
        <button
          class="button is-primary is-medium has-text-weight-bold"
          type="submit"
          @click="submitPosts"
        >
          {{ this.request.posts ? "Atualizar" : "Enviar" }}
        </button>
      </div>

      <div class="titleCounterparts">
        <span>Fotos / Prints</span>
        <span v-if="request.counterparts.photos">✅</span>
        <span v-else>❌</span> <br />
      </div>

      <span class="caption">Fotos / Prints para a contrapartida</span>

      <input
        id="photos-zip"
        type="file"
        ref="counterpartsPhotos"
        style="display: none"
        @change="uploadCounterpartsPhotos"
      />

      <div class="content has-text-centered">
        <button class="button" @click="$refs.counterpartsPhotos.click()">
          {{ request.counterparts.photos ? "Atualizar" : "Enviar" }}
        </button>

        &nbsp;&nbsp;&nbsp;

        <a
          :disabled="!request.counterparts.photos"
          :href="request.counterparts.photosURL"
          class="button"
        >
          Baixar
        </a>

        &nbsp;&nbsp;&nbsp;

        <button
          :disabled="!request.counterparts.photos"
          class="button"
          @click="deleteCounterpart('photos')"
        >
          Excluir
        </button>
      </div>

      <span class="instruction"
        >*Enviar um único arquivo compactado (.zip ou .rar) contendo todas as
        fotos / prints</span
      >

      <div class="titleCounterparts">
        <span>Quantidade de participantes</span>
        <span v-if="request.eventInfo.realAttendeeQty">✅</span>
        <span v-else>❌</span> <br />
      </div>

      <span class="caption">
        Conte pra nós quantas pessoas participaram da ação
      </span>

      <b-field label="Número de Participantes" label-position="on-border">
        <b-input v-model="realAttendeeQty" />
      </b-field>

      <div class="has-text-centered">
        <br />
        <button
          class="button is-primary is-medium has-text-weight-bold"
          type="submit"
          id="button-submit-counterpart"
          @click="submitRealAttendeeQty"
          style="margin-top: -1rem;"
        >
          {{
            !isNaN(request.eventInfo.realAttendeeQty) ? "Atualizar" : "Enviar"
          }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { commom } from "../../../mixins/commom";

export default {
  name: "Counterparts",

  mixins: [commom],

  props: ["request"],

  data() {
    return {
      isLoading: false,
      urlPosts: [],
      realAttendeeQty: 0
    };
  },

  methods: {
    submitCounterparts() {
      if (this.request.counterparts === "posts") {
        if (!this.urlPosts.length) {
          this.alertError({ message: "ADD_AT_LEAST_ONE_URL_POST_COUNTERPART" });
          return;
        }

        this.isLoading = true;
        this.$store
          .dispatch("submitCounterpartsUrlPosts", {
            urlPosts: this.urlPosts,
            request: this.request
          })
          .then(() => {
            this.$store.dispatch("checkOpenRequests");
            this.request.counterparts.posts = true;
            this.request.counterparts.postsURL = this.urlPosts;
            this.successToast();
            this.$parent.close();
          })
          .catch(() => {
            this.alertError({ message: "UNEXPECTED_ERROR" });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    submitPosts() {
      if (!this.urlPosts.length) {
        this.alertError({ message: "ADD_AT_LEAST_ONE_URL_POST_COUNTERPART" });
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("submitCounterpartsUrlPosts", {
          urlPosts: this.urlPosts,
          request: this.request
        })
        .then(() => {
          this.$store.dispatch("checkOpenRequests");
          this.request.counterparts.posts = true;
          this.request.counterparts.postsURL = this.urlPosts;
          this.successToast();
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submitRealAttendeeQty() {
      this.isLoading = true;
      this.$store
        .dispatch("submitRealAttendeeQty", {
          realAttendeeQty: this.realAttendeeQty,
          request: this.request
        })
        .then(() => {
          this.request.eventInfo.realAttendeeQty = this.realAttendeeQty;
          this.successToast();
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    uploadCounterpartsPhotos(event) {
      const formData = new FormData();
      const file = event.target.files[0];

      if (file.size > 50000000) {
        this.alertError({ message: "FILE_IS_TOO_LARGE" });
        return;
      }

      formData.append("file", file);
      formData.append("fileId", event.srcElement.id);
      formData.append("requestId", this.request._id);
      formData.append("requestType", this.request.requestType);
      formData.append("businessName", this.request.opportunity.businessName);

      this.isLoading = true;
      this.$store
        .dispatch("uploadFile", {
          to: "upload-counterpart-photos",
          data: formData
        })
        .then(res => {
          this.$store.dispatch("checkOpenRequests");
          this.request.counterparts.photos = true;
          this.request.counterparts.photosURL = res.data;
          this.successToast();
          this.$parent.close();
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    successToast() {
      this.$buefy.toast.open({
        duration: 3000,
        message: "Contrapartida atualizada!",
        type: "is-primary",
        position: "is-bottom"
      });
    },

    deleteCounterpart(counterpart) {
      this.isLoading = true;
      this.$store
        .dispatch("deleteCounterpart", {
          requestId: this.request._id,
          counterpart
        })
        .then(() => {
          this.request.counterparts[counterpart] = false;
          this.successToast();
          this.$parent.close();
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    confirmFormSent() {
      this.isLoading = true;
      this.$store
        .dispatch("confirmFormSent", {
          requestId: this.request._id
        })
        .then(res => {
          if (res.data.result === "ok") {
            this.request.counterparts.form = true;
            this.successToast();
            this.$parent.close();
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.urlPosts = this.request.counterparts.postsURL.slice();
    this.realAttendeeQty = this.request.eventInfo.realAttendeeQty;
  }
};
</script>

<style scoped>
.head {
  text-align: center;
  background-color: rgb(200, 200, 200);
  font-size: 1.4rem;
  padding: 1rem;
  color: black;
  border-radius: 0.3rem 0.3rem 0 0;
}
.body {
  padding: 2rem;
}
.titleCounterparts {
  font-size: 1.3rem;
  color: black;
  font-weight: bold;
  margin-top: 1rem;
}
.caption {
  font-size: 1rem;
}
.field {
  margin-top: 0.5rem;
}
.instruction {
  font-size: 0.8rem;
}
</style>
