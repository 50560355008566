<template>
  <div class="column has-text-centered">
    <b-icon
      icon="bus-alt"
      :style="{ color: visitProfIsActive ? 'black' : '#d1d1d1' }"
    ></b-icon>
    <br />

    <span :style="{ color: visitProfIsActive ? 'black' : '#d1d1d1' }">
      Visita
    </span>
  </div>
</template>

<script>
export default {
  props: ["visitProfIsActive"]
};
</script>

<style scoped>
div {
  font-size: 1rem;
}
</style>
