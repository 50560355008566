<template>
  <div>
    <div class="column-left hero blackSide" style="background-color: black">
      <img
        src="../../assets/img/logos-empower/logo-empower-branco.png"
        alt=""
      />

      <div class="has-text-centered left-text">
        <p
          class="title is-2 is-size-3-mobile is-spaced has-text-white"
          style="margin-top: 3rem;"
        >
          PLATAFORMA PROFESSORES
        </p>

        <p
          class="subtitle is-4 is-size-5-mobile has-text-white has-text-weight-bold"
          style="max-width: 300px; margin: auto; margin-top: 4rem;"
        >
          CONECTE O SEU CURSO COM A
          <span class="has-text-primary">PRÁTICA</span> DO MERCADO
        </p>

        <p
          class="has-text-white"
          style="max-width: 300px; margin: auto; margin-top: 3rem;"
        >
          Conteúdos das empresas e parcerias para pesquisa. 100% gratuito!
        </p>
      </div>

      <router-view v-if="!isDesktop" />
    </div>

    <div class="column-right hero is-fullheight" v-if="isDesktop">
      <a href="https://empower.vision">
        <p>
          <span style="position: relative; bottom: 6px;">
            Voltar para site
          </span>
          <b-icon icon="arrow-alt-circle-right"></b-icon>
        </p>
      </a>
      <router-view v-if="isDesktop" />
    </div>
  </div>
</template>

<script>
import { responsive } from "../../mixins/responsive";

export default {
  mixins: [responsive],

  mounted() {
    document.querySelector("body").classList.remove("has-navbar-fixed-top");
  }
};
</script>

<style scoped>
.left-text {
  max-width: 880px;
  padding: 15vh 0 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .left-text {
    max-width: 660px;
  }
}
@media (max-width: 540px) {
  .left-text {
    max-width: 90%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1023px) {
  .column-left {
    width: 50%;
    overflow: hidden;
    float: left;
  }
  .left-text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
.column-right {
  width: 50%;
  overflow: hidden;
  float: right;
}
img {
  position: absolute;
  top: 30px;
  left: 30px;
  max-width: 200px;
}
a {
  position: absolute;
  top: 30px;
  right: 30px;
  color: black;
}

.blackSide{
  min-height: 64.5rem;
}
</style>
