<template>
  <section
    class="tipContent"
    :class="info.infoBorder ? 'tipBorder' : 'tipNoBorder'"
  >
    <div class="microfone">
      <b-icon icon="microphone-alt" type="is-fff"> </b-icon>
      <span class="textTip">{{ info.buttonText }}</span>
    </div>
    <div class="textDescription">{{ info.descriptionText }}</div>
  </section>
</template>

<script>
export default {
  name: "tip",

  props: ["info"]
};
</script>

<style>
.tipContent {
  background-color: white;
  height: 7rem;
  display: flex;
}
.tipBorder{
  border: solid rgb(175, 175, 175) 0.7px;
  border-radius: 1rem;
  box-shadow: 1px 1px 1px rgb(175, 175, 175);
}
.tipNoBorder {
  border: transparent;
}
.microfone {
  background-color: black;
  width: fit-content;
  height: 3rem;
  display: flex;
  border-radius: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2.5rem;
  padding-right: 1rem;
}
.microfone .icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.microfone .textTip {
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
}
.textDescription {
  color: black;
  font-size: 1.2rem;
  height: 4rem;
  max-width: 50rem;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

@media (max-width: 700px) {
  .tipContent{
    display: block;
    height: fit-content;
  }
  .microfone {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .textDescription {
    height: fit-content;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .microfone .textTip {
    font-size: 1.1rem;
  }
}
</style>
