<template>
  <div class="column">
    <div class="box">
      <h2 class="subtitle has-text-weight-bold">Pré-Requisitos</h2>

      <hr class="content-separator" />

      <p v-if="minPublic">
        <b>Público Mínimo:</b> {{ minPublic }} participantes<br />
      </p>

      <p v-if="maxPublic">
        <b>Público Máximo:</b> {{ maxPublic }} participantes<br /><br />
      </p>

      <p v-if="advance">
        <b>Antecedência Mínima:</b> {{ advance }} semanas<br /><br />
      </p>

      <p v-if="limitDateForActions">
        <b>Data limite para realização da ação:</b>
        {{ moment(limitDateForActions).format("DD/MM/YY") }}<br /><br />
      </p>

      <p v-if="courses"><b>Cursos:</b> {{ courses }}<br /><br /></p>

      <p v-if="states">
        <b>Estados:</b>
      </p>

      <ul>
        <li v-for="(state, index) in states" :key="index">
          &#9656; {{ state }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { momentMixin } from "../../../../mixins/moment";

export default {
  mixins: [momentMixin],

  props: [
    "minPublic",
    "maxPublic",
    "advance",
    "courses",
    "states",
    "limitDateForActions"
  ]
};
</script>

<style scoped>
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
