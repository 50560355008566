<template>
  <div class="column">
    <div class="box">
      <h2 class="subtitle has-text-weight-bold">Instalações</h2>
      <hr class="content-separator">
      <ul>
        <template v-for="(plant, index) in plants">
          <li v-if="plant.visitIsActive" :key="index">
            <b-collapse 
              :open="plants.length < minQtyToHidePlantDetails" 
              :aria-id="`collapse-${plant._id}`"
            >
              <p 
                slot="trigger" 
                :aria-controls="`collapse-${plant._id}`"
              >
                &rtrif; {{ plant.name }} 
                <b-icon 
                  v-if="plants.length >= minQtyToHidePlantDetails"
                  type="is-info"
                  icon="info-circle"
                  size="is-small"
                ></b-icon>
              </p>
              
              <p v-if="plant.visitDuration">&nbsp;&nbsp;&bull; <b>Duração:</b> {{ plant.visitDuration }}</p>
              
              <p v-if="plant.visitMinPublic && plant.visitMaxPublic">&nbsp;&nbsp;&bull; <b>Público mín./máx.:</b> {{ `${plant.visitMinPublic} a ${plant.visitMaxPublic}` }} participantes</p>
              <p v-else-if="plant.visitMinPublic">&nbsp;&nbsp;&bull; <b>Público mínimo:</b> {{plant.visitMinPublic}} participantes</p>
              <p v-else-if="plant.visitMaxPublic">&nbsp;&nbsp;&bull; <b>Público máximo:</b> {{plant.visitMaxPublic}} participantes</p>

              <p v-if="plant.visitGuide">&nbsp;&nbsp;&bull; <b>Roteiro:</b> {{ plant.visitGuide }}</p>
            </b-collapse>
            <br>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["plants"],

  data() {
    return {
      minQtyToHidePlantDetails: 4
    }
  }
}
</script>

<style scoped>
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
