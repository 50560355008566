<template>
  <div class="column">
    <div class="box">
      <h2 class="subtitle has-text-weight-bold">Contrapartidas</h2>

      <hr class="content-separator" />

      <p><b>Prazo:</b> até 7 dias após {{ action }}</p>

      <br />

      <p>
        <b>Formulário:</b> após a ação, enviar o formulário aos participantes
        para que possam compartilhar seus dados e comentários sobre a ação. O
        formulário será disponibilizado pela Empower.
      </p>
      <br />

      <div v-if="action === 'a aula'">
        <p>
          <b>Fotos/Prints:</b> enviar pelo menos 4 fotos/prints da participação
          em aula
        </p>
        <ul>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com o slide de capa</li>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com todos os participantes</li>
          <li>
            &nbsp;&nbsp;&nbsp;&#9656; 2 com o(a) gestor(a)
          </li>
        </ul>
      </div>

      <div v-else-if="action === 'a mentoria'">
        <p><b>Prints:</b> enviar pelo menos 4 prints da mentoria</p>
        <ul>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com o slide de capa</li>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com todos os participantes</li>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 2 com o(a) gestor(a)</li>
        </ul>
      </div>

      <div v-else-if="action === 'a visita'">
        <p><b>Prints ou Fotos:</b></p>
        <ul>
          <li>
            &#9656; <b>Para Visitas Presenciais:</b> enviar pelo menos 4 fotos
            da visita
          </li>
          <li>
            &#9656; <b>Para Visitas Online:</b> enviar pelo menos 4 prints da
            visita
          </li>
          <ul>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com o slide de capa</li>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com todos os participantes</li>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 2 com o(a) gestor(a)</li>
          </ul>
        </ul>
      </div>

      <div v-else-if="action === 'a palestra'">
        <p><b>Fotos:</b> enviar pelo menos 4 fotos da palestra</p>
        <ul>
          <li>&nbsp;&nbsp;&nbsp;&#9656; 2 do(a) gestor(a) palestrando</li>
          <li>
            &nbsp;&nbsp;&nbsp;&#9656; 2 do(a) gestor(a) palestrando com
            auditório ao fundo (mostrar o maior número de participantes
            possível)
          </li>
        </ul>
      </div>

      <div v-else-if="action === 'a ação'">
        <p><b>Prints ou Fotos:</b></p>
        <ul>
          <li>
            &#9656; <b>Para Ações Presenciais:</b> enviar pelo menos 4 fotos da
            da ação
          </li>
          <li>
            &#9656; <b>Para Ações Online:</b> enviar pelo menos 4 prints da ação
          </li>
          <ul>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com o slide de capa</li>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 1 com todos os participantes</li>
            <li>&nbsp;&nbsp;&nbsp;&#9656; 2 com o(a) gestor(a)</li>
          </ul>
        </ul>
      </div>

      <div v-else-if="action === 'o evento / projeto patrocinado'">
        <p>
          <b>Fotos:</b> enviar pelo menos 4 fotos do evento / projeto
          patrocinado
        </p>
      </div>

      <br />

      <p>
        <b>Posts:</b> publicar pelo menos 1 post sobre {{ actionName(action) }}, seja no LinkedIn do professor,
        LinkedIn do estudante representante, redes sociais da universidade, divulgações internas da universidade, etc.
      </p>

      <br />

      <div class="has-text-centered">
        <a :href="logosZipURL" class="button is-empower-gray-2">
          Baixar Logos
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { responsive } from "../../../../mixins/responsive";

export default {
  props: ["treatmentPronoun", "businessName", "logosZipURL", "action"],

  mixins: [responsive],

  methods: {
    actionName(name){
      if(name == 'a aula'){
        return 'a participação em aula'
      } else return name;
    }
  }
};
</script>

<style scoped>
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
