<template>
  <div class="column">
    <div class="box">
      <h2 class="subtitle has-text-weight-bold">
        Temas Sugeridos
      </h2>

      <hr class="content-separator" />

      <p v-if="themes.length === 0">
        Não existem temas sobre negócios e gestão
      </p>

      <p v-else>
        <b>Negócios e Gestão:</b>
      </p>

      <ul>
        <li v-for="(theme, index) in themes" :key="index">
          &#9656; {{ theme }}
        </li>
      </ul>

      <div v-if="specificThemes">
        <br />

        <p v-if="specificThemes.length === 0">
          Não existem temas ténicos e específicos
        </p>

        <p v-else>
          <b>Técnicos e Específicos:</b>
        </p>

        <ul>
          <li v-for="(specificTheme, index) in specificThemes" :key="index">
            &#9656; {{ specificTheme }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["themes", "specificThemes"]
};
</script>

<style scoped>
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
