const descriptions = {
  class:
    "<b>O QUE É?</b> As participações em aula são ações online ou presenciais a partir das quais os professores podem levar os conhecimentos das Empresas Mentoras para as suas aulas (conhecimentos sobre negócio, gestão, processos, tecnologia, diversidade, etc) para complementar o seu conteúdo programático com as melhores práticas do mercado. As participações em aula podem ser estruturadas em formato de webinar, palestra, treinamento, estudo de caso, etc, de acordo com a necessidade de cada professor.<br /><br /><b>COMO FUNCIONA?</b> As participações em aula são conduzidas pelos gestores das Empresas Mentoras em formato presencial ou online, de acordo com o contexto de cada professor e disponibilidade de cada empresa. O professor que solicita a participação em aula tem a oportunidade de definir o tema, roteiro, data e horário da ação.<br /><br /><b>DICAS:</b> Para facilitar a obtenção do Público Mínimo, o professor solicitante pode organizar a ação em parceria com outros professores, juntando as turmas.<br /><br /><b>IMPORTANTE:</b> A divulgação e a obtenção do Público Mínimo para as participações em aula são de responsabilidade dos professores solicitantes. <b>Obs: Os professores que não enviarem as contrapartidas (prints/fotos e posts) no prazo determinado poderão ter seus cadastrados e solicitações bloqueados na Plataforma Empower.</b>",
  research:
    "<b>O QUE É?</b> As participações em pesquisa são oportunidades para desenvolvimento de projetos de interesse acadêmico e que ao mesmo tempo possuam aplicação/ resolvam um problema real das Empresas Mentoras. O foco principal dessas parcerias são: projetos de Iniciação Científica, Trabalhos de Conclusão de Curso, projetos de Mestrado, Doutorado, etc.<br /><br /><b>COMO FUNCIONA?</b> As parcerias podem ser solicitadas pelos professores de tecnólogo, graduação, pós-graduação. Os interessados devem enviar uma proposta de parceria detalhada, contendo: 1.Informações sobre o projeto, 2.Apoio solicitado para a Empresa Mentora (compartilhamento de know-how específico, acesso a laboratórios específicos, doação de algum material produzido ou descartado pela Empresa Mentora, etc) e 3.Benefícios gerados pela parceria para a Empresa Mentora. Obs: As propostas devem ser enviadas no formato PDF, com até 5MB.<br /><br /><b>DICAS:</b> Capriche na estruturação da Proposta de Parceria. Explicite os benefícios que serão gerados para a Empresa Mentora, utilize elementos visuais (fotos, imagens, tabelas, gráficos, bullets, etc) e evite utilizar grandes blocos de texto. Prefira estruturar a proposta no power point sempre que possível.<br /><br /><b>IMPORTANTE:</b> Esse card não deve ser utilizado para solicitação de patrocínio ou agendamento de reuniões/calls de prospecção.Obs: Os professores que não enviarem as contrapartidas (formulário, fotos e posts) no prazo determinado poderão ter seus cadastros e solicitações bloqueados na Plataforma Empower.",
  visitProf:
    "<b>O QUE É?</b> As visitas técnicas são experiências estruturadas para que os estudantes possam vivenciar a cultura e o negócio das Empresas Mentoras.<br /><br /><b>COMO FUNCIONA?</b> As visitas técnicas online são bate papos informais com gestores das instalações selecionadas (sede, planta, centro de inovação, CD, etc) que possam explicar os processos produtivos, seus controles, forma de organização das instalações, etc. As visitas online são realizadas a partir das ferramentas de preferência da Empresa Mentora: skype, hangouts/meet, teams, etc e contam com a utilização de recursos visuais (vídeos, fotos, etc) para ilustrá-las. As visitas técnicas presenciais são realizadas nas instalações e estarão disponíveis assim que sairmos do período de isolamento social. Obs.: Nas visitas técnicas presenciais, a organização e o custeio do transporte são de responsabilidade do solicitante.<br /><br /><b>DICAS:</b> Para facilitar a obtenção do Público Mínimo, o professor solicitante pode organizar a ação em parceria com outros professores, juntando as turmas.<br /><br /><b>IMPORTANTE:</b> A divulgação e a obtenção do Público Mínimo para as visitas é de responsabilidade dos professores solicitantes. <b>Obs: Os professores que não enviarem as contrapartidas (prints/fotos e posts) no prazo determinado poderão ter seus cadastrados e solicitações bloqueados na Plataforma Empower.</b>",
  otherProf:
    "<b>O QUE É?</b> A partir desse card os professores podem solicitar Ações de Relacionamento que não estejam contempladas nos cards acima.<br /><br /><b>COMO FUNCIONA?</b> Ao solicitar as ações, os professores precisam informar: 1.Detalhes sobre a participação/apoio solicitados paras as Empresas Mentoras e 2.Benefícios oferecidos para as Empresas Mentoras. Obs: Um arquivo em formato PDF, com até 5MB, pode ser anexado às informações que serão enviadas para as Empresas Mentoras.<br /><br /><b>DICAS:</b> Organizem as informações em seções e explicitem os benefícios que serão oferecidos para as Empresas Mentoras.<br /><br /><b>IMPORTANTE:</b> Esse card não deve ser utilizado para agendamento de reuniões / calls de prospecção. <b>Obs: Os professores que não enviarem as contrapartidas (prints/fotos e posts) no prazo determinado poderão ter seus cadastrados e solicitações bloqueados na Plataforma Empower.</b>"
};

export { descriptions };
