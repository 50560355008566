<template>
  <div>
    <logged-in-header/>
    <user-config-form></user-config-form>
  </div>
</template>

<script>
import loggedInHeader from "../../components/commom/LoggedInHeader";
import userConfigForm from "../../components/userconfig/UserConfigForm";

export default {
  components: {
    loggedInHeader,
    userConfigForm
  }
};
</script>

