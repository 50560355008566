<template>
  <b-navbar id="navbar" fixed-top>
    <template slot="brand">
      <figure class="image">
        <router-link to="opportunities">
          <img
            src="../../assets/img/logos-empower/logo-empower-branco.png"
            alt="Logo Empower | Employer Branding e Recrutamento"
            title="Empower - Employer Branding e Recrutamento"
          />
        </router-link>
      </figure>
      <div id="black-detail"></div>
      <div id="gray-detail"></div>
    </template>

    <template slot="end">
      <div class="navbar-item has-dropdown is-hoverable userInfo">
        <div class="picture" v-if="account.picture || account.googlePictureURL">
          <img
            :src="account.picture ? account.picture : account.googlePictureURL"
            class="pictureImg"
            @click="goUserProfile"
          />
        </div>
        <div class="picture" v-else>
          <img
            style="background-color: white;"
            src="../../assets/img/avatar.png"
            class="pictureImg"
          />
        </div>

        <div class="professor-name has-text-centered">
          <div
            v-cloak
            class="has-text-centered has-text-fff"
            style="display: inline-block; margin-top: 1.5rem;"
          >
            <span class="has-text-fff">
              {{ `${account.name || ""} ${account.surname || ""}` }}
            </span>
          </div>
          <b-icon
            icon="caret-down"
            size="is-medium"
            class="caretAccount"
          ></b-icon>
        </div>

        <div
          class="navbar-dropdown navbar-dropdown-left"
          :class="{
            'navbar-dropdown-photo': account.picture || account.googlePictureURL
          }"
        >
          <div class="userInfoMenu">
            <div
              class="pictureMenu"
              v-if="account.picture || account.googlePictureURL"
            >
              <img
                :src="
                  account.picture ? account.picture : account.googlePictureURL
                "
                class="pictureImgMenu"
              />
            </div>
            <div class="professor-nameMenu">
              <b> {{ `${account.name || ""} ${account.surname || ""}` }} </b
              ><br />
              <br />
              {{ `${account.email || ""}` }}
            </div>
          </div>

          <hamburger-menu v-if="!isDesktop" />

          <hr class="frontier" />

          <a class="navbar-item" @click="logout">
            <b-icon icon="arrow-right" size="is-medium"></b-icon>
            Sair
          </a>
        </div>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import { commom } from "../../mixins/commom";
import { responsive } from "../../mixins/responsive";
import HamburgerMenu from "./HamburgerMenu";

export default {
  mixins: [commom, responsive],

  components: { HamburgerMenu },

  data() {
    return {
      showNavbarMenu: false
    };
  },

  computed: {
    ...mapGetters(["account"])
  },

  methods: {
    goUserProfile() {
      this.$router.push({ path: "userprofile" });
    }
  },

  mounted() {
    this.$store
      .dispatch("getAccountData", this.account)
      .then(res => {
        if (res.data.hasPendingCounterparts) {
          this.$buefy.dialog.alert({
            title: "Aviso",
            message: "Você tem contrapartidas pendentes para envio",
            type: "is-warning",
            hasIcon: true,
            icon: "exclamation-triangle",
            iconPack: "fa",
            onConfirm: () => this.$router.push("/requests"),
            onCancel: () => this.$router.push("/requests")
          });
        }
      })
      .catch(() => {
        this.alertError({ message: "UNEXPECTED_ERROR" });
      });
  }
};
</script>

<style scoped>
#navbar {
  z-index: 890;
  border-bottom: 4px solid #555555;
}
#black-detail {
  position: absolute;
  top: 0;
  left: -100px;
  background-color: black;
  width: 30%;
  min-width: 370px;
  max-width: 440px;
  height: 4.37rem;
  z-index: 20;
  -webkit-transform: skew(-40deg); /* Chrome, Opera */
  -ms-transform: skew(-40deg); /* IE */
  transform: skew(-40deg); /* Padrão */
}
#gray-detail {
  position: absolute;
  top: 0;
  left: -100px;
  background-color: #1b1c1d;
  width: 31.5%;
  min-width: 385px;
  max-width: 460px;
  height: 4.37rem;
  z-index: 10;
  -webkit-transform: skew(-40deg); /* Chrome, Opera */
  -ms-transform: skew(-40deg); /* IE */
  transform: skew(-40deg); /* Padrão */
}
.navbar-end .icon {
  margin-right: 1rem !important;
}
@media (max-width: 400px) {
  #black-detail {
    min-width: 320px;
  }
  #gray-detail {
    min-width: 335px;
  }
}
img {
  max-height: 24px;
  margin-top: 26px;
  margin-left: 4vw;
  width: 150px !important;
  cursor: pointer;
}
.image {
  z-index: 30;
}
@media (max-width: 1023px) {
  .navbar-item {
    color: white;
  }
  .professor-nameMenu {
    color: white !important;
  }
  .navbar-end a {
    margin-left: 1rem !important;
  }
  .navbar-end .icon {
    color: white;
  }
}
@media (max-width: 425px) {
  img {
    margin-left: 25px;
  }
}

.professor-name {
  margin-right: 4vw;
}

.menu-angle-down {
  padding-top: 22px;
  padding-left: 5px;
}
@media (max-width: 1023px) {
  .menu-angle-down {
    display: none;
  }
  .professor-name {
    display: none;
  }
}
.navbar-dropdown-left {
  left: -50%;
}
.navbar-dropdown-photo {
  left: -10%;
}

.navbar-divider-mobile {
  width: 80%;
  margin: 0.8rem 6vw 0.8rem calc(6vw + 12px);
  background-color: #8f8f8f;
  height: 1px;
}
@media (max-width: 1023px) {
  .navbar-end a {
    margin-left: 6vw;
  }
  .picture {
    display: none;
  }
}

.caretAccount {
  color: white;
  /* border: solid; */
  margin-left: 0.5rem;
}

.picture {
  width: 50px;
  height: 50px;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.pictureImg {
  border: solid white;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  max-height: none;
}

.frontier {
  background-color: #bbb8b8;
  height: 0.5px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.pictureImgMenu {
  border-radius: 100%;
  margin: 0;
  max-height: none;
  max-width: none;
  height: 60px;
  width: 60px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.professor-nameMenu {
  text-align: center;
}
</style>
