<template>
  <form @submit.prevent="signup">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <h1
      class="is-size-3 has-text-weight-semibold"
      :class="{ 'has-text-white': !isDesktop }"
    >
      Criar conta
    </h1>

    <p :class="{ 'has-text-white': !isDesktop }">
      Criar conta com:
    </p>
    <br />

    <div class="columns">
      <div class="column">
        <b-button type="is-primary" id="email-button" expanded>
          E-mail
        </b-button>
      </div>

      <div class="column has-text-centered">
        <g-signin-button
          :params="googleSignInParams"
          @success="onSignInSuccess"
          @error="onSignInError"
        >
          <img
            src="../../assets/img/g-logo.png"
            alt="Google Logo"
            id="g-logo"
          />
          <span id="google-button-text">Google</span>
        </g-signin-button>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="name && isDesktop ? 'Nome' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="name"
            name="name"
            class="is-expanded"
            type="text"
            placeholder="Nome"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field
          :label="surname && isDesktop ? 'Sobrenome' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="surname"
            name="surname"
            class="is-expanded"
            type="text"
            placeholder="Sobrenome"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="email && isDesktop ? 'E-mail institucional' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="email"
            name="email"
            class="is-expanded"
            type="email"
            placeholder="E-mail institucional"
            :disabled="disableEmail"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field
          :label="password && isDesktop ? 'Senha' : ''"
          label-position="on-border"
        >
          <b-input
            v-model="password"
            name="password"
            class="is-expanded"
            type="password"
            placeholder="Senha"
            password-reveal
          ></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field>
          <b-field
            :label="cellphone ? 'Celular' : ''"
            label-position="on-border"
          >
            <b-select placeholder="DDD" v-model="areacode">
              <option value="">DDD</option>

              <option v-for="(ddd, i) in ddds" :value="ddd" :key="i">
                {{ ddd }}
              </option>
            </b-select>

            <b-input
              placeholder="Celular"
              expanded
              v-model="cellphone"
            ></b-input>
          </b-field>
        </b-field>
      </div>
    </div>

    <p :class="{ 'has-text-white': !isDesktop }">
      Sobre a universidade em que leciona:
    </p>
    <br />

    <b-field
      :label="state && isDesktop ? 'UF/Cidade' : ''"
      label-position="on-border"
    >
      <b-select
        v-model="state"
        name="state"
        placeholder="UF"
      >
        <option value="">UF</option>
        <option value="Acre">AC</option>
        <option value="Alagoas">AL</option>
        <option value="Amapá">AP</option>
        <option value="Amazonas">AM</option>
        <option value="Bahia">BA</option>
        <option value="Ceará">CE</option>
        <option value="Distrito Federal">DF</option>
        <option value="Espírito Santo">ES</option>
        <option value="Goiás">GO</option>
        <option value="Maranhão">MA</option>
        <option value="Mato Grosso">MT</option>
        <option value="Mato Grosso do Sul">MS</option>
        <option value="Minas Gerais">MG</option>
        <option value="Pará">PA</option>
        <option value="Paraíba">PB</option>
        <option value="Paraná">PR</option>
        <option value="Pernambuco">PE</option>
        <option value="Piauí">PI</option>
        <option value="Rio de Janeiro">RJ</option>
        <option value="Rio Grande do Norte">RN</option>
        <option value="Rio Grande do Sul">RS</option>
        <option value="Rondônia">RO</option>
        <option value="Roraima">RR</option>
        <option value="Santa Catarina">SC</option>
        <option value="São Paulo">SP</option>
        <option value="Sergipe">SE</option>
        <option value="Tocantins">TO</option>
      </b-select>
      <b-autocomplete
        v-model="city"
        name="city"
        expanded
        :data="filteredCitiesArray"
        :open-on-focus="true"
        :keep-first="true"
        placeholder="Cidade"
        @select="option => (selected = option)"
        :disabled="!isValidState"
      >
        <template slot="empty">
          Sem resultados
        </template>
      </b-autocomplete>
    </b-field>

    <div class="columns">
      <div class="column">
        <b-field
          :label="university && isDesktop ? 'Universidade' : ''"
          label-position="on-border"
        >
          <b-autocomplete
            v-model="university"
            name="university"
            :data="filteredUniversitiesArray"
            :open-on-focus="true"
            :keep-first="true"
            placeholder="Universidade"
            @select="option => (selected = option)"
            :disabled="!isValidState"
          >
            <template slot="empty"
              >Sem resultados</template
            >
          </b-autocomplete>
        </b-field>
      </div>
    </div>

    <div class="columns" v-show="isAnotherUniversity">
      <div class="column">
        <b-field
          :label="
            anotherUniversity && isDesktop ? 'Qual outra universidade?' : ''
          "
          label-position="on-border"
        >
          <b-input
            v-model="anotherUniversity"
            name="anotherUniversity"
            placeholder="Qual outra universidade?"
          ></b-input>
        </b-field>
      </div>
    </div>

    <p :class="{ 'has-text-white': !isDesktop }">
      Leciona para:
    </p>

    <div class="column">
      <b-field>
        <b-checkbox v-model="specialization" native-value="technologist">
          <span :class="{ 'has-text-white': !isDesktop }">
            Tecnólogo (nível superior)
          </span>
        </b-checkbox>
      </b-field>

      <b-field v-if="specialization.indexOf('technologist') > -1">
        <b-taginput placeholder="Cursos" v-model="technologistGrade.courses" />
      </b-field>

      <b-field v-if="specialization.indexOf('technologist') > -1">
        <b-taginput
          placeholder="Disciplinas"
          v-model="technologistGrade.subjects"
        />
      </b-field>

      <b-field>
        <b-checkbox v-model="specialization" native-value="graduation">
          <span :class="{ 'has-text-white': !isDesktop }">
            Graduação
          </span>
        </b-checkbox>
      </b-field>

      <b-field v-if="specialization.indexOf('graduation') > -1">
        <b-taginput placeholder="Cursos" v-model="graduationGrade.courses" />
      </b-field>

      <b-field v-if="specialization.indexOf('graduation') > -1">
        <b-taginput
          placeholder="Disciplinas"
          v-model="graduationGrade.subjects"
        />
      </b-field>

      <b-field>
        <b-checkbox v-model="specialization" native-value="postGraduation">
          <span :class="{ 'has-text-white': !isDesktop }">
            Pós-Graduação
          </span>
        </b-checkbox>

        <span @click="postGraduationInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>
    </div>
    <p :class="{ 'has-text-white': !isDesktop }">
      Orienta:
    </p>

    <div class="column">
      <b-field>
        <b-checkbox native-value="tcc" v-model="guidance">
          <span :class="{ 'has-text-white': !isDesktop }">
            TCC
          </span>
        </b-checkbox>
        <span @click="tccInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>

      <b-field>
        <b-checkbox native-value="scientificResearch" v-model="guidance">
          <span :class="{ 'has-text-white': !isDesktop }">
            Iniciação Científica
          </span>
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="mastersDegree" v-model="guidance">
          <span :class="{ 'has-text-white': !isDesktop }">
            Mestrado
          </span>
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="doctorateDegree" v-model="guidance">
          <span :class="{ 'has-text-white': !isDesktop }">
            Doutorado
          </span>
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox native-value="organization" v-model="guidance">
          <span :class="{ 'has-text-white': !isDesktop }">
            Org. Estudantis / Extensão
          </span>
        </b-checkbox>
        <span @click="organizationInfo">
          <b-icon icon="info-circle" size="is-small" class="infoIcon"></b-icon>
        </span>
      </b-field>
    </div>
    <br />

    <div class="field">
      <b-checkbox v-model="hasAcceptedTermsAndConditions" type="is-info">
        <span :class="{ 'has-text-white': !isDesktop }">
          Aceito os
        </span>
        <a :href="TERMS_AND_CONDITIONS" target="_blank">
          Termos de Uso e a Política de Privacidade
        </a>
      </b-checkbox>
    </div>

    <button
      class="button is-primary is-medium is-fullwidth has-text-weight-bold"
    >
      Criar conta
    </button>

    <br />
    <p :class="{ 'has-text-white': !isDesktop }">
      Já tenho uma conta.
      <router-link to="/" class="has-text-primary has-text-weight-semibold">
        Entrar
      </router-link>
    </p>
    <br />
    <br />
  </form>
</template>

<script>
import { cidades_por_estado } from "../../assets/js/cidades-por-estado";
import { estados } from "../../assets/js/estados";
import { universidades_por_estado } from "../../assets/js/universidades-por-estado";
import { commom } from "../../mixins/commom.js";
import { responsive } from "../../mixins/responsive";
import { mask } from "../../mixins/mask";
import { ddds } from "../../assets/js/ddds";

export default {
  mixins: [commom, responsive, mask],

  data() {
    return {
      isLoading: false,
      TERMS_AND_CONDITIONS: process.env.VUE_APP_TERMS_AND_CONDITIONS,
      PRIVACY_POLICY: process.env.VUE_APP_PRIVACY_POLICY,
      name: "",
      surname: "",
      googlePictureURL: "",
      id_token: "",
      email: "",
      cellphone: "",
      areacode: "",
      password: "",
      city: "",
      state: "",
      university: "",
      anotherUniversity: "",
      specialization: [],
      technologistGrade: {
        courses: [],
        subjects: []
      },
      graduationGrade: {
        courses: [],
        subjects: []
      },
      guidance: [],
      hasAcceptedTermsAndConditions: false,
      citiesList: {},
      universitiesList: {},
      statesList: [],
      googleSignInParams: {
        client_id:
          "775299412180-ppi59j8ie7s4ul8k1rfb3oqrufs4403h.apps.googleusercontent.com"
      },
      disableEmail: false,
      ddds: []
    };
  },

  computed: {
    filteredCitiesArray() {
      let filteredCitiesArray = [];
      if (this.isValidState) {
        filteredCitiesArray = this.citiesList[this.state].filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.city.toLowerCase()) >= 0
          );
        });
      }
      return filteredCitiesArray;
    },
    filteredUniversitiesArray() {
      let filteredUniversitiesArray = [];
      if (this.isValidState) {
        filteredUniversitiesArray = this.universitiesList[this.state].filter(
          option => {
            return (
              option
                .toString()
                .toLowerCase()
                .indexOf(this.university.toLowerCase()) >= 0
            );
          }
        );
      }
      return filteredUniversitiesArray;
    },
    isAnotherUniversity() {
      return this.university === "Outra";
    },
    isValidState() {
      return this.statesList.includes(this.state);
    }
  },

  methods: {
    onSignInSuccess(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      const profile = googleUser.getBasicProfile();
      console.log(profile);
      this.name = profile.getGivenName();
      this.surname = profile.getFamilyName();
      this.email = profile.getEmail();
      this.disableEmail = true;
      this.googlePictureURL = profile.getImageUrl();
      this.id_token = googleUser.getAuthResponse().id_token;
    },

    onSignInError(error) {
      // `error` contains any error occurred.
      console.log('OH NOES', error);
      this.alertError({ message: "COULD_NOT_SIGN_IN_WITH_GOOGLE" });
    },

    async signup() {
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("signup");

      this.isLoading = true;
      this.$store
        .dispatch("signup", {
          professor: {
            name: this.name,
            surname: this.surname,
            email: this.email,
            areacode: this.areacode,
            cellphone: this.cellphone,
            password: this.password,
            city: this.city,
            state: this.state,
            university: this.university,
            anotherUniversity: this.anotherUniversity,
            specialization: this.specialization,
            technologistGrade: this.technologistGrade,
            graduationGrade: this.graduationGrade,
            guidance: this.guidance,
            hasAcceptedTermsAndConditions: this.hasAcceptedTermsAndConditions,
            id_token: this.id_token
          },
          token
        })
        .then(res => {
          if (res.data.result === "ok") {
            this.$buefy.dialog.alert({
              title: "Cadastro realizado!",
              message:
                "Agora só falta validar seu e-mail clicando no link que lhe enviamos.",
              type: "is-success",
              hasIcon: true,
              icon: "check",
              iconPack: "fa",
              onConfirm: () => this.$router.push("/"),
              onCancel: () => this.$router.push("/")
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.$recaptchaInstance.hideBadge();
          this.isLoading = false;
        });
    },

    postGraduationInfo() {
      this.$buefy.dialog.alert({
        title: "Pós-Graduação",
        message: " • Mestrado <br> • Doutorado <br> • MBA",
        confirmText: "OK"
      });
    },

    tccInfo() {
      this.$buefy.dialog.alert({
        title: "TCC",
        message:
          " • TCC - Trabalho de Conclusão de Curso <br> • TFG - Trabalho de Finalização de Graduação <br> • TG - Trabalho de Graduação",
        confirmText: "OK"
      });
    },

    organizationInfo() {
      this.$buefy.dialog.alert({
        title: "Org. Estudantil / Extensão",
        message:
          " • Empresa Júnior <br> • Equipes de Competição <br> • Centros Acadêmicos <br> • etc.",
        confirmText: "OK"
      });
    }
  },

  mounted() {
    this.citiesList = cidades_por_estado;
    this.universitiesList = universidades_por_estado;
    this.statesList = estados;
    this.ddds = ddds;
    if (this.$route.query.email) this.email = this.$route.query.email;
  }
};
</script>

<style scoped>
form {
  width: 60%;
  margin: auto;
  margin-top: 2vh;
}
@media (min-width: 1023px) {
  form {
    width: 80%;
    margin-top: 2vh;
  }
}
@media (max-width: 600px) {
  form {
    width: 70%;
  }
}
@media (max-width: 540px) {
  form {
    width: 90%;
  }
}
.error-background {
  background-color: #ff9797 !important;
}
#g-logo {
  width: 20px;
  position: relative;
  top: 3px;
  left: -3px;
}
#email-button,
.g-signin-button {
  border-radius: 18px;
}
.g-signin-button {
  background-color: white;
  width: 100%;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
#google-button-text {
  position: relative;
  right: -3px;
  bottom: 1px;
}

.infoIcon{
  color: #209cee;
  cursor: pointer;
}
</style>
