<template>
  <section
    class="hero is-fullheight"
    style="padding-left: 1rem; padding-right: 1rem;"
  >
    <div
      class="container"
      id="main-container"
      :style="[
        isTablet ? { 'padding-left': '10px', 'padding-right': '10px' } : {}
      ]"
    >
      <b-loading
        :is-full-page="true"
        :active="isLoadingRequests || isLoadingOpportunities"
        :can-cancel="false"
      ></b-loading>

      <h1 class="title is-spaced has-text-centered is-size-1 is-size-2-mobile">
        AÇÕES DE DESENVOLVIMENTO
      </h1>

      <p class="has-text-centered">
        Capacite e engaje seus alunos com uma experiência de aprendizagem
        inovadora.
        <br />
        Aumente a nota do seu curso no Enade e seja uma referência dentro da sua
        IES.
      </p>
      <br />
      <br />

      <div class="bodyContent">
        <div class="lateralComponents">
          <LateralMenu class="bodyMenu" />

          <div class="lateralFilter" v-if="windowWidth > 1023">
            <div class="headerFilter" @click="filterActive">
              <b-icon
                icon="filter"
                size="is-medium"
                class="filterIcon"
              ></b-icon>

              <span v-if="openFilter" style="padding-left: 10px;">FILTROS</span>
            </div>

            <div class="filterContent" v-if="openFilter">
              <b-select
                placeholder="Estado"
                class="selectState"
                v-model="filter.state"
              >
                <option> Estado </option>
                <option
                  v-for="estado in statesList"
                  :value="estado"
                  :key="estado"
                  class="stateOption"
                >
                  {{ estado }}
                </option>
              </b-select>
              <hr />

              <span>Status</span>
              <ul>
                <li>
                  <b-radio v-model="filter.status" native-value="">
                    Todas ({{ filteredList.length }})
                  </b-radio>
                </li>

                <li>
                  <b-radio v-model="filter.status" native-value="Ativa">
                    Ativa ({{ numberOfFeatures.ativa }})
                  </b-radio>
                </li>

                <li>
                  <b-radio v-model="filter.status" native-value="Nova">
                    Nova ({{ numberOfFeatures.nova }})
                  </b-radio>
                </li>

                <li>
                  <b-radio v-model="filter.status" native-value="Acabando">
                    Acabando ({{ numberOfFeatures.acabando }})
                  </b-radio>
                </li>

                <li>
                  <b-radio v-model="filter.status" native-value="Em breve">
                    Em Breve ({{ numberOfFeatures.breve }})
                  </b-radio>
                </li>

                <li>
                  <b-radio v-model="filter.status" native-value="Esgotada">
                    Esgotada ({{ numberOfFeatures.esgotada }})
                  </b-radio>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="bodyCards" id="bodyCards">
          <h2
            v-if="filteredList.length === 0"
            class="subtitle has-text-centered is-spaced"
          >
            Não existem Ações de Desenvolvimento em aberto no momento :(
          </h2>

          <div v-else class="columns is-multiline cards-columns">
            <div
              v-for="opportunity in opportunitiesOrderedByOrder"
              class="column is-half-tablet"
              :class="columnNum"
              :key="opportunity._id"
            >
              <opportunity-card :opportunity="opportunity" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { commom } from "../../mixins/commom";
import { responsive } from "../../mixins/responsive";
import OpportunityCard from "./opportunity-card/OpportunityCard";
import LateralMenu from "../lateral-menu/LateralMenu";
import { estados } from "../../assets/js/estados";

export default {
  mixins: [commom, responsive],

  components: {
    OpportunityCard,
    LateralMenu
  },

  data() {
    return {
      isLoadingRequests: false,
      isLoadingOpportunities: false,
      statesList: [],
      openFilter: true,
      filter: {
        state: "Estado",
        status: ""
      }
    };
  },

  methods: {
    checkOpenRequests() {
      this.isLoadingRequests = true;
      this.$store
        .dispatch("checkOpenRequests")
        .then(res => {
          if (res.data.result === "error") {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoadingRequests = false;
        });
    },

    getOpportunities() {
      this.isLoadingOpportunities = true;
      this.$store
        .dispatch("getOpportunities")
        .then(res => {
          if (res.data.result === "error") {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoadingOpportunities = false;
        });
    },

    //Abre ou fecha o filtro
    filterActive() {
      this.openFilter = !this.openFilter;
    }
  },

  computed: {
    ...mapGetters(["account", "opportunitiesOrderedByOrder", "filters"]),

    //Propriedades para tornar a página mais responsiva
    columnNum() {
      if (this.isSmall == 0) {
        return "is-half-desktop";
      } else if (this.isSmall == 1) {
        return "is-full";
      } else {
        return "is-one-third-desktop";
      }
    },

    isSmall() {
      if (this.windowWidth < 1280) {
        return 0;
      } else if (this.windowWidth < 950) {
        return 1;
      } else {
        return 2;
      }
    },

    //Filtra os componentes de acordo com o tipo de ação de desenvolvimento e estado selecionado no filtro
    filteredList() {
      let opportunities = this.opportunitiesOrderedByOrder;

      if (this.filter.state && this.statesList.includes(this.filter.state)) {
        opportunities = opportunities.filter(o => {
          return (
            o.lesson.isActive &&
            o.lesson.states &&
            (o.lesson.states.includes(this.filter.state) ||
              o.lesson.states.includes("Brasil Todo"))
          );
        });
      }
      return opportunities;
    },

    numberOfFeatures() {
      // Após a filtragem de ações de desenvolvimento e e estado, esse método filtra por status da ação, além de atualizar o contador de cada status.
      const numberOfFeatures = { //Quantas características existem entre as oportunidades
        ativa: 0,
        nova: 0,
        acabando: 0,
        breve: 0,
        esgotada: 0
      };

      let opportunities = this.filteredList;

      if (this.filter.status === "Ativa")
        opportunities = opportunities.filter(o => o.status === "active");

      if (this.filter.status === "Esgotada")
        opportunities = opportunities.filter(o => o.status === "soldout");

      if (this.filter.status === "Em breve")
        opportunities = opportunities.filter(o => o.status === "waiting");

      if (this.filter.status === "Nova")
        opportunities = opportunities.filter(o => o.spotlight === "new");

      if (this.filter.status === "Acabando")
        opportunities = opportunities.filter(o => o.status === "ending");

      numberOfFeatures.ativa = 0;
      numberOfFeatures.nova = 0;
      numberOfFeatures.esgotada = 0;
      numberOfFeatures.breve = 0;
      numberOfFeatures.acabando = 0;

      for (let i = 0; i < opportunities.length; i++) {
        if (opportunities[i].status === "active") {
          numberOfFeatures.ativa++;
        }
        if (opportunities[i].spotlight === "new") {
          numberOfFeatures.nova++;
        }
        if (opportunities[i].status === "soldout") {
          numberOfFeatures.esgotada++;
        }
        if (opportunities[i].status === "waiting") {
          numberOfFeatures.breve++;
        }
        if (opportunities[i].status === "ending") {
          numberOfFeatures.acabando++;
        }
      }
      return numberOfFeatures;
    }
  },

  mounted() {
    this.statesList = estados;
    this.checkOpenRequests();
    this.getOpportunities();
  }
};
</script>

<style scoped>
.is-centered {
  padding-bottom: 0;
}
section {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-image: linear-gradient(#02f8c7, #e9e9e9 40%);
}
.container {
  max-width: 200rem !important;
}
#main-container {
  min-width: 60% !important;
}
.cards-columns {
  max-width: 1100px;
  margin: auto;
}
@media (min-width: 768px) {
  #main-container {
    min-width: 95%;
  }
}
#page-title {
  color: white;
  font-size: 2.8rem;
}
.is-filter,
.is-filter:hover,
.is-filter:focus {
  color: white;
  background: none;
  border: 0;
  outline: none;
}

.bodyContent {
  display: grid;
  grid-template-columns: auto auto;
}

.bodyMenu {
  grid-column-start: 1;
  grid-row-start: 1;
  height: fit-content;
  margin-right: 0.7rem;
}

.bodyCards {
  grid-column-start: 2;
  grid-row-start: 1;
}

@media (max-width: 769px) {
  .bodyContent {
    display: block;
  }
}

.lateralFilter {
  background-color: white;
  border: solid 0.5px  rgb(207, 205, 205);
  border-radius: 10px;
  margin-top: 2rem;
  width: fit-content;
}

.headerFilter {
  padding: 20px;
}

.filterIcon {
  color: gray;
  cursor: pointer;
}

.filterIcon:hover {
  transform: scale(1.2);
}

.filterContent {
  width: 18.7rem;
  padding-bottom: 2rem;
}

.filterContent span {
  margin-left: 1rem;
  font-weight: bold;
}

.filterContent .checkbox {
  margin-left: 1rem;
  margin-top: 1rem;
}

.filterContent .radio {
  margin-left: 1rem;
  margin-top: 1rem;
}

.filterContent hr {
  background-color: rgb(207, 205, 205);
  height: 0.5px;
}

.selectState {
  width: fit-content;
  margin: auto auto;
}
</style>
