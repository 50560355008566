<template>
  <form @submit.prevent="forgotPassword">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <h1 class="title" :class="{ 'has-text-white': !isDesktop }">
      Esqueceu sua senha?
    </h1>

    <p class="subtitle" :class="{ 'has-text-white': !isDesktop }">
      Sem problemas! Digite seu e-mail cadastrado e enviaremos um link para
      redefinição da senha.
    </p>

    <b-field
      :label="email && isDesktop ? 'E-mail' : ''"
      label-position="on-border"
    >
      <b-input
        size="is-medium"
        v-model="email"
        class="is-expanded"
        type="email"
        placeholder="E-mail"
      ></b-input>
    </b-field>

    <br />
    <button
      class="button is-primary is-medium is-fullwidth has-text-weight-bold"
    >
      Enviar link
    </button>

    <br />
    <router-link to="login" tag="button" class="button">
      <b-icon icon="arrow-alt-circle-left"></b-icon>
      &nbsp; Voltar
    </router-link>
    <br />
    <br />

    <b-modal :width="520" :active="modalActive" :can-cancel="false">
      <div class="alertModal">
        <div class="modalTitle">
          <b-icon icon="envelope" size="is-small"> </b-icon>
          <span>E-mail enviado!</span>
        </div>
        <div class="message">
          <span class="messageTitle"
            ><div class="dashed"></div>
            Verifique sua caixa de entrada</span
          >
          <span class="messageBody"
            >Enviamos em seu e-mail o link para redefinição de senha.</span
          >
        </div>
        <div class="message">
          <span class="messageTitle"
            ><div class="dashed"></div>
            Não recebeu o e-mail?</span
          >
          <span class="messageBody"
            >Por favor, confira sua caixa de spam. Se o e-mail não estiver lá,
            tente novamente.</span
          >
        </div>
        <button @click="confirmModal" class="modalButton">
          <span>Voltar para a página de login</span>
        </button>
      </div>
    </b-modal>
  </form>
</template>

<script>
import { commom } from "../../mixins/commom.js";
import { responsive } from "../../mixins/responsive";

export default {
  mixins: [commom, responsive],

  data() {
    return {
      email: "",
      isLoading: false,
      modalActive: false
    };
  },

  methods: {
    forgotPassword() {
      this.isLoading = true;
      this.$store
        .dispatch("forgotPassword", {
          email: this.email
        })
        .then(res => {
          if (res.data.result === "ok") {
            this.modalActive = true; //Abre o modal
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    confirmModal() {
      //Fecha o modal e redireciona o usuário para a página de login
      this.modalActive = false;
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
form {
  width: 60%;
  margin: auto;
  margin-top: 5vh;
}
@media (min-width: 1023px) {
  form {
    width: 80%;
    margin-top: 15vh;
  }
}
@media (max-width: 600px) {
  form {
    width: 70%;
  }
}
@media (max-width: 540px) {
  form {
    width: 90%;
  }
}

.alertModal {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
}

.alertModal .icon {
  color: #02b893;
}

.modalTitle {
  margin-bottom: 1rem;
}

.modalTitle span {
  color: black;
  margin-left: 1rem;
  font-size: 18px;
}

.message {
  padding-left: 1rem;
  background-color: white;
}

.messageTitle {
  font-weight: bold;
  display: flex;
  margin-bottom: 0;
}

.messageBody {
  margin-top: 0;
}

.dashed {
  background-color: black;
  height: 0.5px;
  width: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}

.modalButton {
  margin-left: 0.5rem;
  border: none;
  background-color: white;
  cursor: pointer;
}

.modalButton span {
  color: #02b893;
}
</style>
