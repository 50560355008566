<template>
  <div class="column">
    <div class="box">
      <h2 class="subtitle has-text-weight-bold">Pré-Requisitos</h2>

      <hr class="content-separator" />

      <div v-for="(plant, index) in plants" :key="index">
        <b-collapse
          :open="plants.length < minQtyToHidePlantDetails"
          :aria-id="`collapse-${plant._id}`"
        >
          <p slot="trigger" :aria-controls="`collapse-${plant._id}`">
            &rtrif; {{ plant.name }}
            <b-icon
              v-if="plants.length >= minQtyToHidePlantDetails"
              type="is-info"
              icon="info-circle"
              size="is-small"
            ></b-icon>
          </p>

          <p v-if="plant.visitAdvance">
            &nbsp;&nbsp;&bull; <b>Antecedência Mínima:</b>
            {{ plant.visitAdvance }} semanas
          </p>

          <p v-if="plant.visitCourses">
            &nbsp;&nbsp;&bull; <b>Cursos:</b> {{ plant.visitCourses }}<br />
          </p>

          <p v-if="states">
            &nbsp;&nbsp;&bull;
            <b>Estados:</b>
          </p>

          <p v-if="limitDateForActions">
            &nbsp;&nbsp;&bull; <b>Data limite para realização da ação:</b>
            {{ moment(limitDateForActions).format("DD/MM/YY") }}<br /><br />
          </p>

          <ul>
            <li v-for="(state, index) in states" :key="index">
              &#9656; {{ state }}
            </li>
          </ul>
        </b-collapse>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { momentMixin } from "../../../../mixins/moment";

export default {
  props: ["plants", "states", "limitDateForActions"],

  mixins: [momentMixin],

  data() {
    return {
      minQtyToHidePlantDetails: 4
    };
  }
};
</script>

<style scoped>
.content-separator {
  background-color: rgb(211, 211, 211);
  height: 1px;
}
</style>
