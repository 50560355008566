<template>
  <div class="modal-card" style="width: auto">
    <b-loading
      :is-full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></b-loading>

    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">
        Visita Técnica (Presencial ou Online)
      </p>
    </header>

    <section class="modal-card-body">
      <form @submit.prevent="requestVisit">
        <b-field label="Sobre a Disciplina">
          <div class="column">
            <span>Leciona para:</span> <br />
            <br />

            <b-field>
              <b-radio
                v-model="request.eventInfo.subject.specialization"
                native-value="technologist"
                >Tecnólogo (nível superior)
              </b-radio>
            </b-field>

            <b-field>
              <b-radio
                v-model="request.eventInfo.subject.specialization"
                native-value="graduation"
                >Graduação (bacharelado e licenciatura)</b-radio
              >
            </b-field>

            <b-field>
              <b-radio
                v-model="request.eventInfo.subject.specialization"
                native-value="postGraduation"
                >Pós-Graduação
              </b-radio>

              <div @click="postGraduationDialog">
                <b-icon
                  icon="info-circle"
                  size="is-small"
                  class="infoIcon"
                ></b-icon>
              </div>
            </b-field>
          </div>
        </b-field>

        <b-field
          :label="
            request.eventInfo.subject.courses.length
              ? 'Cursos dos Estudantes'
              : ''
          "
          label-position="on-border"
        >
          <b-taginput
            v-model="request.eventInfo.subject.courses"
            placeholder="Cursos dos Estudantes"
          ></b-taginput>
        </b-field>

        <b-field
          label="Semestre dos Estudantes"
          custom-class="is-small"
          v-if="
            request.eventInfo.subject.specialization == 'technologist' ||
              request.eventInfo.subject.specialization == 'graduation'
          "
        >
          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="1"
          >
            1º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="2"
          >
            2º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="3"
          >
            3º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="4"
          >
            4º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="5"
          >
            5º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="6"
          >
            6º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="7"
          >
            7º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="8"
          >
            8º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="9"
          >
            9º
          </b-checkbox>

          <b-checkbox
            v-model="request.eventInfo.subject.semester"
            native-value="10"
          >
            +10º
          </b-checkbox>
        </b-field>

        <b-field
          :label="
            request.eventInfo.subject.subjectName ? 'Nome da Disciplina' : ''
          "
          label-position="on-border"
          v-if="
            request.eventInfo.subject.specialization == 'technologist' ||
            request.eventInfo.subject.specialization == 'graduation'
          "
        >
          <b-input
            v-model="request.eventInfo.subject.subjectName"
            placeholder="Nome da Disciplina"
          ></b-input>
        </b-field>

        <span>
          Há um aluno responsável pela ação junto com o professor? (Sim/Não)
        </span>
        <br />
        <br />

        <b-field>
          <b-radio
            v-model="request.eventInfo.subject.hasAssistantStudent"
            :native-value="true"
          >
            Sim
          </b-radio>

          <b-radio
            v-model="request.eventInfo.subject.hasAssistantStudent"
            :native-value="false"
          >
            Não
          </b-radio>
        </b-field>

        <div
          class="columns"
          v-if="request.eventInfo.subject.hasAssistantStudent"
        >
          <div class="column">
            <b-field
              :label="
                request.eventInfo.subject.studentName ? 'Nome do Aluno' : ''
              "
              label-position="on-border"
            >
              <b-input
                v-model="request.eventInfo.subject.studentName"
                placeholder="Nome do Aluno"
              ></b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field
              :label="
                request.eventInfo.subject.studentPhone ? 'Celular do Aluno' : ''
              "
              label-position="on-border"
            >
              <b-input
                v-model="request.eventInfo.subject.studentPhone"
                placeholder="Celular do Aluno"
              ></b-input>
            </b-field>
          </div>
        </div>

        <b-field
          :label="
            request.eventInfo.subject.studentEmail ? 'E-mail do Aluno' : ''
          "
          label-position="on-border"
          v-if="request.eventInfo.subject.hasAssistantStudent"
        >
          <b-input
            v-model="request.eventInfo.subject.studentEmail"
            placeholder="E-mail do Aluno"
          ></b-input>
        </b-field>
        <hr />

        <b-field label="Sobre a Visita Técnica">
          <div class="columns">
            <div class="column">
              <b-field label="Tipo de Ação" custom-class="is-small">
                <b-radio
                  v-model="request.eventInfo.isOnline"
                  :native-value="true"
                >
                  Online
                </b-radio>

                <b-radio
                  v-model="request.eventInfo.isOnline"
                  :native-value="false"
                >
                  Presencial
                </b-radio>
              </b-field>
            </div>

            <div class="column">
              <b-field
                :label="
                  request.eventInfo.attendeeQty ? 'Público estimado *' : ''
                "
                label-position="on-border"
              >
                <b-input
                  type="number"
                  v-model="request.eventInfo.attendeeQty"
                  placeholder="Público Estimado"
                />
              </b-field>
            </div>
          </div>
        </b-field>

        <date-options />

        <b-field label="Planta que deseja visitar" label-position="on-border">
          <b-select expanded v-model="request.eventInfo.plant">
            <option value="">Selecione uma planta</option>
            <option
              v-for="(plant, index) in plants"
              :value="plant.name"
              :key="index"
            >
              {{ ` ${plant.name} | ${plant.city}, ${plant.state}` }}
            </option>
          </b-select>
        </b-field>

        <b-field
          :label="request.organizationComments ? 'Comentários' : ''"
          label-position="on-border"
        >
          <b-input
            type="textarea"
            placeholder="Algum Comentário?"
            v-model="request.organizationComments"
          />
        </b-field>
        <hr />

        <b-field label="Seus Contatos">
          <b-field
            :label="account.cellphone ? 'Celular' : ''"
            label-position="on-border"
          >
            <b-select placeholder="DDD" v-model="contact.areacode">
              <option value="">DDD</option>

              <option v-for="(ddd, i) in ddds" :value="ddd" :key="i">
                {{ ddd }}
              </option>
            </b-select>

            <b-input
              placeholder="Celular"
              expanded
              v-model="contact.cellphone"
            ></b-input>
          </b-field>
        </b-field>

        <b-field
          :label="contact.lattes ? 'Plataforma Lattes - Link' : ''"
          label-position="on-border"
          style="margin-bottom: 0rem; margin-top: 1.5rem"
        >
          <b-input
            v-model="contact.lattes"
            placeholder="Plataforma Lattes - Link"
            expanded
          />
        </b-field>

        <b-field
          :label="contact.linkedin ? 'Linkedin - Link' : ''"
          label-position="on-border"
          style="margin-bottom: 0rem; margin-top: 1.5rem"
        >
          <b-input v-model="contact.linkedin" placeholder="Linkedin - Link" />
        </b-field>

        <hr />

        <b-field
          label="Termo de Contrapartidas *"
          custom-class="has-text-dark-grey "
        >
          <div class="field">
            <b-checkbox v-model="terms.photos" class="checkbox-bottom">
              Aceito enviar prints/fotos da visita técnica e coletar as devidas
              autorizações para uso de imagem dos participantes que aparecerem
              nos prints/fotos enviados.
            </b-checkbox>
            <br />

            <b-checkbox v-model="terms.post" class="checkbox-bottom">
              Aceito publicar um post na fanpage, insta ou linkedin do(a)
              professor(a).
            </b-checkbox>
          </div>
        </b-field>

        <div class="has-text-centered">
          <button
            class="button is-primary"
            :disabled="pendencies.length > 0"
            :style="[windowWidth < 769 ? { marginBottom: 40 + 'px' } : null]"
          >
            Solicitar Visita Técnica
          </button>
        </div>

        <Pendencies :pendencies="pendencies" />
      </form>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Cancelar
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { commom } from "../../../mixins/commom";
import { responsive } from "../../../mixins/responsive";
import dateOptions from "./date-time-options/DateOptions";
import { ddds } from "../../../assets/js/ddds";
import Pendencies from "../../commom/Pendencies";

export default {
  mixins: [commom, responsive],

  components: {
    dateOptions,
    Pendencies
  },

  data() {
    const today = new Date();
    return {
      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + this.advance * 7
      ),
      isLoading: false,
      ddds: [],
      plants: [{}],

      contact: {
        cellphone: "",
        areacode: "",
        lattes: "",
        linkedin: "",
      },

      request: {
        opportunity: null,
        requestType: "visit",
        eventInfo: {
          plant: "",
          isOnline: "",
          dateOptions: {
            first: {
              date: null,
              start: null,
              end: null,
            },
            second: {
              date: null,
              start: null,
              end: null,
            },
            third: {
              date: null,
              start: null,
              end: null,
            },
          },
          attendeeQty: null,
          subject: {
            specialization: "",
            courses: [],
            semester: [],
            subjectName: "",
            hasAssistantStudent: false,
            studentName: "",
            studentPhone: "",
            studentEmail: "",
          },
        },
        organizationComments: "",
      },

      pdf_visit: "",
      filename: "",
      terms: {
        photos: false,
        post: false,
      },
    };
  },

  methods: {
    handleFileUpload() {
      this.filename = this.$refs.pdf_visit.files[0].name;
      this.pdf_visit = this.$refs.pdf_visit.files[0];
    },

    excludeFile() {
      this.filename = "";
      this.pdf_lecture = "";
    },

    requestVisit() {
      if (this.pdf_visit.size > 5000000) {
        this.alertError({ message: "FILE_IS_TOO_LARGE" });
        return;
      }

      this.request.eventInfo.dateOptions.first.start = this.request.eventInfo.dateOptions.first.date;
      this.request.eventInfo.dateOptions.first.end = this.request.eventInfo.dateOptions.first.date;

      this.request.eventInfo.dateOptions.second.start = this.request.eventInfo.dateOptions.second.date;
      this.request.eventInfo.dateOptions.second.end = this.request.eventInfo.dateOptions.second.date;

      this.request.eventInfo.dateOptions.third.start = this.request.eventInfo.dateOptions.third.date;
      this.request.eventInfo.dateOptions.third.end = this.request.eventInfo.dateOptions.third.date;

      let formData = new FormData();
      formData.append("pdf_visit", this.pdf_visit);
      formData.append("request", JSON.stringify(this.request));

      this.isLoading = true;
      if (this.updateAccount) {
        this.account.areacode = this.contact.areacode;
        this.account.cellphone = this.contact.cellphone;
        this.account.lattes = this.contact.lattes;
        this.account.linkedin = this.contact.linkedin;

        let formData = new FormData();
        formData.append("account", JSON.stringify(this.account));

        this.$store
          .dispatch("updateAccount", formData)
          .then(res => {
            if (res.data.result === "ok") {
              console.log(
                "Dados de contato do usuário atualizados com sucesso!"
              );
            }
          })
          .catch(() => {
            console.log(
              "Houve um erro e os dados de contato desde usuário não puderam ser atualizados."
            );
          });
      }
      this.$store
        .dispatch("makeRequest", {
          to: "visit",
          data: formData,
        })
        .then((res) => {
          if (res.data.result === "ok") {
            this.$buefy.dialog.alert({
              title: "Solicitação de Visita Recebida",
              message:
                "Sua solicitação será analisada e enviada para a empresa. A resposta pode levar algumas semanas. Em caso de dúvida, entre em contato conosco.",
              type: "is-success",
              hasIcon: true,
              icon: "check",
              iconPack: "fa",
              onConfirm: () => this.$parent.close(),
              onCancel: () => this.$parent.close(),
            });
          } else {
            this.alertError(res.data);
          }
        })
        .catch(() => {
          this.alertError({ message: "UNEXPECTED_ERROR" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setActivePlants() {
      let j = 0;
      for (let i = 0; i < this.opportunityBeingVisited.plants.length; i++)
        if (this.opportunityBeingVisited.plants[i].visitIsActive)
          this.plants[j++] = this.opportunityBeingVisited.plants[i];
    },

    postGraduationDialog() {
      this.$buefy.dialog.alert({
        title: "Pós-Graduação",
        message: "- Mestrado <br> - Doutorado <br> - MBA <br> - Outros",
      });
    },

    findPlantByName(plantName) {
      return this.opportunityBeingVisited.plants.find(
        (p) => p.name == plantName
      );
    },
  },

  computed: {
    ...mapGetters(["opportunityBeingVisited", "account"]),

    pendencies() {
      const pendencies = [];
      if (!this.terms.photos || !this.terms.post) {
        pendencies.push("Aceitar os termos de contrapartidas");
      }
      if (!this.request.eventInfo.subject.specialization) {
        pendencies.push("Especialização dos estudantes");
      }
      if (
        this.request.eventInfo.subject.specialization == "technologist" ||
        this.request.eventInfo.subject.specialization == "graduation"
      ) {
        if (this.request.eventInfo.subject.semester.length < 1) {
          pendencies.push("Semestre dos Estudantes");
        }
        if (!this.request.eventInfo.subject.subjectName) {
          pendencies.push("Nome da Disciplina");
        }
      }
      if (this.request.eventInfo.subject.courses.length < 1) {
        pendencies.push("Cursos dos Estudantes");
      }
      if (this.request.eventInfo.subject.hasAssistantStudent) {
        if (this.request.eventInfo.subject.studentName == "") {
          pendencies.push("Nome do aluno responsável");
        }
        if (this.request.eventInfo.subject.studentPhone == "") {
          pendencies.push("Celular do aluno responsável");
        }
        if (this.request.eventInfo.subject.studentEmail == "") {
          pendencies.push("E-mail do aluno responsável");
        }
      }
      if (this.request.eventInfo.isOnline === "") {
        pendencies.push("Tipo da Visita");
      }
      if (!this.request.eventInfo.attendeeQty) {
        pendencies.push("Público estimado");
      }
      if (!this.request.eventInfo.dateOptions.first.date) {
        pendencies.push("Primeira opção de data");
      }
      if (!this.request.eventInfo.dateOptions.second.date) {
        pendencies.push("Segunda opção de data");
      }
      if (!this.request.eventInfo.dateOptions.third.date) {
        pendencies.push("Terceira opção de data");
      }
      if (!this.request.eventInfo.plant) {
        pendencies.push("Selecione uma planta");
      } else if (
        this.request.eventInfo.attendeeQty <
        this.findPlantByName(this.request.eventInfo.plant).visitMinPublic
      ) {
        pendencies.push(
          "O Público Estimado para a visita nessa planta precisa ser no mínimo " +
            this.findPlantByName(this.request.eventInfo.plant).visitMinPublic
        );
      }

      return pendencies;
    },

    updateAccount() {
      return (
        this.contact.areacode !== this.account.areacode ||
        this.contact.cellphone !== this.account.cellphone ||
        this.contact.lattes !== this.account.lattes ||
        this.contact.linkedin !== this.account.linkedin
      );
    }
  },

  created() {
    this.setActivePlants();
  },

  mounted() {
    this.request.opportunity = this.opportunityBeingVisited._id;

    this.ddds = ddds;

    this.contact.cellphone = this.account.cellphone;
    this.contact.areacode = this.account.areacode;
    this.contact.lattes = this.account.lattes;
    this.contact.linkedin = this.account.linkedin;
  },
};
</script>

<style scoped>
.modal-card-body {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.checkbox-bottom {
  padding-top: 0.5rem;
}

.infoIcon {
  color: #209cee;
  cursor: pointer;
}
</style>
