<template>
  <div>
    <logged-in-header />
    <requests-panel></requests-panel>
    <empower-footer />
  </div>
</template>

<script>
import EmpowerFooter from "../../components/commom/EmpowerFooter";
import loggedInHeader from "../../components/commom/LoggedInHeader";
import requestsPanel from "../../components/request/RequestsPanel";

export default {
  components: {
    loggedInHeader,
    requestsPanel,
    EmpowerFooter
  }
};
</script>

