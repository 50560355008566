<template>
  <section class="LateralMenu" v-if="showMenu">
    <div class="menu1">
      <div class="body-menu">
        <div @click="useMenu" class="buttonMenu">
          <b-icon icon="bars" size="is-medium"></b-icon>
        </div>
        <b-menu>
          <b-menu-list :label="empowerName">
            <b-menu-item
              tag="a"
              target="_blank"
              href="https://empower.vision/empower"
            >
              <template #label="">
                <img 
                  src="../../assets/img/logos-empower/simbolo-branco.png"
                  class="logoIcon"
                >
                
                <span class="text" v-if="menuExpanded">
                  A Empower
                </span>
              </template>
            </b-menu-item>

            <b-menu-item tag="router-link" to="/opportunities" :active="actionsActive">
              <template #label="">
                <b-icon icon="rocket" size="is-medium"></b-icon>

                <span class="text" v-if="menuExpanded" style="min-width: 13.2rem; display: inline-block;">
                  Ações de Desenvolvimento 
                </span>
              </template>
            </b-menu-item>

            <b-menu-item
              tag="a"
              target="_blank"
              href="https://empower.vision/professores#PerguntasFrequentes"
            >
              <template #label="">
                <b-icon icon="question-circle" size="is-medium"></b-icon>

                <span class="text" v-if="menuExpanded">
                  Perguntas Frequentes
                </span>
              </template>
            </b-menu-item>

            <b-menu-item tag="a" target="_blank" href="https://www.youtube.com/channel/UCn9lg9OKBmNBIohP3bfjpxQ/featured">

              <template #label="">
                <b-icon icon="calendar-alt" size="is-medium"></b-icon>

                <span class="text" v-if="menuExpanded">
                  Próximos Eventos
                </span>
              </template>
            </b-menu-item>

            <b-menu-item
              tag="a"
              target="_blank"
              href="https://empower.vision/inovacao"
            >
              <template #label="">
                <b-icon icon="lightbulb" size="is-medium"></b-icon>

                <span class="text" v-if="menuExpanded">
                  Parcerias para Projetos
                </span>
              </template>
            </b-menu-item>

            <b-menu-item
              :active="isActive"
              @click="isActive = !isActive"
              :expanded="isActive && menuExpanded"
            >
              <template #label="">
                <b-icon icon="link" size="is-medium"> </b-icon>
                <b-icon
                  v-if="menuExpanded"
                  class="is-pulled-right"
                  :icon="isActive ? 'caret-down' : 'caret-up'"
                  size="is-medium"
                ></b-icon>

                <span class="text" v-if="menuExpanded">
                  Redes Sociais Empower
                </span>
              </template>

              <b-menu-item 
                tag="a"
                target="_blank"
                href="https://www.facebook.com/empower.oficial"
              >
                <template #label="">
                  <b-icon
                  pack="fab"
                  icon="facebook"
                  type="is-empower-gray"
                  size="is-large"
                  ></b-icon>
                  <span class="text" v-if="menuExpanded">Facebook</span>
                </template>
              </b-menu-item>
              
              <b-menu-item 
                tag="a"
                target="_blank"
                href="https://www.instagram.com/empower.oficial/"
              >
                <template #label="">
                  <b-icon
                  pack="fab"
                  icon="instagram"
                  type="is-empower-gray"
                  size="is-large"
                  ></b-icon>
                  <span class="text" v-if="menuExpanded">Instagram</span>
                </template>
              </b-menu-item>
              
              <b-menu-item 
                tag="a"
                target="_blank"
                href="https://br.linkedin.com/company/empower-oficial"
              >
                <template #label="">
                  <b-icon
                  pack="fab"
                  icon="linkedin"
                  type="is-empower-gray"
                  size="is-large"
                  ></b-icon>
                  <span class="text" v-if="menuExpanded">LinkedIn</span>
                </template>
              </b-menu-item>
            </b-menu-item>
          </b-menu-list>

          <hr/>

          <b-menu-list :label="areaName">
            <b-menu-item tag="router-link" to="/userprofile" :active="userProfileActive">
              <template #label="">
                <b-icon icon="signal" size="is-medium"> </b-icon>
                <span class="text" v-if="menuExpanded">Meu Perfil</span>
              </template>
            </b-menu-item>

            <b-menu-item tag="router-link" to="/requests" :active="requestsActive" :style="openRequests > 0 && menuExpanded ? 'width: 17.7rem;' : ''">
              <template #label="">
                <b-icon icon="tasks" size="is-medium"></b-icon>

                <span class="text" v-if="menuExpanded">
                  Gerenciar Solicitações
                </span>

                <span
                  class="button is-rounded is-small has-background-danger has-text-white open-requests"
                  v-if="openRequests"
                >
                  <b>{{ openRequests }}</b>
                </span>
              </template>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { responsive } from "../../mixins/responsive";

export default {
  name: "LateralMenu",

  mixins: [responsive],

  data() {
    return {
      isActive: false,
      menuExpanded: true,
    };
  },

  computed: {
    //Essas propriedades apresentam os labels do menu caso o mesmo esteja expandido.
    empowerName() {
      if (this.menuExpanded) {
        return "Empower";
      } else {
        return "";
      }
    },
    areaName() {
      if (this.menuExpanded) {
        return "Área do Professor";
      } else {
        return "";
      }
    },
    //Essa propriedade torna o menu exclusivo para resolução desktop
    showMenu(){
      if(this.windowWidth > 1023){
        return true
      }else{
        return false
      }
    },
    ...mapGetters(["account", "openRequests"]),
    //As seguintes propriedades verificam a rota atual, ativando o ítem do menu correspondente
    actionsActive(){
      if(this.$route.name == "opportunities"){
        return true;
      } else return false;
    },
    userProfileActive(){
      if(this.$route.name == "userprofile"){
        return true;
      } else return false;
    },
    requestsActive(){
      if(this.$route.name == "requests"){
        return true;
      } else return false;
    }
  },
  methods:{
    //Método para expandir ou retrair o menu
    useMenu(){
      this.menuExpanded = !this.menuExpanded;
    }
  }
};
</script>

<style scoped>

.LateralMenu {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 11px;
  width: fit-content;
  background-image: none;
  background-color: white;
  border-radius: 10px;
}

.menu1 {
  border: solid rgb(207, 205, 205) 0.5px;
  background-color: white;
  border-radius: 10px;
}

.body-menu {
  margin: 8px;
}

.text {
  margin-left: 15px;
  position: relative;
  bottom: 6px;
}

.open-requests {
  position: relative;
  top: 2px;
}

hr {
  height: .5px;
  background-color: rgb(190, 189, 189);
}

.icon {
  color: gray;
}

.buttonMenu{
  margin-left: 13px;
  width: fit-content;
  background-color: transparent;
  cursor: pointer;
}

.buttonMenu:hover{
  transform: scale(1.2);
}

.logoIcon {
  filter: invert(50%);
  margin-left: 3px;
  width: 1.8rem;
  height: 1rem;
}

a.is-active .text{
  font-weight: bold;
  color: white;
}

a.is-active span{
  color: white;
  font-weight: bold;
}

a.is-active .icon{
  color: white;
}

a.is-active .logoIcon{
  filter: invert(0%);
}
</style>