<template>
  <div
    v-if="opportunityBeingVisited.lesson.isActive"
    id="webinars-info"
    class="container"
  >
    <div class="columns">
      <div id="webinars-box" class="column box">
        <lesson-title
          :title="lessonFormat"
          buttonText="Solicitar"
          :description="description"
          :isCardOpen="isCardOpen"
          @open-card="$emit('open-card', 'lesson')"
          @close-card="$emit('close-card', 'lesson')"
        />

        <div class="columns action-details" v-if="isCardOpen">
          <themes
            :themes="opportunityBeingVisited.lesson.themes"
            :specificThemes="opportunityBeingVisited.lesson.specificThemes"
          />

          <prerequisites
            :minPublic="opportunityBeingVisited.lesson.minPublic"
            :advance="opportunityBeingVisited.lesson.advance"
            :limitDateForActions="opportunityBeingVisited.limitDateForActions"
            :courses="opportunityBeingVisited.lesson.courses"
            :states="opportunityBeingVisited.lesson.states"
          />

          <counterparts
            :treatmentPronoun="opportunityBeingVisited.treatmentPronoun"
            :businessName="opportunityBeingVisited.businessName"
            :logosZipURL="opportunityBeingVisited.page.logosZipURL"
            action="a aula"
          />
        </div>
      </div>
    </div>

    <b-modal :active.sync="isRequestModalActive">
      <lesson-request-modal
        :minPublic="opportunityBeingVisited.lesson.minPublic"
      />
    </b-modal>
  </div>
</template>

<script>
import { descriptions } from "../../../assets/js/actions-descriptions";
import lessonTitle from "./card/Title";
import themes from "./card/Themes";
import prerequisites from "./card/Prerequisites";
import counterparts from "./card/Counterparts";
import lessonRequestModal from "../request-modal/LessonRequestModal.vue";

export default {
  props: ["opportunityBeingVisited", "isCardOpen"],

  components: {
    lessonTitle,
    themes,
    prerequisites,
    counterparts,
    lessonRequestModal
  },

  data() {
    return {
      description: "",
      isRequestModalActive: false
    };
  },

  computed: {
    lessonFormat(){
      if(this.opportunityBeingVisited.lesson.format == 'online'){
        return 'Participação em Aula (Online)';
      } else if(this.opportunityBeingVisited.lesson.format == 'faceToFace'){
        return 'Participação em Aula (Presencial)';
      } else {
        return 'Participação em Aula (Presencial ou Online)';
      }
    }
  },

  mounted() {
    this.description = descriptions.class;
  }
};
</script>

<style scoped>
#webinars-info {
  margin-top: 3rem;
}
@media (max-width: 1023px) {
  #webinars-info {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
#webinars-box {
  background-color: rgb(242, 242, 242);
}
.action-details {
  padding: 2rem;
}
</style>
