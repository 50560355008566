<template>
  <div v-if="pendencies.length > 0" class="pendencies">
    <span>
      ⚠️ Para realizar a operação, os seguintes campos precisam estar
      preenchidos:
    </span>
    <ul>
      <li v-for="(pendencie, index) in pendencies" :key="index">
        ▫️ {{ pendencie }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pendencies",

  props: ["pendencies"]
};
</script>

<style scoped>
.pendencies {
  color: red;
}
</style>
