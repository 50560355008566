<template>
  <div
    id="visits-info"
    v-if="
      opportunityBeingVisited.plantsProf.find(e => {
        return e.visitIsActive;
      })
    "
    class="container"
  >
    <div class="columns">
      <div id="visits-box" class="column box">
        <visits-title
          title="Visitas Técnicas (Presencial ou Online)"
          buttonText="Solicitar"
          :description="description"
          :isCardOpen="isCardOpen"
          @open-card="$emit('open-card', 'visitProf')"
          @close-card="$emit('close-card', 'visitProf')"
        />

        <div class="columns action-details" v-if="isCardOpen">
          <plants :plants="opportunityBeingVisited.plantsProf" />

          <visitPrerequisites
            :plants="opportunityBeingVisited.plantsProf"
            :limitDateForActions="opportunityBeingVisited.limitDateForActions"
          />

          <counterparts
            :treatmentPronoun="opportunityBeingVisited.treatmentPronoun"
            :businessName="opportunityBeingVisited.businessName"
            :logosZipURL="opportunityBeingVisited.page.logosZipURL"
            action="a visita"
          />
        </div>
      </div>
    </div>

    <b-modal :active.sync="isRequestModalActive">
      <visit-request-modal />
    </b-modal>
  </div>
</template>

<script>
import { descriptions } from "../../../assets/js/actions-descriptions";
import visitsTitle from "./card/Title";
import plants from "./card/Plants";
import visitPrerequisites from "./card/VisitPrerequisites";
import counterparts from "./card/Counterparts";
import visitRequestModal from "../request-modal/VisitRequestModal";

export default {
  props: ["opportunityBeingVisited", "isCardOpen"],

  components: {
    visitsTitle,
    plants,
    visitPrerequisites,
    counterparts,
    visitRequestModal
  },

  data() {
    return {
      description: "",
      isRequestModalActive: false
    };
  },

  mounted() {
    this.description = descriptions.visitProf;
  }
};
</script>

<style scoped>
#visits-info {
  margin-top: 3rem;
}
@media (max-width: 1023px) {
  #visits-info {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
#visits-box {
  background-color: rgb(242, 242, 242);
}
.action-details {
  padding: 2rem;
}
</style>
