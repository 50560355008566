<template>
  <div>
    <div id="company-name-background-div">
      <h1 id="company-name-mobile" class="title is-3 has-text-centered">
        {{ businessName }}
      </h1>

      <div class="is-pulled-right social-icons">
        <a
          :href="site"
          class="social-icon"
          v-if="site"
          target="_blank"
          @click="
            accountLinkClick('site', 'opportunity-page-company-name-bar')
          "
        >
          <b-tooltip
            :label="
              'Página de Carreira d' + treatmentPronoun + ' ' + businessName
            "
          >
            <b-icon
              icon="graduation-cap"
              size="is-large"
            ></b-icon>
          </b-tooltip>
        </a>

        <a
          :href="fanpage"
          class="social-icon"
          v-if="fanpage"
          target="_blank"
          @click="
            accountLinkClick('fanpage', 'opportunity-page-company-name-bar')
          "
        >
          <b-tooltip
            :label="'Facebook d' + treatmentPronoun + ' ' + businessName"
          >
            <b-icon
              v-if="fanpage"
              pack="fab"
              icon="facebook"
              size="is-large"
            ></b-icon>
          </b-tooltip>
        </a>

        <a
          :href="insta"
          class="social-icon"
          v-if="insta"
          target="_blank"
          @click="
            accountLinkClick('insta', 'opportunity-page-company-name-bar')
          "
        >
          <b-tooltip
            :label="'Instagram d' + treatmentPronoun + ' ' + businessName"
          >
            <b-icon
              v-if="insta"
              pack="fab"
              icon="instagram"
              size="is-large"
            ></b-icon>
          </b-tooltip>
        </a>

        <a
          :href="linkedin"
          class="social-icon"
          v-if="linkedin"
          target="_blank"
          @click="
            accountLinkClick('linkedin', 'opportunity-page-company-name-bar')
          "
        >
          <b-tooltip
            :label="'Linkedin d' + treatmentPronoun + ' ' + businessName"
          >
            <b-icon
              v-if="linkedin"
              pack="fab"
              icon="linkedin"
              size="is-large"
            ></b-icon>
          </b-tooltip>
        </a>
      </div>
    </div>

    <h1 id="company-name-desktop" class="title is-3">
      {{ businessName }}
    </h1>
  </div>
</template>

<script>
export default {
  props: [
    "businessName",
    "treatmentPronoun",
    "fanpage",
    "insta",
    "linkedin",
    "site",
    "opportunityId"
  ],

  methods: {
    accountLinkClick(link, linkLocation) {
      this.$store.dispatch("accountLinkClick", {
        link,
        linkLocation,
        opportunityId: this.opportunityId
      });
    }
  }
};
</script>

<style scoped>
#company-name-background-div {
  height: 70px;
  background-color: rgb(242, 242, 242);
}
.social-icons {
  padding-right: 2rem;
  padding-top: 0.7rem;
}
@media (max-width: 769px) {
  #company-name-background-div {
    height: 170px;
  }
}
#company-name-desktop {
  position: absolute;
  left: 330px;
  top: 384px;
  z-index: 2;
}
@media (max-width: 769px) {
  #company-name-desktop {
    display: none;
  }
  .social-icons {
    padding-top: 3rem;
  }
}
#company-name-mobile {
  position: relative;
  top: 4rem;
}
@media (min-width: 769px) {
  #company-name-mobile {
    display: none;
  }
}
.social-icon {
  margin-right: 0.7rem;
}
</style>
