<template>
  <span>
    <h1 class="action-title has-text-zero">
      {{ title }}
      <div v-if="isCardOpen" class="is-pulled-right">
          <button
            class="button is-primary request-button"
            @click="openRequestModal"
          >
            {{ buttonText }}
          </button>

        &nbsp;

        <button class="button" @click="$emit('close-card')">
          <b-icon icon="chevron-up"></b-icon>
        </button>
      </div>

      <button v-else class="is-pulled-right button" @click="$emit('open-card')">
        <b-icon icon="chevron-down"></b-icon>
      </button>
    </h1>

    <h2 v-if="isCardOpen" v-html="description"></h2>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["title", "buttonText", "description", "isCardOpen"],

  computed: {
    ...mapGetters(["account"])
  },

  methods: {
    openRequestModal() {
        this.$parent.isRequestModalActive = true;
    }
  }
};
</script>

<style scoped>
.action-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 2rem 0.2rem;
}
.request-button {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  font-weight: bold;
}
h2 {
  padding: 2rem;
}
</style>
