import $http from "../axios";

const actions = {
  sendMessage(_, data) {
    return new Promise((resolve, reject) => {
      $http
        .post("/contact", {
          message: data.message
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};

export default {
  actions
};
