import Vue from "vue";
import Router from "vue-router";
import store from "./store";

import Login from "./views/professor/Login";
import SignUpForm from "./components/signup/SignUpForm";
import EmailConfirmation from "./components/signup/EmailConfirmation";
import LoginForm from "./components/login/LoginForm";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
import UserConfig from "./views/professor/UserConfig";
import UserProfile from "./views/professor/UserProfile";

import Opportunities from "./views/opportunity/Opportunities";
import OpportunityPage from "./views/opportunity/OpportunityPage";

import Requests from "./views/request/Requests";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Login,
      beforeEnter: logout,
      children: [
        {
          path: "signup",
          name: "signup",
          component: SignUpForm
        },
        {
          path: "confirmemail",
          name: "confirmemail",
          component: EmailConfirmation
        },
        {
          path: "login",
          name: "login",
          component: LoginForm
        },
        {
          path: "forgotpassword",
          name: "forgotpassword",
          component: ForgotPassword
        },
        {
          path: "resetpassword",
          name: "resetpassword",
          component: ResetPassword
        }
      ]
    },
    {
      path: "/opportunities",
      name: "opportunities",
      component: Opportunities,
      beforeEnter: checkAuth
    },
    {
      path: "/opportunity-page",
      name: "opportunity-page",
      component: OpportunityPage,
      beforeEnter: checkAuth
    },
    {
      path: "/userconfig",
      name: "userconfig",
      component: UserConfig,
      beforeEnter: checkAuth
    },
    {
      path: "/userprofile",
      name: "userprofile",
      component: UserProfile,
      beforeEnter: checkAuth
    },
    {
      path: "/requests",
      name: "requests",
      component: Requests,
      beforeEnter: checkAuth
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.afterEach(to => {
  if (process.env.VUE_APP_USE_GA) {
    // eslint-disable-next-line no-undef
    gtag("config", process.env.VUE_APP_GA_TRACKING_ID, {
      page_path: to.fullPath,
      app_name: "Professor",
      send_page_view: true
    });
  }
});

function checkAuth(to, from, next) {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next("/");
  }
}

function logout(to, from, next) {
  store.dispatch("logout");
  next();
}

export default router;
